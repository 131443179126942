import {
  Box,
  Card,
  Collapse,
  createStyles,
  Flex,
  Group,
  MediaQuery,
} from '@mantine/core';
import React, { useState } from 'react';
import { createPopUp, removeStoresPopup } from './utils';
import NoozText from '../Nooz/NoozText/NoozText';
import { BasicNoozBadge } from '@/components/Nooz/NoozBadge/NoozBadge';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useStaticContent from '@/utils/hook/useStaticContent';
import { capitalizeFirstLetter } from '@/utils';
import { COUNTRIES } from '@/helpers/Locale';
import { ICountryDictionary } from '@/helpers/interface';
import usePageContext from '@/utils/context/page';
import CountryCodeToName from '@/helpers/CountryCodeToName.json';
import CategoriesBadges from '@/components/StoreLocatorMap/CategoriesBadges';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.white,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: theme.colors.gray[0],
    },
  },

  title: {
    fontWeight: 700,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1.2,
  },

  body: {
    padding: theme.spacing.md,
  },
}));

export const StoreLocationList = ({
  list,
  map,
  onClick,
  setActiveStoreIndex,
  noResultBody,
  zoomBody,
  storeDetails,
  dictionary,
}: LocationListProps) => {
  const staticContent = useStaticContent();
  const [opened, setOpened] = useState<string | undefined>();
  const toggleOpen = (id: string) =>
    setOpened((prev) => (prev === id ? undefined : id));
  const { classes } = useStyles();
  const [, setIsHover] = useState(false);

  const handleItemClick = (store: any, index: number) => {
    onClick(store, index);
  };

  const handleMouseEnter = (store: any, index: number) => {
    if (map?.getZoom() > 13) {
      createPopUp(map, store);
    }
    setIsHover(true);
    setActiveStoreIndex(index);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
    setActiveStoreIndex(null);
    removeStoresPopup();
  };

  return (
    <>
      {list.length > 0 && map?.getZoom() > 9 ? (
        list.map((store, index) => {
          const prop = store.properties;
          const id = JSON.stringify(store);

          return (
            <Card
              key={index}
              onMouseEnter={() => handleMouseEnter(store, index)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleItemClick(store, index)}
              withBorder
              radius='md'
              p={0}
              className={classes.card}>
              <Flex
                direction={'column'}
                className={classes.body}>
                <Flex gap={12}>
                  <Box sx={{ flex: 1 }}>
                    <MediaQuery
                      styles={{ display: 'none' }}
                      smallerThan={'sm'}>
                      <NoozText
                        size={18}
                        lh={1}
                        weight={700}
                        mb={14}>
                        {prop.name}
                      </NoozText>
                    </MediaQuery>
                    <MediaQuery
                      styles={{ display: 'none' }}
                      largerThan={'sm'}>
                      <NoozText
                        size={18}
                        lh={1}
                        weight={700}
                        mb={14}>
                        {prop.name}
                      </NoozText>
                    </MediaQuery>
                    <NoozText
                      color={'text'}
                      size={14}
                      lh={1.33}>
                      {prop.address1}
                      <br />
                      {[prop.postcode, prop.city].join(', ')}
                      <br />
                      {prop.country
                        ? capitalizeFirstLetter(
                            CountryCodeToName[
                              prop.country as keyof typeof CountryCodeToName
                            ],
                          )
                        : ''}
                    </NoozText>
                    <Group mt={20}>
                      <NoozButton
                        color={'title'}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleItemClick(store, index);
                        }}>
                        <Flex gap={4}>
                          {storeDetails.directionLabel.fields.text}
                          <NoozIcon
                            iconKey={'Map2'}
                            color={'white'}
                          />
                        </Flex>
                      </NoozButton>
                      <NoozButton
                        variant={'outline'}
                        color={'title'}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleOpen(id);
                        }}
                        sx={{
                          '& svg': {
                            transition: 'transform 300ms ease',
                            transform:
                              id === opened ? 'rotate(180deg)' : undefined,
                          },
                        }}>
                        <Flex
                          align={'space-between'}
                          gap={8}>
                          {staticContent.theirCollections}
                          <NoozIcon
                            iconKey={'ChevronDown'}
                            size={16}
                          />
                        </Flex>
                      </NoozButton>
                    </Group>
                  </Box>
                </Flex>

                <Collapse in={id === opened}>
                  <Group
                    spacing='xs'
                    mt={8}>
                    <CategoriesBadges
                      categories={prop.productCategories}
                      dictionary={dictionary}
                    />
                  </Group>
                </Collapse>
              </Flex>
            </Card>
          );
        })
      ) : list.length === 0 ? (
        <div className='flex flex-col justify-center items-center p-6 text-center h-full'>
          {noResultBody.fields.richBody &&
            documentToReactComponents(noResultBody.fields.richBody, {})}
        </div>
      ) : (
        <div className='flex flex-col justify-center items-center p-6 text-center h-full'>
          {noResultBody.fields.richBody &&
            documentToReactComponents(zoomBody.fields.richBody, {})}
        </div>
      )}
    </>
  );
};
