import { Box, Button, Flex, Image } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import React from 'react';
import usePageContext from '@/utils/context/page';
import useStaticContent from '@/utils/hook/useStaticContent';
import { PackProductOption } from '@/interface.custom';
import i18n from '@/helpers/i18n';

interface IStepProductSelection {
  options: string[];
  handleSelectProduct: any;
}
const Step = ({ options, handleSelectProduct }: IStepProductSelection) => {
  const staticContent = useStaticContent();
  const { productOptions } = usePageContext() as unknown as {
    productOptions: { [key: string]: PackProductOption };
  };

  return (
    <Flex
      sx={{
        flex: 1,
        overflow: 'overlay',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}>
      <Box
        w={'100%'}
        sx={() => ({
          width: '100%',
          '& > *:not(:first-of-type)': {
            marginTop: 30,
          },
        })}>
        {options.map((opt) => {
          if (!productOptions) return null;
          const productOpt = productOptions[opt];
          if (!productOpt) return null;
          return (
            <Flex
              key={productOpt?.id}
              direction={'row'}
              w={'100%'}>
              <Flex
                align={'center'}
                justify={'left'}>
                <Box
                  sx={(theme) => ({
                    overflow: 'hidden',
                    borderRadius: 20,
                    height: 200,
                    width: 200,
                    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                      height: 100,
                      width: 100,
                    },
                  })}>
                  <Image
                    fit={'contain'}
                    sx={(theme) => ({
                      height: 200,
                      width: 200,
                      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                        height: 100,
                        width: 100,
                      },
                    })}
                    src={productOpt?.posterImage + '?fm=webp'}
                    alt={productOpt?.altText || ''}
                  />
                </Box>
              </Flex>
              <Flex
                p={20}
                justify={'space-between'}
                direction={'column'}>
                <Box>
                  <NoozText
                    size={24}
                    fw={900}>
                    {productOpt?.label}
                  </NoozText>
                  <NoozText
                    size={16}
                    fw={500}
                    color={'text'}>
                    {productOpt?.subLabel}
                  </NoozText>
                </Box>
                <Button
                  mt={16}
                  variant={'outline'}
                  color={'text'}
                  radius={'xl'}
                  onClick={handleSelectProduct(productOpt)}>
                  {i18n(staticContent, 'choose')}
                </Button>
              </Flex>
            </Flex>
          );
        })}
      </Box>
    </Flex>
  );
};

const StepProductSelection = ({
  step,
  ...props
}: { step: number } & IStepProductSelection) =>
  step === 1 ? <Step {...props} /> : null;

export default StepProductSelection;
