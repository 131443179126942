//@ts-nocheck
import React, { useMemo } from 'react';
import {
  ActionIcon,
  clsx,
  createStyles,
  Image,
  MediaQuery,
} from '@mantine/core';
import { IImage } from '@/@types/generated/contentful';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { PRODUCT_LAYOUT_MEDIA_QUERY } from '@/templates/Product';
import useProductContext from '@/utils/context/product';
import usePageContext from '@/utils/context/page';

type CarouselImage = IImage & { id: string };

const useStyles = createStyles((theme) => ({
  carouselPicker: {
    overflow: 'visible',
    position: 'absolute',
    bottom: 30,
    maxWidth: '100%',
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'row',
    padding: '0 25px',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    zIndex: 1,
    '& .picker-item': {
      cursor: 'pointer',
      overflow: 'hidden',
      borderRadius: 4,
      border: '1px solid #F5F5F5',
      '&:hover, &.active': {
        borderColor: `${theme.colors.black[0]}`,
      },
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.fn.smallerThan(PRODUCT_LAYOUT_MEDIA_QUERY)]: {
      padding: '0 12px',
      minWidth: '100%',
      display: 'flex',
      justifyContent: 'center',
      '& .picker-item': {
        width: '2.75rem',
        height: '2.75rem',
        minWidth: '2.75rem',
        minHeight: '2.75rem',

        '&:not(.active)': {
          opacity: 0.5,
        },
      },
    },
    '& > *:not(:last-of-type)': {
      marginRight: 16,
    },
    '& .mantine-Image-root': {
      cursor: 'pointer',
    },
  },
}));

const VideoPicker = ({ onClick, active }) => {
  const productFields = usePageContext().product?.fields;
  const { product } = usePageContext();
  const { variant } = useProductContext();
  const images = variant.fields?.images as CarouselImage[];
  const lifestyleImages = variant.fields?.lifestyleImages as CarouselImage[];
  const index = (images?.length || 0) + (lifestyleImages?.length || 0);

  if (!productFields?.video) return null;

  return (
    <ActionIcon
      onClick={() => onClick(index)}
      variant={index === active ? 'filled' : 'light'}
      className={clsx('picker-item', 'test', { active: index === active })}
      color={product?.fields.category?.fields.color?.fields.classname}
      size={60}>
      <NoozIcon
        color='white'
        strokeWidth={1.5}
        iconKey='PlayerPlay'
        size={30}
      />
    </ActionIcon>
  );
};

const ProductDisplayerPicker = ({ slideState, emblaState }) => {
  const { variant } = useProductContext();
  const { product } = usePageContext();
  const [embla, setEmbla] = emblaState;
  const [active, setActive] = slideState;
  const { classes } = useStyles();

  const packImages = product?.fields?.images as CarouselImage[];
  const images = variant.fields?.images as CarouselImage[];

  const lifestyleImages = useMemo(() => {
    if (variant?.fields?.lifestyleImages?.length > 0) {
      return variant.fields.lifestyleImages;
    } else {
      return product?.fields?.lifestyleImages;
    }
  }, [product?.fields?.lifestyleImages, variant?.fields?.lifestyleImages]);

  const handlePickerClick = (index: number, offset: number = 0) => {
    setActive(index);
    embla?.scrollTo(index + offset);
  };

  const renderImageIcon = (
    image: CarouselImage,
    index: number,
    offset: number = 0,
  ) => {
    return (
      image?.fields?.media && (
        <ActionIcon
          key={image.id}
          variant={index === active ? 'outline' : 'light'}
          size={60}
          className={clsx('picker-item', { active: index === active })}
          onClick={() => handlePickerClick(index, offset)}>
          <Image
            fit='cover'
            src={image.fields.media?.fields?.file.url + '?fm=webp'}
            alt={image.fields.altText}
          />
        </ActionIcon>
      )
    );
  };

  return (
    <div className={classes.carouselPicker}>
      {packImages?.map((image, index) => renderImageIcon(image, index))}
      {images?.map((image, index) => renderImageIcon(image, index))}
      {lifestyleImages?.map((image, index) =>
        renderImageIcon(image, (images?.length || 0) + index),
      )}
      <VideoPicker
        active={active}
        onClick={(index) => handlePickerClick(index)}
      />
    </div>
  );
};

export default ProductDisplayerPicker;
