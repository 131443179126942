import { IProduct, IProductVariant } from '@/@types/generated/contentful';
import React, {
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
  useCallback,
  useRef,
} from 'react';
import useDefaultProductVariant from '@/utils/hook/useDefaultVariant';
import useShopifyProductContext from '@/utils/context/shopifyProduct';
import DataLayerHelper from '@/helpers/Datalayer';
import ShopifyFormatter from '@/helpers/ShopifyFormatter';
import { useRouter } from 'next/router';
import usePageContext from '@/utils/context/page';
import Cookie from '@/helpers/Cookie';
import { APPLY_DISCOUNT_PRODUCT_FACET } from '@/templates/ProductCategory';
import { useSearchParams } from 'next/navigation';

export interface IVariant extends IProductVariant {
  id: string;
  shopifyInfo: ShopifyProductVariant;
  shopifyVariants?: ShopifyProductVariant[];
  product?: {
    label: string;
    gradientColor?: string;
  };
}

export type IProductContext = {
  variant: IVariant;
  variants: (IVariant | undefined)[];
  setVariant: React.Dispatch<React.SetStateAction<IVariant>>;
};

const IVariantsContextState: IProductContext = {
  variant: {} as IVariant,
  variants: [] as IVariant[],
  setVariant: () => {},
};

export const VariantContext = createContext<IProductContext>(
  IVariantsContextState,
);

const useProductContext = (): IProductContext => {
  const context = useContext(VariantContext);
  if (!context) {
    throw new Error('useProductContext must be used within a ProductProvider');
  }
  return context;
};
interface ProductProviderProps {
  children: React.ReactNode;
  product: IProduct;
  triggerEvent?: boolean;
}

// Product provider component
export const ProductProvider = ({
  product,
  children,
  triggerEvent = true,
}: ProductProviderProps) => {
  const { page, localizedSlugs, shopifyPrice } = usePageContext() as any;
  const router = useRouter();
  // Get the Shopify product and the default variant for this product
  const shopifyProduct = useShopifyProductContext();
  const prevStock = useRef<boolean>(!!shopifyProduct.shopifyStockChecked);
  const defaultProductVariant = useDefaultProductVariant(product);
  const sp = useSearchParams();

  const [variant, setVariant] = useState(defaultProductVariant as IVariant);
  const discountToApply = useMemo(() => {
    let d = 0.0;
    if (
      // @ts-ignore
      page.fields.pageType.type === 'Product' &&
      Cookie.get(APPLY_DISCOUNT_PRODUCT_FACET)
    ) {
      d = parseFloat(
        Cookie.get(APPLY_DISCOUNT_PRODUCT_FACET) as unknown as string,
      );
    }
    return d;
  }, [page]);

  const setVariantState = useCallback(
    (v: IVariant) => {
      const cpy: IVariant = JSON.parse(JSON.stringify(v));
      if (discountToApply > 0) {
        if (!cpy.shopifyInfo.compareAtPrice)
          cpy.shopifyInfo.compareAtPrice = v.shopifyInfo.price;
        const price = parseFloat(cpy.shopifyInfo.price.amount);
        cpy.shopifyInfo.price.amount = (
          price -
          (price * discountToApply) / 100
        ).toFixed(2);
      }
      setVariant(cpy);
    },
    [discountToApply, setVariant],
  );
  // Set the default variant as the selected variant when the product changes
  useEffect(() => {
    if (shopifyProduct.shopifyStockChecked && !prevStock.current) {
      prevStock.current = true;
      setVariantState(defaultProductVariant as IVariant);
    }
  }, [defaultProductVariant, shopifyProduct]);

  // Push a view item event to the data layer when the selected variant changes
  useEffect(() => {
    if (triggerEvent) {
      DataLayerHelper.pushViewItemEvent(
        product,
        // @ts-ignore
        defaultProductVariant,
        shopifyProduct.id,
      );
    }
  }, []);

  const shopifyVariants = shopifyProduct?.variants as unknown as any[];

  const variants = useMemo(
    () =>
      product?.fields?.variants?.map((v) => {
        // Filter the Shopify variants to only include those that match the selected variant title
        const sku = v.fields?.title;
        let shopVariant = shopifyVariants.find((s) => s.sku === sku);
        if (!shopVariant)
          shopVariant = shopifyVariants.find((s) => s.sku.includes(sku));

        const shopVariants = shopifyVariants.filter(
          (s) =>
            s?.selectedOptions?.find((o: Record<string, string>) =>
              ['Color', 'Couleur'].includes(o.name),
            )?.value ===
            shopVariant?.selectedOptions?.find((o: Record<string, string>) =>
              ['Color', 'Couleur'].includes(o.name),
            )?.value,
        );

        return {
          ...v,
          shopifyInfo: ShopifyFormatter.getVariantInfo(
            router,
            shopifyVariants,
            v.fields.title,
          ),
          shopifyVariants: shopVariants,
        };
      }),
    [product, shopifyVariants, router],
  );

  return (
    <VariantContext.Provider
      // @ts-ignore
      value={{ variant, variants, setVariant: setVariantState }}>
      {children}
    </VariantContext.Provider>
  );
};

export default useProductContext;
