import useCommerceContext from '../../context/commerce';
import usePageContext from '@/utils/context/page';
import { useMemo } from 'react';
import Currency from '@/helpers/Currency';
import { FREE_SHIPPING_ENABLED } from '@/components/Header/HeaderBanner/headerBanner';

export function useFreeShipping(): {
  amount: string;
  percentage: number;
  currency: string;
  missingAmount: number;
  required: string;
} | null {
  const { locale, settings } = usePageContext();
  const { cart, freeShipping } = useCommerceContext();

  return useMemo(() => {
    if (cart == null || !locale) {
      return null;
    }

    if (!freeShipping.isActive) return null;
    const amountNumber = parseFloat(freeShipping.amount);
    return {
      required: `${amountNumber.toFixed(2)} ${Currency.toSymbol(
        cart.subtotalPrice.currencyCode,
      )}`,
      amount: amountNumber.toFixed(2),
      currency: cart.subtotalPrice.currencyCode,
      missingAmount: FREE_SHIPPING_ENABLED
        ? 0
        : // @ts-ignore
          amountNumber -
          // @ts-ignore
          parseFloat(cart.subtotalPrice.amount),
      percentage: FREE_SHIPPING_ENABLED
        ? 100
        : // @ts-ignore
          (parseFloat(cart.subtotalPrice.amount) * 100) /
          // @ts-ignore
          amountNumber,
    };
  }, [cart, settings, locale]);
}
