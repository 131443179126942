import React, { useMemo } from 'react';
import useProductContext, { IVariant } from '@/utils/context/product';
import { clsx, createStyles, Group, Divider, Box } from '@mantine/core';
import { useRouter } from 'next/router';
import usePageContext from '@/utils/context/page';
import { IPage } from '@/@types/generated/contentful';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { ProductSizePickerProps, UISizePickerProps } from '@/interface.custom';
import { useProductInfoStyles } from '../ProductInformation/ProductInformation';

const useStyles = createStyles((theme) => ({
  chip: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    width: 64,
    height: 26,
    borderRadius: 13,
    cursor: 'pointer',
    border: `1px solid ${theme.colors.text[0]}`,
  },
  active: {
    color: theme.white,
    backgroundColor: theme.colors.text[0],
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
}));

export const STATIC_SIZE_KEY: Record<string, string> = {
  S: 'small',
  M: 'medium',
  L: 'large',
};
export const UISizePicker = ({
  isKid,
  active,
  handleSize,
  styles,
}: UISizePickerProps & {
  isKid: boolean;
  active: IVariant | null;
  handleSize: (key: string) => void;
}) => {
  const { page } = usePageContext();
  const staticContent = useMemo(
    // @ts-ignore
    () => (page as IPage).fields.pageType.staticItems,
    [page],
  );
  const { classes } = useStyles();
  const productInfoStyles = useProductInfoStyles();
  const selectedSize = useMemo(
    () =>
      active?.shopifyInfo?.selectedOptions.find(
        (o: { name: string }) => o.name === 'Size',
      )?.value,
    [active],
  );

  if (!active?.shopifyInfo) {
    return null;
  }

  return active?.shopifyVariants?.length &&
    active?.shopifyVariants.length > 1 ? (
    <Box className={productInfoStyles.classes.infoContentWrapper}>
      <Divider className={styles.smallDivider} />
      <div className={styles.itemWrapper}>
        <NoozText
          color={'title'}
          fw={900}
          size={14}
          mb={16}>
          {staticContent.sizeLabel}
          <NoozText
            span
            size={14}
            fw={400}
            color={'text'}>
            {selectedSize && ['S', 'M', 'L'].includes(selectedSize)
              ? ` - ${
                  staticContent[
                    isKid
                      ? selectedSize === 'S'
                        ? 'smallSizeSub'
                        : 'largeSizeSub'
                      : STATIC_SIZE_KEY[selectedSize]
                  ]
                }`
              : ''}
          </NoozText>
        </NoozText>
        <Group>
          {active.shopifyVariants?.map((v) => (
            <div
              className={clsx(classes.chip, {
                [classes.active]: v.id === active.shopifyInfo.id,
                [classes.disabled]: !v.availableForSale,
              })}
              onClick={() => {
                if (v.availableForSale) handleSize(v.id);
              }}
              key={`product_size_${v.id}`}>
              <NoozText
                size={12}
                weight={v.id === active.shopifyInfo.id ? 700 : undefined}>
                {v?.selectedOptions.find(
                  (o: { name: string }) => o.name === 'Size',
                )?.value
                  ? staticContent[
                      STATIC_SIZE_KEY[
                        v?.selectedOptions.find(
                          (o: { name: string }) => o.name === 'Size',
                        )?.value as string
                      ]
                    ]
                  : 'NA'}
              </NoozText>
            </div>
          ))}
        </Group>
      </div>
    </Box>
  ) : null;
};

const ProductSizePicker = ({ styles }: UISizePickerProps) => {
  const router = useRouter(); // @ts-ignore
  const { shopifyProduct } = usePageContext();
  const hasSizeOption = useMemo(
    () =>
      !!shopifyProduct?.options.find(
        ({ name }: { name: string }) => 'Size' === name,
      ),
    [shopifyProduct?.options],
  );
  const { variant, setVariant } =
    useProductContext() as unknown as ProductSizePickerProps;

  const handleSize = (id: string) => {
    const newShopifyInfo = variant?.shopifyVariants?.find((v) => v.id === id)!;
    const newVariant: IVariant = { ...variant, shopifyInfo: newShopifyInfo };
    setVariant(newVariant);

    const newQuery: Record<string, any> = {};
    Object.entries(router.query).forEach(([key, value]) => {
      if (!['locale', 'handles', 'size'].includes(key)) newQuery[key] = value;
    });

    newQuery.size = newShopifyInfo.selectedOptions.find(
      (opt: any) => opt.name === 'Size',
    )?.value;

    // @ts-ignore
    if (typeof window !== 'undefined' && window.history?.pushState) {
      var newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?${new URLSearchParams(newQuery).toString()}`;
      window.history.replaceState({ path: newurl }, '', newurl);
    }
  };

  return hasSizeOption ? (
    <UISizePicker
      isKid={/^cruzy-/.test(shopifyProduct.title)}
      active={variant}
      handleSize={handleSize}
      styles={styles}
    />
  ) : null;
};

export default ProductSizePicker;
