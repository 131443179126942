//@ts-nocheck
import usePageContext from '@/utils/context/page';
import {
  Box,
  Center,
  clsx,
  createStyles,
  Group,
  Image,
  useMantineTheme,
} from '@mantine/core';
import React, { useCallback, useMemo } from 'react';
import { useModalContext } from '@/utils/context/modal';
import useProductContext from '@/utils/context/product'
import FrameFitter from '@/components/Product/ProductInformation/FrameFitter/FrameFitter';
import { IPage } from '@/@types/generated/contentful';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { ProductProvider } from '@/utils/context/product';
import { useMediaQuery } from '@mantine/hooks';
import useShopifyProductContext, {
  ShopifyProductProvider,
} from '@/utils/context/shopifyProduct';

const useStyles = createStyles((theme) => ({
  frameFitter: {
    '& .mantine-Modal-modal': {
      width: '75vw',
      [theme.fn.smallerThan(theme.breakpoints.md)]: {
        width: '85vw',
      },
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        width: '95vw',
      },
    },
  },
}));

const ProductInformationFitter = ({
  classes,
}: {
  className?: string;
  classes: {
    adWrapper: string;
    adText: string;
  };
}) => {
  const styles = useStyles();
  const { page, product } = usePageContext();
  const shopifyProductState = useShopifyProductContext();
  const {variant} = useProductContext();
  const staticContent = useMemo(
    // @ts-ignore
    () => (page as IPage).fields.pageType.staticItems,
    [page],
  );

  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);

  const { setModal } = useModalContext();

  const openFrameFitter = useCallback(() => {
    setModal({
      opened: true,
      size: 'xl',
      radius: 'lg',
      className: styles.classes.frameFitter,
      body: (
        <ShopifyProductProvider
          product={shopifyProductState}
          shouldFetch={false}>
          <ProductProvider
            product={product}
            triggerEvent={false}>
            <FrameFitter
              setModal={setModal}
              size={variant.shopifyInfo.selectedOptions.find((opt: any) =>
                opt.name === 'Size')?.value
            }/>
          </ProductProvider>
        </ShopifyProductProvider>
      ),
    });
  }, [variant]);

  return (
    <Group
      noWrap
      className={clsx(classes.adWrapper)}
      onClick={openFrameFitter}
      p={30}
      spacing={10}>
      <Center className={styles.classes.child}>
        <Image
          src={staticContent.findYourFitImage?.url + '?fm=webp'}
          withPlaceholder
          className={styles.classes.image}
          width={isDesktop ? '90%' : '100%'}
          fit='contain'
          alt={staticContent.findYourFit}
        />
      </Center>
      <Box className={styles.classes.child}>
        <NoozText
          color={'blue.9'}
          size={12}
          fw={900}
          transform={'uppercase'}
          align={'center'}>
          {staticContent.findYourFit}
        </NoozText>
        <NoozText
          color={'title'}
          size={18}
          fw={500}
          align={'center'}>
          {`${staticContent.findYourFitSubLabel}`}
        </NoozText>
      </Box>
    </Group>
  );
};

export default ProductInformationFitter;
