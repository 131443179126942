import React from 'react';
import { Image, Input, NativeSelect } from '@mantine/core';
import { CountryCode } from 'libphonenumber-js';
import usePageContext from '@/utils/context/page';
import { ICountryDictionary } from '@/helpers/interface';
import { CountryInputProps, CountrySelectorProps } from '@/interface.custom';
import { COUNTRIES } from '@/helpers/Locale';

export const CountrySelector = ({
  size,
  value,
  setValue,
}: CountrySelectorProps) => {
  const { locale } = usePageContext();
  return (
    <NativeSelect
      size={size || 'md'}
      radius={'xl'}
      data={
        Object.keys(COUNTRIES)
          ?.filter((k) => k !== 'int')
          .map((c) => ({
            label:
              // @ts-ignore
              COUNTRIES[c as keyof typeof COUNTRIES].labelTranslations[
                (locale?.split('-')[0] || 'en') as keyof ICountryDictionary
              ],
            value: c,
          })) || []
      }
      icon={
        // @ts-ignore
        COUNTRIES[value?.toLowerCase() as string]?.flag ? (
          <Image
            // @ts-ignore
            src={COUNTRIES[value?.toLowerCase() as string]?.flag}
            alt={`${value} country flag`}
            width={15}
          />
        ) : undefined
      }
      value={value?.toLowerCase()}
      onChange={(e) => {
        // @ts-ignore
        setValue(e.currentTarget.value);
      }}
    />
  );
};

const CountryInput = ({
  onChange,
  renderItem,
  controlClassName,
  // eslint-disable-next-line no-unused-vars
  inputProps: { value, onChange: _, placeholder, ...props },
}: CountryInputProps) => (
  <Input.Wrapper {...props}>
    <CountrySelector
      value={value as CountryCode}
      setValue={onChange}
    />
  </Input.Wrapper>
);

export default CountryInput;
