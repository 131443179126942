import React, { useState } from 'react';
import { Container } from '@mantine/core';
import FormTrackOrder from '@/components/pages/TrackOrder/FormTrackOrder';
import ResultsTrackOrder from '@/components/pages/TrackOrder/ResultsTrackOrder';
import useStaticContent from '@/utils/hook/useStaticContent';
import { OrderRowProps } from '@/interface.custom';
import { HeroTextBanner } from '@/components/Hero/Banner/HeroTextBanner';

const TrackOrder = () => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<OrderRowProps[] | undefined>();
  const staticContent = useStaticContent();

  return (
    <>
      <HeroTextBanner
        title={staticContent.title}
        subTitle={staticContent.description}
      />
      <Container my={80}>
        {Array.isArray(orders) ? (
          <ResultsTrackOrder
            orders={orders}
            goPrev={() => {
              setOrders(undefined);
            }}
          />
        ) : (
          <FormTrackOrder
            loading={loading}
            setLoading={setLoading}
            setOrders={setOrders}
          />
        )}
      </Container>
    </>
  );
};

export default TrackOrder;
