import useStaticContent from '@/utils/hook/useStaticContent';
import {
  ActionIcon,
  Box,
  Divider,
  Flex,
  Image,
  MantineTheme,
  NativeSelect,
  useMantineTheme,
} from '@mantine/core';
import React, { useMemo } from 'react';
import Currency from '@/helpers/Currency';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import i18n from '@/helpers/i18n';
import { useMediaQuery } from '@mantine/hooks';
import { useRouter } from 'next/router';
import { ICartLine } from '@/interface.custom';
import useCartActions from '@/utils/hook/cart/useCartActions';
import { notifications } from '@mantine/notifications';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import { useDiscounts } from '@/utils/context/commerce';
export const roundPrice = (value: number, decimals: number) =>
  Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);

interface UiLineItemProps {
  loading: boolean;
  cartLine: ICartLine;
  hasDivider?: boolean;
  theme: MantineTheme;
  qty: string;
  staticContent: Record<string, any>;
  removeItem: () => void;
  updateQuantity: (_quantity: number) => void;
  prices: { display?: number | string; compare?: number | string };
}

const ICON_MAPPING: Record<string, string> = {
  Color: 'Brush',
  Diopter: 'EyeTable',
  Collection: 'Tags',
};

const DesktopUi = ({
  loading,
  theme,
  cartLine,
  qty,
  staticContent,
  removeItem,
  updateQuantity,
  prices,
}: UiLineItemProps) => {
  const router = useRouter();

  return (
    <Flex
      mb={20}
      sx={{ gap: '1.75rem' }}>
      <Box
        sx={() => ({
          borderRadius: 20,
          overflow: 'hidden',
        })}>
        <Image
          withPlaceholder
          height={150}
          width={150}
          fit={'contain'}
          sx={() => ({
            background: '#F0F0F3',
            height: 'fit-content',
          })}
          src={
            cartLine.__contentfulItem?.image?.fields?.media?.fields.file.url +
            '?fm=webp'
          }
          alt={cartLine.__contentfulItem?.image?.fields?.altText}
        />
      </Box>
      <Flex
        sx={{
          flex: 1,
        }}
        direction={'column'}>
        <Box
          sx={
            cartLine.__contentfulItem?.url
              ? {
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }
              : undefined
          }
          onClick={() => {
            if (cartLine.__contentfulItem?.url)
              router.push(cartLine.__contentfulItem?.url);
          }}>
          <NoozText
            size={34}
            weight={600}>
            {cartLine.__contentfulItem?.title}
          </NoozText>
        </Box>

        {cartLine.__itemType === 'pack' ? (
          <Flex
            direction={'column'}
            sx={{ gap: '.5rem' }}>
            {cartLine.__contentfulItem.items?.map((lines, pos) => {
              return (
                <Flex key={pos}>
                  <NoozText
                    size={14}
                    weight={700}
                    w={125}>
                    {`${i18n(staticContent, 'frameNumber')} ${pos + 1}`}
                  </NoozText>
                  <Flex
                    direction={'column'}
                    sx={{ gap: '.25rem' }}>
                    {lines.map((l, key) =>
                      l !== '0' ? (
                        <NoozText
                          size={14}
                          key={`${l}-${pos}`}>
                          {key === 2 ? `+ ${l.replace('+', '')}` : l}
                        </NoozText>
                      ) : null,
                    )}
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        ) : null}

        {cartLine.__contentfulItem?.itemOpt ? (
          <Flex
            direction={'column'}
            sx={{ gap: '.5rem' }}>
            {cartLine.__contentfulItem?.itemOpt?.map(({ key, value }) => (
              <Flex key={key}>
                <NoozText
                  size={14}
                  weight={700}
                  w={125}>
                  {i18n(staticContent, key)}
                </NoozText>

                <NoozText size={14}>
                  {key.toLowerCase() === 'diopter'
                    ? `+ ${(value || '').replace('+', '')}`
                    : value}
                </NoozText>
              </Flex>
            ))}
          </Flex>
        ) : null}
      </Flex>
      <Flex
        direction={'column'}
        justify={'space-between'}
        align={'flex-end'}>
        <Flex
          direction={'column'}
          align={'flex-end'}>
          <NoozText
            sx={{ lineHeight: 'calc(34px * 1.55)' }}
            size={24}
            weight={700}>
            {prices.display || null}
            <NoozText
              component={'span'}
              color={'main'}
              size={24}
              weight={700}>
              {` ${Currency.toSymbol(
                cartLine.variant?.price?.currencyCode || '',
              )}`}
            </NoozText>
          </NoozText>
          {prices.compare ? (
            <NoozText
              color={'text'}
              strikethrough
              size={20}>
              {prices.compare}
              <NoozText
                component={'span'}
                color={'text'}
                size={20}>
                {` ${Currency.toSymbol(
                  cartLine.variant?.price?.currencyCode || '',
                )}`}
              </NoozText>
            </NoozText>
          ) : null}
        </Flex>

        <Flex>
          {cartLine.soldOut ? (
            <Flex
              direction={'column'}
              align={'flex-end'}>
              <p
                style={{
                  fontWeight: 700,
                  color: theme.colors.original[0],
                  margin: '0 0 8px',
                  textAlign: 'end',
                }}>
                {staticContent.soldOut}
              </p>
              <NoozButton
                variant={'outline'}
                sx={{ width: 'fit-content' }}
                onClick={() => {
                  if (cartLine.__contentfulItem?.url)
                    router.push(cartLine.__contentfulItem?.url);
                }}>
                {staticContent.viewProduct}
              </NoozButton>
            </Flex>
          ) : (
            <>
              <ActionIcon
                variant='outline'
                color='gray'
                size={36}
                radius={20}
                disabled={loading}
                onClick={removeItem}>
                <NoozIcon
                  iconKey={'Trash'}
                  size={20}
                  color={theme.colors.main[0]}
                />
              </ActionIcon>

              {cartLine.__itemType === 'single' ? (
                <NativeSelect
                  data={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
                  radius='xl'
                  disabled={loading}
                  ml={10}
                  value={qty}
                  styles={{
                    input: {
                      height: 36,
                    },
                  }}
                  onChange={(event) => {
                    const amount =
                      parseInt(event.currentTarget.value) - parseInt(qty);
                    if (amount !== 0) updateQuantity(amount);
                  }}
                />
              ) : null}
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const MobileUi = ({
  loading,
  hasDivider,
  theme,
  cartLine,
  qty,
  removeItem,
  prices,
  updateQuantity,
}: UiLineItemProps) => {
  const staticContent = useStaticContent();
  const router = useRouter();
  return (
    <>
      {hasDivider ? <Divider mt={8} /> : null}
      <Flex
        mt={hasDivider ? 8 : undefined}
        sx={{ flex: 1 }}
        justify={'space-between'}
        direction={'row'}>
        <Flex gap={'1.5rem'}>
          <Box
            sx={() => ({
              borderRadius: 20,
              overflow: 'hidden',
            })}>
            <Image
              withPlaceholder
              height={75}
              width={75}
              fit={'contain'}
              styles={{
                root: {
                  borderRadius: 15,
                },
                image: {
                  borderRadius: 15,
                },
              }}
              sx={() => ({
                background: '#F0F0F3',
                height: 'fit-content',
              })}
              src={
                cartLine.__contentfulItem?.image?.fields?.media?.fields.file
                  .url + '?fm=webp'
              }
              alt={cartLine.__contentfulItem?.image?.fields?.altText}
            />
          </Box>
          <Flex
            sx={{ gap: 0, flex: 1 }}
            direction={'column'}>
            <Box
              sx={
                cartLine.__contentfulItem?.url
                  ? {
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }
                  : undefined
              }
              onClick={() => {
                if (cartLine.__contentfulItem?.url)
                  router.push(cartLine.__contentfulItem?.url);
              }}>
              <NoozText
                size={24}
                weight={700}>
                {cartLine.__contentfulItem?.title}
                <NoozText
                  size={24}
                  weight={700}
                  color={cartLine.__contentfulItem?.categoryColor as string}
                  component={'span'}>
                  {'.'}
                </NoozText>
              </NoozText>
            </Box>

            {cartLine.__contentfulItem?.items ? (
              <Flex
                direction={'column'}
                gap={8}>
                {cartLine.__contentfulItem?.items.map((lines, pos) => (
                  <Flex
                    key={pos}
                    align={'center'}>
                    <Flex
                      align={'center'}
                      sx={{
                        borderRadius: 16,
                        padding: 4,
                        backgroundColor: '#F0F0F3',
                      }}>
                      <NoozIcon iconKey={`RosetteNumber${pos + 1}`} />
                    </Flex>
                    <NoozText
                      color={'text'}
                      ml={12}
                      size={14}>
                      {lines
                        .filter((l) => l !== '0')
                        .reduce((arr: any[], val, key) => {
                          arr.push(
                            key === 2 ? `+ ${val.replace('+', '')}` : val,
                          );
                          return arr;
                        }, [])
                        .join(' - ')}
                    </NoozText>
                  </Flex>
                ))}
              </Flex>
            ) : null}

            {cartLine.__contentfulItem?.itemOpt ? (
              <Flex direction={'column'}>
                {cartLine.__contentfulItem?.itemOpt.map(({ key, value }) =>
                  value && !['', '0', '-'].includes(value) ? (
                    <Flex key={key}>
                      {ICON_MAPPING[key] ? (
                        <Flex
                          align={'center'}
                          mr={12}
                          sx={{
                            borderRadius: 16,
                            padding: 4,
                            backgroundColor: '#F0F0F3',
                          }}>
                          <NoozIcon iconKey={ICON_MAPPING[key]} />
                        </Flex>
                      ) : null}

                      <NoozText
                        color={'text'}
                        size={14}>
                        {key !== 'diopter'
                          ? value
                          : `+ ${value.replace('+', '')}`}
                      </NoozText>
                    </Flex>
                  ) : null,
                )}
              </Flex>
            ) : null}
          </Flex>
        </Flex>
        <Flex
          direction={'column'}
          align={'flex-end'}
          justify={'space-between'}>
          <NoozText
            truncate='end'
            size={24}
            align='right'
            weight={700}>
            {prices.display || null}
            <NoozText
              component={'span'}
              color={'main'}
              size={24}
              weight={700}>
              {` ${Currency.toSymbol(
                cartLine.variant?.price.currencyCode as unknown as string,
              )}`}
            </NoozText>
          </NoozText>
          {cartLine.soldOut ? (
            <Flex
              direction={'column'}
              align={'flex-end'}>
              <p
                style={{
                  fontWeight: 700,
                  color: theme.colors.original[0],
                  margin: '0 0 8px',
                  textAlign: 'end',
                }}>
                {staticContent.soldOut}
              </p>
              <NoozButton
                variant='outline'
                sx={{ width: 'fit-content' }}
                onClick={() => {
                  if (cartLine.__contentfulItem?.url)
                    router.push(cartLine.__contentfulItem?.url);
                }}>
                {staticContent.viewProduct}
              </NoozButton>
            </Flex>
          ) : (
            <>
              {cartLine.__contentfulItem?.itemOpt ? (
                <NativeSelect
                  data={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
                  radius='xl'
                  disabled={loading}
                  ml={10}
                  mb={10}
                  value={qty}
                  onChange={(event) => {
                    const amount =
                      parseInt(event.currentTarget.value) - parseInt(qty);
                    if (amount !== 0) updateQuantity(amount);
                  }}
                />
              ) : null}
              <ActionIcon
                variant={'outline'}
                color={'title'}
                disabled={loading}
                size={30}
                radius={20}
                onClick={removeItem}>
                <NoozIcon
                  iconKey={'Trash'}
                  size={18}
                  color={theme.colors.main[0]}
                />
              </ActionIcon>
            </>
          )}
        </Flex>
      </Flex>
    </>
  );
};

const LineItem = ({
  hasDivider,
  loading,
  ...cartLine
}: {
  hasDivider?: boolean;
  loading: boolean;
} & ICartLine) => {
  const staticContent = useStaticContent();
  const theme = useMantineTheme();
  const qty = cartLine.quantity.toString();
  const { removeFromCart, updateFromCart } = useCartActions();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const handleRemoveItem = () => {
    notifications.show({
      id: 'loading',
      loading: true,
      message: i18n(staticContent, 'cartRemoveLoading'),
    });
    removeFromCart(cartLine.lineItems)
      .then(() => {
        notifications.show({
          color: 'green',
          message: i18n(staticContent, 'cartRemoveSuccess'),
        });
      })
      .catch(() => {
        notifications.show({
          color: 'red',
          message: i18n(staticContent, 'cartRemoveError'),
        });
      })
      .finally(() => notifications.hide('loading'));
  };
  const handleUpdateQuantity = (diff: number) => {
    notifications.show({
      id: 'loading',
      loading: true,
      message: i18n(staticContent, 'cartQuantityUpdateLoading'),
    });
    updateFromCart(cartLine.lineItems, diff)
      .then(() => {
        notifications.show({
          color: 'green',
          message: i18n(staticContent, 'cartQuantityUpdateSuccess'),
        });
      })
      .catch(() => {
        notifications.show({
          color: 'red',
          message: i18n(staticContent, 'cartQuantityUpdateError'),
        });
      })
      .finally(() => notifications.hide('loading'));
  };

  const discounts = useDiscounts();

  const prices = useMemo(() => {
    const p: Record<string, string | number | undefined> = {
      display: cartLine.variant?.price.amount
        ? parseFloat(cartLine.variant?.price.amount as unknown as string)
        : 0.0,
      compare: cartLine.__contentfulItem?.discountPrice
        ? parseFloat(cartLine.__contentfulItem?.discountPrice)
        : undefined,
    };

    if (cartLine.discountAllocations) {
      for (const { allocatedAmount } of cartLine.discountAllocations) {
        const discount = parseFloat(
          allocatedAmount.amount as unknown as string,
        );
        if (discount > 0 && p.display) {
          if (!p.compare) p.compare = p.display;
          p.display = (p.display as number) - discount;
        }
      }
    }

    if (typeof p.display === 'number') p.display = roundPrice(p.display, 2);
    if (typeof p.compare === 'number') p.compare = roundPrice(p.compare, 2);
    else p.compare = undefined;

    return p;
  }, [cartLine, discounts]);

  return (
    <>
      {isMobile ? (
        <MobileUi
          theme={theme}
          cartLine={cartLine}
          qty={qty}
          staticContent={staticContent}
          removeItem={handleRemoveItem}
          updateQuantity={handleUpdateQuantity}
          hasDivider={hasDivider}
          loading={loading}
          prices={prices}
        />
      ) : (
        <DesktopUi
          theme={theme}
          cartLine={cartLine}
          qty={qty}
          staticContent={staticContent}
          removeItem={handleRemoveItem}
          updateQuantity={handleUpdateQuantity}
          loading={loading}
          prices={prices}
        />
      )}
    </>
  );
};

export default LineItem;
