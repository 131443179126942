// @ts-nocheck
import React from 'react';
import { HeroTextBanner } from '@/components/Hero/Banner/HeroTextBanner';
import usePageContext from '@/utils/context/page';
import { Accordion, Container, Stack } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import Link from '@/components/Link/Link';

const Sitemap: React.FC = () => {
  const { pages } = usePageContext();

  // Group the pages by page type
  const groupedPages = pages.reduce(
    (groups: Record<string, Record<string, string>>, page) => {
      const pageType = page.type;
      if (!groups[pageType]) {
        groups[pageType] = [];
      }
      groups[pageType].push(page);
      return groups;
    },
    {},
  );

  return (
    <>
      <HeroTextBanner title={'Sitemap'} />
      <Container>
        <Accordion>
          {Object.entries(groupedPages).map(([pageType, pages], index) => {
            if (
              [
                'Account',
                'Form',
                'Cart',
                'Error',
                'Quiz',
                'QuizResult',
                'Search',
                'Wishlist',
              ].includes(pageType)
            )
              return null;
            return (
              <Accordion.Item
                value={`${index}`}
                key={index}>
                <Accordion.Control>
                  <NoozText>{pageType}</NoozText>
                </Accordion.Control>
                <Accordion.Panel>
                  <Stack>
                    {pages.map((page, index) => {
                      return typeof page.path === 'string' ? (
                        <Link
                          href={`/${page.path}`}
                          key={`${index}_${index}`}>
                          {page.path || 'Home'}
                        </Link>
                      ) : null;
                    })}
                  </Stack>
                </Accordion.Panel>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Container>
    </>
  );
};

export default Sitemap;
