import { capitalizeFirstLetter } from '@/utils';
import { BasicNoozBadge } from '@/components/Nooz/NoozBadge/NoozBadge';
import React, { useMemo } from 'react';

const CategoriesBadges = ({
  categories,
  dictionary,
}: {
  log?: boolean;
  categories?: string | number[];
  dictionary: Record<any, any>;
}) => {
  const list = useMemo<number[]>(() => {
    switch (typeof categories) {
      case 'string':
        try {
          return JSON.parse(categories);
        } catch (e) {
          return [];
        }
      case 'object':
        return Array.isArray(categories) ? categories : [];
      default:
        return [];
    }
  }, [categories]);

  return (
    <>
      {list.map((categoryId: number, i: number) => {
        if (!dictionary[categoryId]) return null;
        const name = (dictionary[categoryId].display_name || '')
          .split(' / ')
          .filter((el: any) => el !== 'glasses')
          .map((el: any) => capitalizeFirstLetter(el))
          .join(' ');
        return (
          <BasicNoozBadge
            key={i}
            color={'title'}
            cssProps={{ padding: '3px 10px' }}>
            {name}
          </BasicNoozBadge>
        );
      })}
    </>
  );
};
export default CategoriesBadges;
