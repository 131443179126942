import { Box, Divider, Flex, Group, Image, Indicator } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import React, { useEffect, useMemo, useState } from 'react';
import useStaticContent from '@/utils/hook/useStaticContent';
import { IColor, IImage } from '@/@types/generated/contentful';
import { STATIC_SIZE_KEY } from '@/components/Product/ProductSizePicker.tsx/ProductSizePicker';
import { IStepVariantSelection, PackProductOption } from '@/interface.custom';
import { useRouter } from 'next/router';
import useLocalizedSlug from '@/utils/hook/useLocalizedSlug';
import i18n from '@/helpers/i18n';

const Step = ({
  product,
  handleSelectVariant,
  stocks,
}: IStepVariantSelection) => {
  const staticContent = useStaticContent();
  const [selectedVariantImage, setSelectedVariantImage] = useState(
    product?.posterImage,
  );
  const [tmpColor, setTmpColor] = useState<string | undefined>();
  const [selectedOptions, setSelectedOpts] = useState<Record<string, any>>(
    () => {
      const opts: Record<string, any> = {};
      product.shopifyProduct.options.forEach((o: Record<string, any>) => {
        opts[o.name] = o.values.length === 1 ? o.values[0] : null;
      });
      if ('Color' in opts || 'Couleur' in opts)
        opts.Color = product.variants?.[0].fields.color?.fields.shopifyKey;
      return opts;
    },
  );

  const Options = useMemo(() => {
    const opts = product.shopifyProduct.options as {
      name: string;
      values: string[];
    }[];
    const OptComponents: JSX.Element[] = [];

    if (opts.find((o) => ['Color', 'Couleur'].includes(o.name))) {
      const variants: (IColor & {
        id: string;
        image?: string;
        new?: boolean;
      })[] = product.variants.reduce((prev, opt) => {
        if (
          !prev.find(
            // @ts-ignore
            (x: IColor & { id: string }) => opt.fields.color.id === x.id,
          )
        ) {
          if (opt.fields?.posterImages?.[0] || opt.fields?.posterImage) {
            // @ts-ignore
            prev.push({
              ...opt.fields.color,
              new: opt.fields.new,
              image: (
                (opt.fields?.posterImages?.[0] as IImage) ||
                opt.fields?.posterImage
              )?.fields?.media?.fields?.file?.url,
            } as IColor & { id: string; image?: string });
          }
        }
        return prev;
      }, []);

      const colorTxt = variants.find(
        (v) => v.fields.shopifyKey === selectedOptions.Color,
      )?.fields.text;
      OptComponents.push(
        <React.Fragment key={'color'}>
          <Divider />
          <Box
            mt={20}
            mb={20}>
            <NoozText
              color={'title'}
              fw={900}
              size={14}
              mb={16}>
              {i18n(staticContent, 'color')}
              <NoozText
                span
                size={14}
                fw={400}
                color={'text'}>
                {colorTxt || tmpColor ? ` - ${tmpColor || colorTxt}` : null}
              </NoozText>
            </NoozText>
            <Group>
              {variants?.map((v) => (
                <Indicator
                  offset={4}
                  size={12}
                  withBorder
                  disabled={!v.new}
                  key={`product_color_${v.id}`}>
                  <Box
                    sx={(theme) => ({
                      height: 28,
                      width: 28,
                      borderRadius: 28,
                      cursor: 'pointer',
                      border: `1px solid ${theme.colors.gray[4]}`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      transition: 'border-width 0.15s linear',

                      background: v.fields.hex,
                      // background: v.fields.color?.fields.hex,
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',

                      '&:hover': {
                        transition: 'border-width 0.15s linear',
                        borderWidth: 4,
                      },
                    })}
                    onMouseEnter={() => setTmpColor(v.fields.text)}
                    onMouseLeave={() => setTmpColor(undefined)}
                    style={{}}
                    onClick={() => {
                      setSelectedVariantImage(v.image);
                      setSelectedOpts((prev) => ({
                        ...prev,
                        Color: v.fields.shopifyKey,
                      }));
                    }}>
                    {selectedOptions.Color === v.fields.shopifyKey ? (
                      <Box
                        sx={(theme) => ({
                          background: theme.white,
                          height: 14,
                          width: 14,
                          borderRadius: 28,
                        })}
                      />
                    ) : null}
                  </Box>
                </Indicator>
              ))}
            </Group>
          </Box>
        </React.Fragment>,
      );
    }

    const diopters = opts.find((o) => o.name === 'Diopter');

    if (diopters && diopters?.values?.length > 1) {
      OptComponents.push(
        <React.Fragment key={'diopter'}>
          <Divider />
          <Box
            mt={20}
            mb={12}>
            <NoozText
              color={'title'}
              fw={900}
              size={14}
              mb={16}>
              {i18n(staticContent, 'diopter')}
              <NoozText
                span
                size={14}
                fw={400}
                color={'text'}>
                {` - ${i18n(staticContent, 'diopterSub')}`}
              </NoozText>
            </NoozText>
            <Group>
              {diopters.values.map((d) => {
                const cOpt = { ...selectedOptions, Diopter: d };
                const variant = product.shopifyProduct.variants.find(
                  (vr: Record<string, any>) => {
                    let match = true;
                    Object.entries(cOpt).forEach(([key, value]) => {
                      if (value.toLowerCase() !== vr[key].toLowerCase()) {
                        match = false;
                      }
                    });

                    return match;
                  },
                );
                const isAvailable = stocks[variant.id] > 0;

                return (
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      width: 52,
                      height: 26,
                      borderRadius: 13,
                      cursor: 'pointer',
                      border: `1px solid ${theme.colors.text[0]}`,

                      ...(d === selectedOptions.Diopter
                        ? {
                            color: theme.white,
                            backgroundColor: theme.colors.text[0],
                          }
                        : {}),
                      ...(!isAvailable
                        ? {
                            cursor: 'not-allowed',
                            opacity: 0.5,
                          }
                        : {}),
                    })}
                    onClick={() => {
                      setSelectedOpts((prev) => ({ ...prev, Diopter: d }));
                    }}
                    key={`product_size_${d}`}>
                    <NoozText size={12}>
                      {`+ ${d.replace('+', '')}` || 'NA'}
                    </NoozText>
                  </Box>
                );
              })}
            </Group>
          </Box>
        </React.Fragment>,
      );
    }

    const sizes = opts.find((o) => o.name === 'Size');
    if (sizes) {
      OptComponents.push(
        <React.Fragment key={'sizes'}>
          <Divider />
          <Box
            mt={20}
            mb={20}>
            <NoozText
              color={'title'}
              fw={900}
              size={14}
              mb={16}>
              {staticContent.sizeLabel}
              <NoozText
                span
                size={14}
                fw={400}
                color={'text'}>
                {staticContent.sizeSub ? ` - ${staticContent.sizeSub}` : ''}
              </NoozText>
            </NoozText>
            <Group>
              {sizes.values.map((s) => {
                const cOpt = { ...selectedOptions, Size: s };
                const variant = product.shopifyProduct.variants.find(
                  (vr: Record<string, any>) => {
                    let match = true;
                    Object.entries(cOpt).forEach(([key, value]) => {
                      if (value.toLowerCase() !== vr[key].toLowerCase()) {
                        match = false;
                      }
                    });

                    return match;
                  },
                );
                const isAvailable = stocks[variant.id] > 0;

                return (
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      width: 52,
                      height: 26,
                      borderRadius: 13,
                      cursor: 'pointer',
                      border: `1px solid ${theme.colors.text[0]}`,

                      ...(s === selectedOptions.Size
                        ? {
                            color: theme.white,
                            backgroundColor: theme.colors.text[0],
                          }
                        : {}),
                      ...(!isAvailable
                        ? {
                            cursor: 'not-allowed',
                            opacity: 0.5,
                          }
                        : {}),
                    })}
                    onClick={() => {
                      setSelectedOpts((prev) => ({ ...prev, Size: s }));
                    }}
                    key={`product_size_${s}`}>
                    <NoozText size={12}>
                      {staticContent[STATIC_SIZE_KEY[s]] || 'NA'}
                    </NoozText>
                  </Box>
                );
              })}
            </Group>
          </Box>
        </React.Fragment>,
      );
    }

    return OptComponents;
  }, [product, staticContent, selectedOptions, tmpColor]);

  useEffect(() => {
    // all options selected
    if (Object.values(selectedOptions).filter((v) => !v).length === 0) {
      const variant = product.shopifyProduct.variants.find(
        (vr: Record<string, any>) => {
          let match = true;
          Object.entries(selectedOptions).forEach(([key, value]) => {
            if (value.toLowerCase() !== vr[key].toLowerCase()) {
              match = false;
            }
          });

          return match;
        },
      );
      if (variant) {
        handleSelectVariant({
          ...product.variants.find((v) => {
            if (variant.sku.includes('-P-')) {
              const pos = (variant.sku as string).indexOf('-P');
              return (
                v.fields.title === (variant.sku as string).slice(0, pos) ||
                v.fields.title === (variant.sku as string).slice(0, pos + 2)
              );
            }
            return v.fields.title === variant.sku;
          }),
          shopifyInfo: variant,
          selectedOptions,
          product,
        });
      }
    } else {
      if (selectedOptions.Diopter === null) {
        handleSelectVariant(staticContent.pleaseSelectDiopter || 'NA');
      } else if (selectedOptions.Size === null) {
        handleSelectVariant(staticContent.pleaseSelectSize || 'NA');
      }
    }
  }, [selectedOptions, product]);

  return (
    <Flex
      direction={'column'}
      sx={{
        flex: 1,
        overflow: 'overlay',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}>
      <Box
        w={'100%'}
        sx={() => ({
          width: '100%',
          marginBottom: 16,
        })}>
        <Flex
          key={product.id}
          direction={'row'}
          w={'100%'}>
          <Flex
            align={'center'}
            justify={'center'}>
            <Box
              h={150}
              w={150}
              sx={{
                overflow: 'hidden',
                borderRadius: 20,
              }}>
              <Image
                fit={'contain'}
                height={150}
                width={150}
                src={selectedVariantImage + '?fm=webp'}
                alt={product.altText}
              />
            </Box>
          </Flex>
          <Flex
            px={20}
            direction={'column'}>
            <Box>
              <NoozText
                size={24}
                fw={700}>
                {product.label}
              </NoozText>
              <NoozText
                size={16}
                fw={500}
                color={'text'}>
                {product.subLabel}
              </NoozText>
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Box w={'100%'}>{Options}</Box>
    </Flex>
  );
};

const StepProductSelection = ({
  step,
  product,
  handleSelectVariant,
  stocks,
}: {
  stocks: Record<string, number>;
  step: number;
  product?: PackProductOption;
  handleSelectVariant: any;
}) =>
  step === 2 && product ? (
    <Step
      stocks={stocks}
      product={product}
      handleSelectVariant={handleSelectVariant}
    />
  ) : null;

export default StepProductSelection;
