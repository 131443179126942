import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Box, createStyles } from '@mantine/core';
import usePageContext from '@/utils/context/page';
import { PackProductReco } from '@/components/Product/ProductReco/ProductReco';
import PackProvider from '@/utils/context/PackContext';
import { IPackData } from '@/interface.custom';
import useStickyHeader from '@/utils/hook/useStickyProduct';
import PackProductInformation from '@/components/pages/ProductPack/PackProductInformation';
import ProductDisplayer from '@/components/ProductDisplayer/ProductDisplayer';
import PackProductStockProvider from '@/utils/context/PackProductStockContext';
import { useSearchParams } from 'next/navigation';

const useLayoutStyles = createStyles((theme) => {
  return {
    wrapper: {
      minHeight: 'calc(100vh - 90px)',
      backgroundColor: theme.colors.lightGray[0],
      display: 'flex',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        flexDirection: 'column',
        height: 'fit-content',
        minHeight: 'unset',
      },
    },
    info: {
      backgroundColor: theme.white,
      width: '33.333vw',

      [theme.fn.smallerThan('lg')]: {
        width: '40vw',
        minWidth: 438,
      },
      [theme.fn.smallerThan('sm')]: {
        width: '100vw',
        minWidth: 'unset',
      },
    },
  };
});

const ProductPack: React.FC = () => {
  const router = useRouter();
  useStickyHeader('#sticky-add-to-cart');
  const { product, locale, localizedSlugs, shopifyProduct, shopifyPrice } =
    usePageContext() as unknown as any;
  const { classes } = useLayoutStyles();
  const sp = useSearchParams();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_DISABLE_PACK === 'true') {
      router.replace(`/${locale}`);
    }
  }, []);
  return (
    <PackProvider defaultPack={product as unknown as IPackData}>
      <PackProductStockProvider
        product={product as unknown as IPackData}
        locale={locale}>
        <Box
          mb={80}
          className={classes.wrapper}>
          <ProductDisplayer />
          <PackProductInformation className={classes.info} />
        </Box>
        <PackProductReco />
        {/* <StaticContentTrustpilotReviews /> */}
      </PackProductStockProvider>
    </PackProvider>
  );
};

export default ProductPack;
