import {
  isValidPhoneNumber,
  Value,
  parsePhoneNumber,
} from 'react-phone-number-input';
import PhoneInputReact from 'react-phone-number-input/input';
import {
  createStyles,
  Group,
  Input,
  TextInputProps,
  clsx,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import usePageContext from '@/utils/context/page';
import { CountryCode } from 'libphonenumber-js';
import { CountrySelector } from '@/components/Form/CountryInput/CountryInput';

const useStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
  phoneWrapper: {
    flex: 1,
  },
  label: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
  },

  icon: {
    transition: 'transform 150ms ease',
    transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
  },
  dropDown: {
    maxHeight: 250,
    overflow: 'auto',
  },
}));

interface PhoneInputProps {
  inputProps?: TextInputProps;
  value?: any;
  onChange?: (value: string) => void;
}

interface UIProps {
  inputProps: TextInputProps;
  value?: any;
  onChange?: (v: any) => void;
  country?: CountryCode;
  setCountry: (v: string) => void;
  phoneNumber: string;
  setPhoneNumber: (v: string) => void;
}

export const isValidPhone = (phone?: string, required?: boolean) => {
  return required
    ? phone && isValidPhoneNumber(phone)
    : (phone && isValidPhoneNumber(phone)) || phone === '' || !phone;
};
const UI = ({
  inputProps,
  country,
  setCountry,
  phoneNumber,
  setPhoneNumber,
}: UIProps) => {
  const { classes } = useStyles({ opened: false });
  return (
    <Input.Wrapper {...inputProps}>
      <Group spacing={'md'}>
        <CountrySelector
          value={country}
          setValue={setCountry}
        />

        <PhoneInputReact
          country={country}
          placeholder={inputProps.placeholder}
          value={phoneNumber as Value}
          className={clsx(classes.phoneWrapper)}
          onChange={(v) => {
            setPhoneNumber(v || '');
          }}
          // placeholder={placeholder}
          // @ts-ignore
          inputComponent={Input}
          disabled={inputProps.disabled}
          required={inputProps.required}
          // autoComplete={autoComplete}
          // className={`border-none ${focusStyle(color, hasFocus)}`}
        />
        {/*<Input*/}
        {/*  id={inputProps?.id}*/}
        {/*  value={phoneNumber}*/}
        {/*  placeholder={inputProps.placeholder}*/}
        {/*  onChange={(event) => setPhoneNumber(event.currentTarget.value)}*/}
        {/*/>*/}
      </Group>
    </Input.Wrapper>
  );
};

const PhoneInput = ({ inputProps = {}, value, onChange }: PhoneInputProps) => {
  const { locale } = usePageContext();

  const [country, setCountry] = useState<CountryCode | undefined>(undefined);
  const [phoneNumber, setPhoneNumber] = useState(value || '');

  useEffect(() => {
    setCountry(
      (typeof value === 'string' && parsePhoneNumber(value)?.country) ||
        ((locale?.split('-')?.[1]?.toUpperCase() === 'INT'
          ? 'FR'
          : locale?.split('-')?.[1]?.toUpperCase()) as CountryCode),
    );
  }, []);

  useEffect(() => {
    if (!value || value === '') setPhoneNumber('');
  }, [value]);

  return (
    <UI
      inputProps={inputProps}
      country={country}
      setCountry={(v: string) => {
        setCountry(v.toUpperCase() as CountryCode);
        setPhoneNumber('');
        onChange?.('');
      }}
      phoneNumber={phoneNumber}
      setPhoneNumber={(v: string) => {
        setPhoneNumber(v);
        onChange?.(v);
      }}
    />
  );
};

export default PhoneInput;
