import React from 'react';
import { MantineNumberSize, Progress } from '@mantine/core';
import { useFreeShipping } from '@/utils/hook/cart/useFreeShipping';

const CartFreeShipping = ({
  size = 'xl',
  showLabel = false,
}: {
  showLabel?: boolean;
  size?: MantineNumberSize;
}) => {
  const freeShipping = useFreeShipping();

  return freeShipping ? (
    <Progress
      value={freeShipping.percentage}
      label={showLabel ? freeShipping.amount : undefined}
      size={size}
      radius='xl'
    />
  ) : null;
};

export default CartFreeShipping;
