import { createStyles, Divider, Grid, Image, Tabs } from '@mantine/core';
import React, { useMemo, useState } from 'react';
import usePageContext from '@/utils/context/page';
import { IPage } from '@/@types/generated/contentful';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { IModalContext } from '@/interface.custom';
import { STATIC_SIZE_KEY } from '@/components/Product/ProductSizePicker.tsx/ProductSizePicker';

const useStyles = createStyles((theme) => ({
  wrapper: {
    margin: '0 20px 20px',
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      margin: '0 0 20px',
      '& > .mantine-Grid-root': {
        margin: 0,
      },
    },
  },
  divider: {
    margin: '40px 0',
  },
  row: {
    display: 'grid',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    height: '100%',
    justifyContent: 'center',
  },
  btnGroup: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    '& > *:not(:last-of-type)': {
      marginBottom: 20,
    },
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  whiteBadge: {
    backgroundColor: theme.white,
    border: '1px solid #D7D7D7',
    height: 28,
  },
  disabled: {
    opacity: 0.5,
    backgroundColor: '#F5F3F3',
  },
  labelButton: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    minWidth: 200,
    '& > div.text': {
      flex: 1,
      textAlign: 'center',
    },
  },
}));

const FrameFitter = ({
  setModal,
  size,
}: {
  setModal: IModalContext['setModal'];
  size?: string;
}) => {
  const { page, product } = usePageContext();
  const staticContent = useMemo(
    // @ts-ignore
    () => (page as IPage).fields.pageType.staticItems,
    [page],
  );
  const [selected, setSelected] = useState(size);

  const { classes } = useStyles();
  const frame = selected
    ? (product?.fields.frames || []).find(
        (f: any) => f.fields.shopifyKey === selected,
      )
    : (product?.fields.frames || [])?.[0];
  // const isKid = /^cruzy-/.test(product?.fields.title || '');

  return (
    <>
      {(product?.fields.frames || []).length > 1 ? (
        <Tabs
          value={selected}
          onTabChange={setSelected as any}>
          <Tabs.List grow>
            {(product?.fields.frames || [])
              .sort((a, b) => {
                const aValue =
                  a.fields.shopifyKey === 'S'
                    ? 1
                    : a.fields.shopifyKey === 'M'
                    ? 2
                    : 3;
                const bValue =
                  b.fields.shopifyKey === 'S'
                    ? 1
                    : b.fields.shopifyKey === 'M'
                    ? 2
                    : 3;

                return aValue - bValue;
              })
              .map((f: any) => {
                return (
                  <Tabs.Tab
                    key={f.fields.shopifyKey}
                    value={f.fields.shopifyKey}>
                    {staticContent[STATIC_SIZE_KEY[f.fields.shopifyKey]]}
                  </Tabs.Tab>
                );
              })}
          </Tabs.List>
        </Tabs>
      ) : null}
      {frame ? (
        <div className={classes.wrapper}>
          {frame.fields.frameWidth ? (
            <>
              <Grid gutter={60}>
                <Grid.Col
                  sm={6}
                  xs={12}>
                  <Image
                    alt={staticContent.img_frameWidth?.alt}
                    src={staticContent.img_frameWidth?.url + '?fm=webp'}
                    withPlaceholder
                  />
                </Grid.Col>
                <Grid.Col
                  sm={6}
                  xs={12}>
                  <div className={classes.centered}>
                    <NoozText
                      color={'title'}
                      size={18}
                      fw={500}>
                      {staticContent.modalFitFrameWidthTitle}
                    </NoozText>
                    <NoozText
                      color={'main'}
                      size={18}
                      fw={900}>
                      {`${frame.fields.frameWidth || ''} mm`}
                    </NoozText>
                    <NoozText
                      color={'text'}
                      mt={20}
                      size={14}
                      fw={400}>
                      {staticContent.modalFitFrameWidthSubLabel}
                    </NoozText>
                  </div>
                </Grid.Col>
              </Grid>
              <Divider className={classes.divider} />
            </>
          ) : null}
          {frame.fields.templeLength ? (
            <>
              <Grid gutter={60}>
                <Grid.Col
                  sm={6}
                  xs={12}>
                  <Image
                    alt={staticContent.img_frameTempleLength?.alt}
                    src={staticContent.img_frameTempleLength?.url + '?fm=webp'}
                    withPlaceholder
                  />
                </Grid.Col>
                <Grid.Col
                  sm={6}
                  xs={12}>
                  <div className={classes.centered}>
                    <NoozText
                      color={'title'}
                      size={18}
                      fw={500}>
                      {staticContent.modalFitTempleLengthTitle}
                    </NoozText>
                    <NoozText
                      color={'main'}
                      size={18}
                      fw={900}>
                      {`${frame.fields.templeLength || ''} mm`}
                    </NoozText>
                    <NoozText
                      color={'text'}
                      mt={20}
                      size={14}
                      fw={400}>
                      {staticContent.modalFitTempleLengthSubLabel}
                    </NoozText>
                  </div>
                </Grid.Col>
              </Grid>
              <Divider className={classes.divider} />
            </>
          ) : null}
          {frame.fields.lensWidth ? (
            <>
              <Grid gutter={60}>
                <Grid.Col
                  sm={6}
                  xs={12}>
                  <Image
                    alt={staticContent.img_lenseWidth?.alt}
                    src={staticContent.img_lenseWidth?.url + '?fm=webp'}
                    withPlaceholder
                  />
                </Grid.Col>
                <Grid.Col
                  sm={6}
                  xs={12}>
                  <div className={classes.centered}>
                    <NoozText
                      color={'title'}
                      size={18}
                      fw={500}>
                      {staticContent.modalFitLensWidthTitle}
                    </NoozText>
                    <NoozText
                      color={'main'}
                      size={18}
                      fw={900}>
                      {`${frame.fields.lensWidth || ''} mm`}
                    </NoozText>
                  </div>
                </Grid.Col>
              </Grid>
              <Divider className={classes.divider} />
            </>
          ) : null}
          {frame.fields.bridge ? (
            <>
              <Grid gutter={60}>
                <Grid.Col
                  sm={6}
                  xs={12}>
                  <Image
                    alt={staticContent.img_bridgeWidth?.alt}
                    src={staticContent.img_bridgeWidth?.url + '?fm=webp'}
                    withPlaceholder
                  />
                </Grid.Col>
                <Grid.Col
                  sm={6}
                  xs={12}>
                  <div className={classes.centered}>
                    <NoozText
                      color={'title'}
                      size={18}
                      fw={500}>
                      {staticContent.modalFitBridgeTitle}
                    </NoozText>
                    <NoozText
                      color={'main'}
                      size={18}
                      fw={900}>
                      {`${frame.fields.bridge || ''} mm`}
                    </NoozText>
                  </div>
                </Grid.Col>
              </Grid>
              <Divider className={classes.divider} />
            </>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default FrameFitter;
