import usePageContext from '@/utils/context/page';
import { formatCfItems } from '@/helpers/formatCfItems';
import React, { useMemo } from 'react';
import {
  BackgroundImage,
  Box,
  Divider,
  Flex,
  Grid,
  MediaQuery,
  Title,
  useMantineTheme,
} from '@mantine/core';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import {
  IBlock,
  IButton,
  IIcon,
  IImage,
  ILabel,
} from '@/@types/generated/contentful';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { BasicNoozBadge } from '@/components/Nooz/NoozBadge/NoozBadge';
import { useModalContext } from '@/utils/context/modal';
import i18n from '@/helpers/i18n';
import useStaticContent from '@/utils/hook/useStaticContent';

const EyeTestLanding = ({ source, ...props }: IEyeTestStep) => {
  const theme = useMantineTheme();
  const h = useHeaderHeight();
  const { setModal, modal } = useModalContext();
  const staticContent = useStaticContent();

  const { locale } = usePageContext();

  const formElements = useMemo<null | Record<string, any>>(() => {
    if (source.startStep) {
      let form = formatCfItems(source.startStep);
      Object.entries(form).forEach(([key, value]) => {
        if ((value as Record<string, any>)?.fields?.items) {
          form[key] = formatCfItems(value);
        }
      });
      return form;
    } else return null;
  }, [source]);

  const instructions = useMemo<null | Record<string, any>>(() => {
    if (source.startStep) {
      return formatCfItems(formatCfItems(source.startStep).instructions);
    } else return null;
  }, [source]);

  const handleStartOnMobile = () => {
    setModal({
      ...modal,
      opened: true,
      // @ts-ignore
      styles: {
        content: {
          borderRadius: 30,
          height: '100%',
        },
        header: {
          backgroundColor: 'transparent',
        },
        body: {
          height: '100%',
          marginTop: '-54px',
        },
      },
      body: (
        <Flex
          direction={'column'}
          align={'center'}
          justify={'space-between'}
          gap={'2rem'}
          h={'100% '}
          p={'40px 0'}>
          <NoozText
            color={'main'}
            align={'center'}
            size={26}
            lh={'31px'}
            weight={900}>
            {(instructions?.label as ILabel).fields.text}
          </NoozText>
          {(instructions?.steps as IBlock)?.fields.items
            ? (instructions?.steps as IBlock).fields.items?.map((step, idx) => {
                const { icon, label, subLabel } = formatCfItems(step);

                return (
                  <Flex
                    direction={'column'}
                    sx={(theme) => ({
                      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                        alignItems: 'center',
                        width: '75%',
                        minWidth: 200,
                        maxWidth: 350,
                        margin: '0 auto',
                      },
                    })}
                    key={(step as unknown as { id: string }).id}>
                    <BasicNoozBadge
                      size={10}
                      color={'title'}>
                      {`${(instructions?.stepLabel as ILabel).fields.text} ${
                        idx + 1
                      }`.toUpperCase()}
                      <Flex ml={8}>
                        <NoozIcon
                          iconKey={(icon as IIcon).fields.iconKey}
                          size={14}
                          color={theme.colors.main[0]}
                        />
                      </Flex>
                    </BasicNoozBadge>
                    <NoozText
                      mt={10}
                      size={14}
                      sx={(theme) => ({
                        [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                          align: 'center',
                        },
                      })}
                      weight={700}>
                      {(label as ILabel).fields.text}
                      <NoozText
                        component={'span'}
                        size={14}
                        weight={500}
                        ml={4}>
                        {(subLabel as ILabel).fields.text}
                      </NoozText>
                    </NoozText>
                  </Flex>
                );
              })
            : null}
          <NoozButton
            mt={26}
            sx={{
              minWidth: 200,
              width: 'fit-content',
              '& .mantine-Button-label > *': {
                marginLeft: 8,
              },
            }}
            onClick={() => {
              setModal({
                opened: false,
                body: '',
                className: '',
                size: undefined,
              });
              props.goNext();
            }}>
            {
              (formElements?.online.button as unknown as IButton).fields.label
                ?.fields?.text
            }
            <NoozIcon
              iconKey={'ChevronRight'}
              color={'white'}
              size={14}
            />
          </NoozButton>
        </Flex>
      ),
    });
  };
  if (!formElements || !instructions) {
    console.error('No contentful startStep');
    return null;
  }

  return (
    <Grid
      gutter={0}
      sx={{
        minHeight: `calc(100vh - ${h.wrapper + h.banner}px)`,
      }}>
      <Grid.Col
        md={8}
        xs={12}>
        <Flex
          direction={'column'}
          justify={'space-evenly'}
          sx={(theme) => ({
            maxWidth: 666,
            margin: '0 auto',
            padding: '36px 0',
            height: '100%',
            [theme.fn.smallerThan(theme.breakpoints.lg)]: {
              padding: '36px',
            },
            [theme.fn.smallerThan(theme.breakpoints.md)]: {
              maxWidth: '80vw',
              padding: '36px 0',
            },
          })}>
          <Flex direction={'column'}>
            <Title
              mt={10}
              order={1}
              color={'title'}
              size={40}
              weight={'bold'}>
              {formElements.online.label.fields.text}
            </Title>
            <NoozText
              mt={18}
              color={'text'}
              size={16}
              weight={500}
              component={'h2'}>
              {formElements.online.subLabel.fields.text}
            </NoozText>
            <Flex
              justify={{ sm: 'space-between' }}
              align={{ base: 'center', sm: 'normal' }}
              direction={{ base: 'column', sm: 'row' }}
              gap={'1.5rem'}
              mt={26}>
              <MediaQuery
                largerThan={theme.breakpoints.sm}
                styles={{ display: 'none' }}>
                <NoozButton
                  mt={26}
                  sx={{
                    minWidth: 200,
                    width: 'fit-content',
                    '& .mantine-Button-label > *': {
                      marginLeft: 8,
                    },
                  }}
                  onClick={handleStartOnMobile}>
                  {
                    (formElements.online.button as unknown as IButton).fields
                      .label?.fields?.text
                  }
                  <NoozIcon
                    iconKey={'ChevronRight'}
                    color={'white'}
                    size={14}
                  />
                </NoozButton>
              </MediaQuery>
              <MediaQuery
                smallerThan={theme.breakpoints.sm}
                styles={{ display: 'none' }}>
                <NoozButton
                  sx={{
                    minWidth: 200,
                    width: 'fit-content',
                    '& .mantine-Button-label > *': {
                      marginLeft: 8,
                    },
                  }}
                  onClick={() => {
                    props.goNext();
                  }}>
                  {
                    (formElements.online.button as unknown as IButton).fields
                      .label?.fields?.text
                  }
                </NoozButton>
              </MediaQuery>

              <Box
                sx={{
                  alignSelf: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  const PDF_LINKS = [
                    'ca',
                    'de',
                    'es',
                    'eu',
                    'fr',
                    'gb',
                    'it',
                    'nl',
                    'pt',
                    'us',
                  ];
                  let language = locale?.split('-')?.[0] || 'eu';
                  if (!PDF_LINKS.includes(language)) language = 'eu';

                  let link = document.createElement('a');
                  link.download = 'eye-test.pdf';
                  link.href = `/pdf/eye-test/eye-test-${language}.pdf`;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}>
                <Flex
                  gap={4}
                  justify='center'
                  align='center'
                  sx={(theme) => ({
                    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                      flexDirection: 'column',
                    },
                  })}>
                  <NoozText
                    transform={'uppercase'}
                    size={14}
                    weight={500}
                    color={'text'}
                    component={'span'}>
                    {i18n(staticContent, 'paperVersionLabel')}
                  </NoozText>
                  <NoozText
                    color={'title'}
                    transform={'uppercase'}
                    size={14}
                    weight={900}
                    underline
                    component={'span'}>
                    {i18n(staticContent, 'eyeTestDownloadLabel')}
                  </NoozText>
                </Flex>
              </Box>
            </Flex>
          </Flex>
          <MediaQuery
            smallerThan={theme.breakpoints.sm}
            styles={{ display: 'none' }}>
            <Divider
              mt={50}
              mb={50}
            />
          </MediaQuery>

          <MediaQuery
            smallerThan={theme.breakpoints.sm}
            styles={{ display: 'none' }}>
            <Flex
              direction={'column'}
              gap={40}>
              <NoozText
                color={'title'}
                size={34}
                lh={1}
                fw={500}>
                {(instructions.label as ILabel).fields.text}
              </NoozText>
              <Grid gutter={33}>
                {(instructions.steps as IBlock)?.fields.items
                  ? (instructions.steps as IBlock).fields.items?.map(
                      (step, idx) => {
                        const { icon, label, subLabel } = formatCfItems(step);

                        return (
                          <Grid.Col
                            sm={4}
                            xs={12}
                            key={(step as unknown as { id: string }).id}>
                            <Flex
                              direction={'column'}
                              sx={(theme) => ({
                                [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                                  alignItems: 'center',
                                  width: '75%',
                                  minWidth: 200,
                                  maxWidth: 350,
                                  margin: '0 auto',
                                },
                              })}>
                              <BasicNoozBadge color={'title'}>
                                {`${
                                  (instructions.stepLabel as ILabel).fields.text
                                } ${idx + 1}`.toUpperCase()}
                                <Flex ml={8}>
                                  <NoozIcon
                                    iconKey={(icon as IIcon).fields.iconKey}
                                    size={16}
                                    color={theme.colors.main[0]}
                                  />
                                </Flex>
                              </BasicNoozBadge>
                              <NoozText
                                mt={16}
                                size={16}
                                sx={(theme) => ({
                                  [theme.fn.smallerThan(theme.breakpoints.sm)]:
                                    {
                                      align: 'center',
                                    },
                                })}
                                weight={700}>
                                {(label as ILabel).fields.text}
                                <NoozText
                                  component={'span'}
                                  size={16}
                                  weight={500}
                                  ml={4}>
                                  {(subLabel as ILabel).fields.text}
                                </NoozText>
                              </NoozText>
                            </Flex>
                          </Grid.Col>
                        );
                      },
                    )
                  : null}
              </Grid>
            </Flex>
          </MediaQuery>
        </Flex>
      </Grid.Col>
      <Grid.Col
        xs={12}
        md={4}
        sx={(theme) => ({
          [theme.fn.smallerThan(theme.breakpoints.md)]: {
            padding: 0,
          },
        })}>
        <Box h={{ md: '100%' }}>
          {(formElements.eyeTestLookImage as IImage).fields.media?.fields.file
            .url ? (
            <BackgroundImage
              sx={(theme) => ({
                height: '100%',
                [theme.fn.smallerThan(theme.breakpoints.md)]: {
                  height: `calc(100vh - ${h.wrapper + h.banner}px)`,
                  borderTopRightRadius: 30,
                  borderTopLeftRadius: 30,
                },
              })}
              src={
                (formElements.eyeTestLookImage as IImage).fields.media?.fields
                  .file.url as string
              }
            />
          ) : null}
        </Box>
      </Grid.Col>
    </Grid>
  );
};

export default EyeTestLanding;
