import useStaticContent from '@/utils/hook/useStaticContent';
import usePageContext from '@/utils/context/page';
import { IButton, ILabel, IProductPack } from '@/@types/generated/contentful';
import React, { useState } from 'react';
import { IVariantName, usePackContext } from '@/utils/context/PackContext';
import AddToCartPackButton from '@/components/pages/ProductPack/AddToCartPackButton';
import { formatCfItems } from '@/helpers/formatCfItems';
import { ActionIcon, Box, Button, Divider, Flex } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import NoozBadge from '@/components/Nooz/NoozBadge/NoozBadge';
import { STATIC_SIZE_KEY } from '@/components/Product/ProductSizePicker.tsx/ProductSizePicker';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import ProductInformation, {
  usePackPackOptSelector,
  useProductInfoStyles,
} from '@/components/Product/ProductInformation/ProductInformation';
import Image from 'next/image';

const PackProductInformation = ({ className }: IProductInformation) => {
  const staticContent = useStaticContent();
  const {
    shopifyProduct,
    // @ts-ignore
    productOptions,
  } = usePageContext() as unknown as {
    shopifyProduct: Record<string, any>;
    product: IProductPack;
  };
  // use to force update the component
  const [, setIdentifier] = useState(42);
  const { pack, setPack } = usePackContext();
  const { classes } = useProductInfoStyles();
  const { openPackOptionSelection, hasLoaded } = usePackPackOptSelector();
  const options = pack.data.fields.options;

  return (
    <ProductInformation
      className={className}
      isPack
      price={shopifyProduct?.variants[0].price}
      strikePrice={shopifyProduct?.variants[0].compareAtPrice}
      actionsDesktop={<AddToCartPackButton />}
      actionsMobile={<AddToCartPackButton />}>
      <div className={classes.packOptions}>
        {options.map((opt, key) => {
          const { label, subLabel, button } = formatCfItems(opt.cta);

          const selectedVariant: undefined | IVariantName | null =
            // @ts-ignore
            pack.options[opt.key];

          const sample =
            typeof opt.products?.[0] === 'string'
              ? productOptions[opt.products?.[0]]
              : null;

          return (
            <React.Fragment
              key={`${key}-${
                opt.key in pack.options ? 'selected' : 'not-selected'
              }`}>
              <Divider
                key={`separator-${key}`}
                className={classes.divider}
              />
              <div>
                <NoozText
                  size={16}
                  fw={700}
                  color={'title'}>
                  {(label as ILabel).fields.text}
                  <NoozText
                    size={16}
                    fw={500}
                    color={'text'}
                    component={'span'}>
                    {` - ${(subLabel as ILabel)?.fields.text}`}
                  </NoozText>
                </NoozText>
                <Box className={'content'}>
                  <Box
                    sx={{ position: 'relative' }}
                    h={100}
                    w={100}>
                    {selectedVariant?.fields?.posterImages?.[0]?.fields.media
                      ?.fields?.file?.url || sample?.posterImage ? (
                      <Image
                        style={{
                          borderRadius: 8,
                          objectFit: 'contain',
                        }}
                        fill
                        alt={
                          selectedVariant?.fields?.posterImages?.[0]?.fields
                            .altText ||
                          sample?.altText ||
                          opt.key
                        }
                        src={
                          (selectedVariant?.fields?.posterImages?.[0]?.fields
                            .media?.fields?.file?.url || sample?.posterImage) +
                          '?fm=webp'
                        }
                      />
                    ) : undefined}
                  </Box>
                  <Flex
                    w='100%'
                    ml={18}
                    sx={{ flex: 1 }}>
                    {selectedVariant ? (
                      <Flex
                        w='100%'
                        align={'center'}
                        justify='space-between'>
                        <Flex
                          h={'100%'}
                          sx={{
                            flex: 1,
                            '& > *:not(:first-of-type)': { marginTop: 8 },
                          }}
                          align={'flex-start'}
                          justify={'flex-start'}
                          direction={'column'}>
                          <NoozText
                            size={19}
                            fw={900}>
                            {selectedVariant.name}
                          </NoozText>
                          {selectedVariant.fields?.color ? (
                            <NoozBadge
                              color={'title'}
                              badgeProps={{
                                color: 'dark',
                                variant: 'outline',
                                size: 'md',
                              }}
                              icon={
                                <Box
                                  h={10}
                                  w={10}
                                  sx={{
                                    borderRadius: 16,
                                    background:
                                      selectedVariant.fields.color.fields.hex,
                                    backgroundSize: 'cover',
                                  }}
                                />
                              }
                              textProps={{ size: 12 }}
                              text={
                                selectedVariant.fields.color.fields.text?.toUpperCase() as string
                              }
                            />
                          ) : null}
                          {selectedVariant.shopifyInfo &&
                          selectedVariant.shopifyInfo.selectedOptions.find(
                            (o: { name: string }) => o.name === 'Diopter',
                          ) &&
                          selectedVariant?.shopifyInfo?.selectedOptions?.find(
                            (o: { name: string }) => o.name === 'Diopter',
                          )?.value !== '0' ? (
                            <NoozBadge
                              color={'title'}
                              badgeProps={{
                                color: 'dark',
                                variant: 'outline',
                                size: 'md',
                              }}
                              textProps={{ size: 12 }}
                              text={`+ ${(
                                selectedVariant.shopifyInfo.selectedOptions.find(
                                  (o: { name: string }) => o.name === 'Diopter',
                                )?.value as string
                              )
                                .replace('+', '')
                                .trim()}`}
                            />
                          ) : null}
                          {selectedVariant.shopifyInfo.selectedOptions.find(
                            (o: { name: string }) => o.name === 'Size',
                          ) ? (
                            <NoozBadge
                              color={'title'}
                              badgeProps={{
                                color: 'dark',
                                variant: 'outline',
                                size: 'lg',
                              }}
                              textProps={{ size: 12 }}
                              text={
                                selectedVariant?.shopifyInfo?.selectedOptions?.find(
                                  (o: { name: string }) => o.name === 'Size',
                                )?.value
                                  ? staticContent[
                                      STATIC_SIZE_KEY[
                                        selectedVariant?.shopifyInfo.selectedOptions?.find(
                                          (o: { name: string }) =>
                                            o.name === 'Size',
                                        )?.value as string
                                      ]
                                    ]?.toUpperCase()
                                  : null
                              }
                            />
                          ) : null}
                        </Flex>
                        <ActionIcon
                          radius={'xl'}
                          variant={'outline'}
                          color={'title'}
                          size='lg'
                          onClick={() => {
                            setPack((prev) => {
                              if (!prev) return prev;

                              if (opt.key in prev.options)
                                // @ts-ignore
                                delete prev.options[opt.key];

                              return prev;
                            });
                            setIdentifier(Math.random());
                          }}>
                          <NoozIcon
                            strokeWidth={1.5}
                            iconKey={'Trash'}
                          />
                        </ActionIcon>
                      </Flex>
                    ) : (
                      <Flex direction={'column'}>
                        <NoozText
                          size={14}
                          fw={700}>
                          {`${staticContent.select} ${
                            staticContent[`glasses${key + 1}`]
                          }`}
                        </NoozText>
                        <Button
                          mt={14}
                          variant={'outline'}
                          color={'title'}
                          radius={'xl'}
                          disabled={!hasLoaded}
                          loading={!hasLoaded}
                          onClick={() => {
                            openPackOptionSelection(
                              // @ts-ignore
                              opt.key,
                              opt.products,
                            )();
                          }}>
                          {(button as IButton).fields.label?.fields.text}
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                </Box>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <Divider />
    </ProductInformation>
  );
};

export default PackProductInformation;
