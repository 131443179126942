import React, { useState } from 'react';
import { useForm } from '@mantine/form';
import {
  TextInput,
  Select,
  Group,
  Divider,
  Stack,
  Container,
  SelectItem,
  NumberInput,
} from '@mantine/core';
import CountryPicker from '@/components/Form/CountryPicker/CountryPicker';
import NoozNextApi, { NOOZ_NEXT_REGISTRY } from '@/utils/services/NoozNextApi';
import customNotification from '@/utils/customNotification';
import PhoneInput from '@/components/Form/PhoneInput/PhoneInput';
import { HeroTextBanner } from '@/components/Hero/Banner/HeroTextBanner';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import useStaticContent from '@/utils/hook/useStaticContent';
import TextLink from '@/components/Link/TextLink';

const Reseller = () => {
  const staticContent = useStaticContent();
  const [loading, setLoading] = useState(false);
  const form = useForm<ResellerFormProps>({
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
      // phone: (val) => (isValidPhone(val, true) ? null : 'invalid Phone number'),
    },
  });

  const onSubmit = ({
    firstName,
    lastName,
    name,
    province: _,
    ...formData
  }: Record<string, any>) => {
    setLoading(true);
    NoozNextApi.instance({
      ...NOOZ_NEXT_REGISTRY.ODOO.CREATE_PROSPECT,
      data: {
        prospect: {
          ...formData,
          name: name || `${firstName} ${lastName}`,
          contactName: `${firstName} ${lastName}`,
        },
        tagId: 722,
      },
    })
      .then(() => {
        customNotification.success({
          message: staticContent.submitResellerFormSuccess,
        });
        form.reset();
      })
      .catch(() => {
        customNotification.error({
          message: staticContent.submitResellerFormError,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <HeroTextBanner
        title={staticContent.title}
        subTitle={staticContent.subTitle}
      />
      <Container
        my={50}
        size={'sm'}>
        <TextLink
          text='Distributor or Agent ?'
          url={staticContent.distributorLinkSlug}
          type='animated-underline'
        />
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Stack>
            <>
              <TextInput
                required
                label={staticContent.emailLabel}
                placeholder={staticContent.emailPlaceholder}
                value={form.values.email || ''}
                onChange={(event) =>
                  form.setFieldValue('email', event.currentTarget.value)
                }
                error={form.errors.email && 'Invalid email'}
              />
              <TextInput
                required
                label={staticContent.firstNameLabel}
                placeholder={staticContent.firstNamePlaceholder}
                value={form.values.firstName || ''}
                onChange={(event) =>
                  form.setFieldValue('firstName', event.currentTarget.value)
                }
                error={form.errors.firstName && 'Invalid firstName'}
              />
              <TextInput
                required
                label={staticContent.lastNameLabel}
                placeholder={staticContent.lastNamePlaceholder}
                value={form.values.lastName || ''}
                onChange={(event) =>
                  form.setFieldValue('lastName', event.currentTarget.value)
                }
                error={form.errors.lastName && 'Invalid lastName'}
              />
              <TextInput
                label={staticContent.companyLabel}
                placeholder={staticContent.companyPlaceholder}
                value={form.values.name || ''}
                onChange={(event) =>
                  form.setFieldValue('name', event.currentTarget.value)
                }
                error={form.errors.name && 'Invalid company'}
              />
              <TextInput
                label={staticContent.websiteLabel}
                placeholder={staticContent.websitePlaceholder}
                value={form.values.website || ''}
                onChange={(event) =>
                  form.setFieldValue('website', event.currentTarget.value)
                }
                error={form.errors.website && 'Invalid website'}
              />
              <NumberInput
                required
                // min={1}
                label={staticContent.nbStoreLabel}
                placeholder={staticContent.nbStorePlaceholder}
                value={form.values.shop_count || ''}
                onChange={(value) =>
                  form.setFieldValue('shop_count', value as number)
                }
                error={form.errors.shop_count && 'Invalid howManyStores'}
              />
            </>

            <div style={{ margin: '1em 0' }}>
              <Divider />
            </div>

            <>
              <TextInput
                required
                label={staticContent.addr1Label}
                placeholder={staticContent.addr1Placeholder}
                value={form.values.street || ''}
                onChange={(event) =>
                  form.setFieldValue('street', event.currentTarget.value)
                }
                error={form.errors.street && 'Invalid street'}
              />
              <TextInput
                required
                label={staticContent.cityLabel}
                placeholder={staticContent.cityPlaceholder}
                value={form.values.city || ''}
                onChange={(event) =>
                  form.setFieldValue('city', event.currentTarget.value)
                }
                error={form.errors.city && 'Invalid city'}
              />
              <TextInput
                required
                label={staticContent.zipLabel}
                placeholder={staticContent.zipPlaceholder}
                value={form.values.zip || ''}
                onChange={(event) =>
                  form.setFieldValue('zip', event.currentTarget.value)
                }
                error={form.errors.zip && 'Invalid zip'}
              />
              <CountryPicker
                required
                label={staticContent.countryLabel}
                placeholder={staticContent.countryPlaceholder}
                value={form.values.country_code || ''}
                onChange={(value) => form.setFieldValue('country_code', value)}
                error={form.errors.country_code && 'Invalid country'}
              />
              <PhoneInput
                inputProps={{
                  required: true,
                  label: staticContent.phoneLabel,
                  placeholder: staticContent.phonePlaceholder,
                  error:
                    form.errors.phone && 'Invalid phone (use national format)',
                }}
                value={form.values.phone || ''}
                onChange={(value) => form.setFieldValue('phone', value)}
              />
            </>

            <div style={{ margin: '1em 0' }}>
              <Divider />
            </div>

            <>
              <Select
                required
                data={(staticContent.typeOfStores || []) as SelectItem[]}
                label={staticContent.storeTypeLabel}
                placeholder={staticContent.storeTypePlaceholder}
                value={form.values.b2b_type || ''}
                onChange={(value) =>
                  form.setFieldValue('b2b_type', value || '')
                }
                error={form.errors.b2b_type && 'Invalid typeOfStores'}
              />
              <TextInput
                label={staticContent.howDidYouHearAboutUsLabel}
                placeholder={staticContent.howDidYouHearAboutUsPlaceholder}
                value={form.values.source || ''}
                onChange={(event) =>
                  form.setFieldValue('source', event.currentTarget.value)
                }
                error={form.errors.source && 'Invalid howDidYouHearAboutUs'}
              />
              <TextInput
                label={staticContent.commentLabel}
                placeholder={staticContent.commentPlaceholder}
                value={form.values.comment || ''}
                onChange={(event) =>
                  form.setFieldValue('comment', event.currentTarget.value)
                }
                error={form.errors.comment && 'Invalid comments'}
              />
            </>
          </Stack>

          <Group
            position='apart'
            mt='xl'>
            <NoozButton
              color={'title'}
              type='submit'
              loaderPosition='center'
              loading={loading}>
              {staticContent.submitLabel}
            </NoozButton>
          </Group>
        </form>
      </Container>
    </>
  );
};

export default Reseller;
