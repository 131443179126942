import {
  TransformComponent,
  TransformWrapper,
  useControls,
  useTransformContext,
  useTransformEffect,
} from 'react-zoom-pan-pinch';
import { Image } from '@mantine/core';
import { PRODUCT_LAYOUT_MEDIA_QUERY } from '@/templates/Product';
import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from '@mantine/carousel';

const Slide = ({
  isDesktop,
  className,
  height,
  desktopImageWidth,
  image,
}: any) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const { zoomIn, zoomOut } = useControls();

  useTransformEffect(({ state, instance }) => {
    setIsZoomed(state.scale > state.previousScale);
    return () => {};
  });
  return (
    <Carousel.Slide
      sx={{
        cursor: isZoomed ? 'zoom-out' : 'zoom-in',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <TransformComponent>
        <Image
          onClick={() => {
            if (isZoomed) {
              zoomOut(2);
            } else {
              zoomIn(1);
            }
          }}
          classNames={className}
          sx={(theme) => ({
            '* .mantine-Image-imageWrapper': {
              display: 'flex',
              justifyContent: 'center',
              height: height || '100%',
              width: desktopImageWidth || '100%',
              [theme.fn.smallerThan(PRODUCT_LAYOUT_MEDIA_QUERY)]: {
                height: 'unset',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              },
            },
          })}
          src={image.fields.media?.fields?.file.url + '?fm=webp'}
          alt={image.fields.altText}
          fit='cover'
          withPlaceholder
        />
      </TransformComponent>
    </Carousel.Slide>
  );
};

const ZoomedProductImage = ({
  className,
  isDesktop,
  desktopImageWidth,
  height,
  image,
}: {
  className: any;
  isDesktop: any;
  desktopImageWidth: any;
  height: any;
  image: any;
}) => {
  return (
    <TransformWrapper
      panning={{ disabled: !isDesktop }}
      wheel={{ disabled: true }}
      doubleClick={{ mode: 'zoomOut', step: 2 }}
      initialScale={1}
      initialPositionX={0}
      initialPositionY={0}>
      <Slide
        isDesktop={isDesktop}
        className={className}
        height={height}
        desktopImageWidth={desktopImageWidth}
        image={image}
      />
    </TransformWrapper>
  );
};

export default ZoomedProductImage;
