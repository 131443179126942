import React from 'react';
import { Box, Container, Title } from '@mantine/core';
import usePageContext from '@/utils/context/page';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { useContainerStyles } from '@/utils/style/useContainer';
import { ResourceCategoryTemplateProps } from '@/interface.custom';
import BlogRow from '@/components/pages/Blog/BlogRow';
import BlogMustRead from '@/components/pages/Blog/BlogMustRead';
import HeroBanner from '@/components/Hero/Banner/HeroBanner';
import { IBlogArticle, ILabel } from '@/@types/generated/contentful';
import NoozText from '@/components/Nooz/NoozText/NoozText';

const BlogMain: React.FC = () => {
  const { resourceCategory, breadcrumbs, highlightedResourceArticles } =
    usePageContext() as unknown as ResourceCategoryTemplateProps;
  const containerStyles = useContainerStyles();

  const label = (highlightedResourceArticles as IBlogArticle).fields.label;
  const subLabel = (highlightedResourceArticles as IBlogArticle).fields
    .subLabel;

  return (
    <Box sx={(theme) => ({ background: theme.colors.dark[9], color: 'white' })}>
      <Box
        pt={40}
        pb={100}>
        <Container className={containerStyles.classes.container}>
          <Breadcrumbs
            color='white'
            list={breadcrumbs}
          />
          <Title
            order={1}
            my={20}
            size={70}>
            {resourceCategory.fields.label?.fields.text}
          </Title>
          <NoozText
            weight={500}
            size={22}>
            {resourceCategory.fields.subLabel?.fields.text}
          </NoozText>
        </Container>
      </Box>
      <Box>
        <HeroBanner
          image={(highlightedResourceArticles as IBlogArticle).fields.image}
          labels={{
            fields: {
              items: [
                //@ts-ignore
                {
                  ...(label as ILabel),
                  fields: {
                    ...label?.fields,
                    size: 38,
                    family: 'Mont',
                    weight: 600,
                  },
                } as ILabel,
              ],
            },
          }}
          subLabel={subLabel}
          slug={
            (highlightedResourceArticles as IBlogArticle).fields.slug?.fields
              .url
          }
          buttons={
            (highlightedResourceArticles as IBlogArticle).fields.button
              ? ({
                  sys: { id: '1337' },
                  fields: {
                    items: [
                      (highlightedResourceArticles as IBlogArticle).fields
                        .button,
                    ],
                  },
                } as any)
              : undefined
          }
          badge={{
            label: (highlightedResourceArticles as IBlogArticle).fields.category
              ?.fields.label?.fields.text,
            iconKey: (highlightedResourceArticles as IBlogArticle).fields
              .category?.fields.icon?.fields.iconKey,
          }}
        />
      </Box>

      <BlogMustRead />
      <Container className={containerStyles.classes.container}>
        {resourceCategory.fields.subCategory?.map((sub) => (
          <BlogRow
            key={(sub as unknown as { id: string }).id}
            qtyLimit={10}
            {...sub}
          />
        ))}
      </Container>
    </Box>
  );
};

export default BlogMain;
