import React from 'react';
import usePageContext from '@/utils/context/page';
import { Container, Group, Title } from '@mantine/core';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { useContainerStyles } from '@/utils/style/useContainer';
import { ResourceCategoryTemplateProps } from '@/interface.custom';

const ResourceCategory = () => {
  const { resourceCategory, breadcrumbs } =
    usePageContext() as unknown as ResourceCategoryTemplateProps;

  const containerStyles = useContainerStyles();

  return (
    <Container className={containerStyles.classes.container}>
      <Group position='apart'>
        <Title my={30}>{resourceCategory.fields.label?.fields.text}</Title>
      </Group>
      <Breadcrumbs list={breadcrumbs} />
    </Container>
  );
};

export default ResourceCategory;
