import EyeTestLanding from '@/components/pages/EyeTest/EyeTestLanding';
import EyeTestForm from '@/components/pages/EyeTest/EyeTestForm';
import EyeTestResult from '@/components/pages/EyeTest/EyeTestResult';
import { useEffect, useMemo, useState } from 'react';
import scrollToTopSmooth from '@/helpers/scrollToTopSmooth';
import DataLayerHelper from '@/helpers/Datalayer';

// eslint-disable-next-line no-unused-vars
enum Test_Step {
  // eslint-disable-next-line no-unused-vars
  Landing,
  // eslint-disable-next-line no-unused-vars
  Form,
  // eslint-disable-next-line no-unused-vars
  Result,
}

const COMPONENT_STEP: Record<Test_Step, any> = {
  [Test_Step.Landing]: EyeTestLanding,
  [Test_Step.Form]: EyeTestForm,
  [Test_Step.Result]: EyeTestResult,
};

const EyeTestWizardHandler = ({ source }: { source: any }) => {
  const [step, setStep] = useState(Test_Step.Landing);
  const [pageProps, setPageProps] = useState<Record<string, any> | null>();

  useEffect(() => {
    DataLayerHelper.pushDataLayerEvent('eyetest_step', {
      //@ts-ignore
      value: step,
    });
  }, [step]);

  const goPrev = (props?: any) => {
    setStep((prev) => (prev > 0 ? prev - 1 : prev));
    setPageProps(props);
    scrollToTopSmooth();
  };

  const goNext = (props?: any) => {
    setStep((prev) => (prev < Test_Step.Result ? prev + 1 : prev));
    setPageProps(props);
    scrollToTopSmooth();
  };

  const Component = useMemo(() => COMPONENT_STEP[step], [step]);

  return (
    <Component
      goPrev={goPrev}
      goNext={goNext}
      source={source}
      {...pageProps}
    />
  );
};
export default EyeTestWizardHandler;
