import {
  Accordion,
  ActionIcon,
  clsx,
  createStyles,
  Divider,
} from '@mantine/core';
import ProductColorPicker from '@/components/Product/ProductColorPicker/ProductColorPicker';
import ProductDiopterPicker from '@/components/Product/ProductDiopterPicker.tsx/ProductDiopterPicker';
import React, { useMemo } from 'react';
import { ChevronRight } from 'tabler-icons-react';
import usePageContext from '@/utils/context/page';
import Link from '@/components/Link/Link';
import Currency from '@/helpers/Currency';
import { ILabel } from '@/@types/generated/contentful';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import ProductSizePicker from '@/components/Product/ProductSizePicker.tsx/ProductSizePicker';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import ProductDenominationPicker from '@/components/Product/ProductDenominationPicker/ProductDenominationPicker';
import useStaticContent from '@/utils/hook/useStaticContent';
import {
  marginDesktop,
  marginMobile,
  useProductInfoStyles,
} from './ProductInformation';
import { PRODUCT_LAYOUT_MEDIA_QUERY } from '@/templates/Product';
import Image from 'next/image';
import ProductLensesPicker from '@/components/Product/ProductInformation/ProductLensesPicker';

const useStyles = createStyles((theme) => ({
  OptWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',

    '& > *:not(:last-of-type)': {
      marginRight: 20,
    },
    '& > .img-wrapper': {
      display: 'flex',
      alignItems: 'center',
    },
    '& > .text-wrapper': {
      display: 'flex',
      flexDirection: 'row',
      '& > .description-wrapper': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.fn.smallerThan(theme.breakpoints.xs)]: {
          justifyContent: 'unset',
          '& .sub-label': { display: 'none' },
        },
      },
      '& > .action-wrapper': {
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        '& > .option-price': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        },
      },
    },
  },
  colorsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: 16,
    '& > *': {
      marginTop: 5,
      marginBottom: 5,
    },
    [theme.fn.smallerThan(theme.breakpoints.xs)]: {
      marginTop: 'unset',
    },
  },
  lenses: {
    borderRadius: 12,
    overflow: 'hidden',
  },
  divider: {
    paddingLeft: marginDesktop,
    paddingRight: marginDesktop,
    width: '-webkit-fill-available',
    [theme.fn.smallerThan(theme.breakpoints[PRODUCT_LAYOUT_MEDIA_QUERY])]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginRight: 0,
    },
  },
}));

const useAccordionStyles = createStyles((theme) => ({
  item: {
    borderBottom: 'none',
  },
  control: {
    paddingLeft: marginDesktop,
    paddingRight: marginDesktop,
    width: '-webkit-fill-available',
    [theme.fn.smallerThan(theme.breakpoints[PRODUCT_LAYOUT_MEDIA_QUERY])]: {
      paddingLeft: marginMobile,
      paddingRight: marginMobile,
    },
  },
  content: {
    paddingLeft: marginDesktop,
    paddingRight: marginDesktop,
    width: '-webkit-fill-available',
    [theme.fn.smallerThan(theme.breakpoints[PRODUCT_LAYOUT_MEDIA_QUERY])]: {
      paddingLeft: marginMobile,
      paddingRight: marginMobile,
    },
  },
}));

const LENSES_CAT_KEY: Record<string, string> = {
  '7qOeTXE5sQwylAIjXgtxO2': 'readingLensesShortSub',
  '6CMUOm1GonB8AAB1BlEG11': 'readingBlueLightLensesShortSub',
  '3uUt6t58oEHpplvfn9s5KW': 'readingSunLensesShortSub',
};

const PacksOpt = () => {
  const { product, page } = usePageContext();
  const staticContent = useStaticContent();
  const packs = useMemo(
    () => product?.fields.collection?.fields?.packs,
    [product?.fields.collection?.fields?.packs],
  );

  const styles = useStyles();
  return !packs || packs?.length <= 0 ? null : (
    <>
      <Accordion.Item value='packs'>
        <Accordion.Control>
          <NoozText
            color={'title'}
            size={16}
            fw={900}>
            {staticContent.packOptions}
          </NoozText>
          <NoozText
            color={'text'}
            size={14}
            fw={400}>
            {staticContent.packOptionsSub}
          </NoozText>
        </Accordion.Control>
        <Accordion.Panel>
          {packs.map((p: any, pos: number) => {
            const opts = Object.entries(p.fields)
              .filter(([key]) => /^option[0-9]$/.test(key))
              .map(([, v]) => v);
            const optQty = (
              opts as { subLabel: ILabel & { id: string } }[]
            ).reduce(
              (
                prev: {
                  subLabel: ILabel & { id: string };
                  quantity: number;
                }[],
                currentValue: { subLabel: ILabel & { id: string } },
              ) => {
                const pos = prev.findIndex(
                  (e) => e.subLabel?.id === currentValue.subLabel?.id,
                );
                if (pos >= 0) {
                  prev[pos].quantity += 1;
                } else {
                  prev.push({ quantity: 1, subLabel: currentValue.subLabel });
                }
                return prev;
              },
              [],
            );

            return (
              <Link
                underline={false}
                onClick={() => {}}
                href={(p.fields.slug?.fields.url || pos) as string}
                key={p.fields.shopifyKey}>
                {pos > 0 ? <Divider my={30} /> : null}

                <div className={styles.classes.OptWrapper}>
                  <div className={'img-wrapper'}>
                    <Image
                      style={{
                        background: (p.fields.gradientColor ||
                          `#F0F0F3`) as string,
                      }}
                      width={100}
                      height={100}
                      className={clsx(styles.classes.lenses)}
                      src={
                        p.fields.image?.fields.media?.fields.file.url +
                        '?fm=webp'
                      }
                      alt={p.fields.image?.fields.altText || ''}
                    />
                  </div>
                  <div className={'description-wrapper'}>
                    <div>
                      <NoozText
                        color={'title'}
                        size={18}
                        fw={900}>
                        {p.fields.label?.fields.text}
                      </NoozText>
                      {optQty.map(
                        (
                          opt: {
                            subLabel: ILabel & { id: string };
                            quantity: number;
                          },
                          key: number,
                        ) => (
                          <NoozText
                            key={`${opt?.subLabel?.id}_${key}`}
                            color={'text'}
                            size={14}
                            fw={500}>
                            {`${opt.quantity}x ${opt.subLabel?.fields.text}`}
                          </NoozText>
                        ),
                      )}
                    </div>
                    <NoozText
                      mt={8}
                      color={'title'}
                      size={16}
                      fw={700}>
                      {`${
                        (p as Record<string, any>).shopify?.priceRange.amount
                      }${Currency.toSymbol(
                        (p as Record<string, any>).shopify?.priceRange
                          .currencyCode,
                      )} `}
                      <NoozText
                        span
                        color={'text'}
                        strikethrough
                        size={16}
                        fw={400}>
                        {`${
                          (p as Record<string, any>).shopify
                            ?.compareAtPriceRange.amount
                        }${Currency.toSymbol(
                          (p as Record<string, any>).shopify
                            ?.compareAtPriceRange.currencyCode,
                        )}`}
                      </NoozText>
                    </NoozText>
                  </div>
                  <ActionIcon
                    variant='outline'
                    radius='xl'
                    sx={{ position: 'absolute', right: 0, top: '40%' }}>
                    <ChevronRight size='1rem' />
                  </ActionIcon>
                </div>
              </Link>
            );
          })}
        </Accordion.Panel>
      </Accordion.Item>
      <Divider className={styles.classes.divider} />
    </>
  );
};
const ProductInformationOptions = ({ classes }: Record<string, any>) => {
  const acc = useAccordionStyles();
  const { product } = usePageContext();

  const { lenses } = product as unknown as { lenses: LensesInformation[] };
  const productInfoStyles = useProductInfoStyles();

  return (
    <>
      <ProductColorPicker styles={classes} />
      <ProductLensesPicker styles={classes} />
      <ProductDenominationPicker styles={classes} />
      <ProductDiopterPicker styles={classes} />
      <ProductSizePicker styles={classes} />
      {/*<MediaQuery*/}
      {/*  largerThan={PRODUCT_LAYOUT_MEDIA_QUERY}*/}
      {/*  styles={{ display: 'none' }}>*/}
      {/*  <Box*/}
      {/*    mt={30}*/}
      {/*    className={productInfoStyles.classes.infoContentWrapper}>*/}
      {/*    <AddToCartButton />*/}
      {/*  </Box>*/}
      {/*</MediaQuery>*/}
      <Divider
        className={classes.divider}
        mb={0}
      />
      <Accordion
        classNames={acc.classes}
        chevron={
          <NoozIcon
            iconKey='Plus'
            size={'xl'}
          />
        }
        styles={{
          chevron: {
            '&[data-rotate]': {
              transform: 'rotate(45deg)',
            },
          },
        }}>
        {process.env.NEXT_PUBLIC_DISABLE_PACK !== 'true' ? <PacksOpt /> : null}
      </Accordion>
    </>
  );
};

export default ProductInformationOptions;
