import { Badge, Flex, Grid, useMantineTheme } from '@mantine/core';
import ProductCard from '@/components/Collection/ProductCard/ProductCard';
import { formatCfItems } from '@/helpers/formatCfItems';
import HighlightCard from '@/components/Cards/HighlightCard';
import React, { useMemo } from 'react';
import { EmptyCategoryList } from '@/components/ProductCategory/CategoryFilterList/CategoryFilterList';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import i18n from '@/helpers/i18n';
import useStaticContent from '@/utils/hook/useStaticContent';
import { ShopItem } from '@/@types/shopitem';
import useTopShopItems from '@/utils/hook/useTopShopItems';

const SearchHits = ({ hits, query }: { hits: ShopItem[]; query?: string }) => {
  const theme = useMantineTheme();
  const staticContent = useStaticContent();
  const defaultReco = useTopShopItems();
  const elements = useMemo(
    () => (query ? hits : defaultReco),
    [hits, query, defaultReco],
  );

  return (
    <>
      <Flex
        gap={8}
        align={'center'}
        mt={-16}
        mb={24}
        id={'search-hits'}>
        <NoozText
          size={24}
          weight='bold'>
          {i18n(staticContent, 'suggestedProductsLabel')}
        </NoozText>
        {hits.length ? (
          <Badge
            size={'lg'}
            variant='filled'
            w='fit-content'>
            {hits.length}
          </Badge>
        ) : null}
      </Flex>
      {elements.length > 0 ? (
        <Grid
          gutter={5}
          gutterSm={20}
          gutterMd={30}
          gutterLg={40}
          id={`grid-${query}`}
          key={query}>
          {elements?.map((hit: ShopItem | any, key: number) => {
            if (hit?.defaultVariantId) {
              return (
                <Grid.Col
                  key={key}
                  span={6}
                  md={4}>
                  <ProductCard
                    key={hit.defaultVariantId}
                    item={hit}
                    position={key}
                  />
                </Grid.Col>
              );
            } else if (hit?.fields?.title) {
              let item = hit?.fields;

              if (hit?.fields?.items) {
                item = formatCfItems(hit);
              }

              const color = theme.colors.main[0];

              return (
                <Grid.Col
                  key={key}
                  span={12}
                  md={4}
                  sx={(theme) => ({
                    [theme.fn.smallerThan('md')]: {
                      margin: '16px 0px',
                    },
                  })}
                  h={{ base: 400, sm: 'auto', md: 'auto', lg: 'auto' }}>
                  <HighlightCard
                    {...item}
                    color={color}
                  />
                </Grid.Col>
              );
            }
          })}
        </Grid>
      ) : (
        <EmptyCategoryList />
      )}
    </>
  );
};

export default SearchHits;
