import React, { useEffect } from 'react';
import usePageContext from '@/utils/context/page';
import {
  Accordion,
  ActionIcon,
  Box,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  useMantineTheme,
} from '@mantine/core';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { Plus } from 'tabler-icons-react';
import { HeroTextBanner } from '@/components/Hero/Banner/HeroTextBanner';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { ResourceCategoryTemplateProps } from '@/interface.custom';
import { useRouter } from 'next/router';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import TextLink from '@/components/Link/TextLink';
import { useContainerStyles } from '@/utils/style/useContainer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useStaticContent from '@/utils/hook/useStaticContent';
import i18n from '@/helpers/i18n';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';

const FaqCategory = () => {
  const staticContent = useStaticContent();
  const theme = useMantineTheme();
  const containerStyles = useContainerStyles();

  const { resourceCategory, breadcrumbs, resourceCategoryTopics } =
    usePageContext() as unknown as ResourceCategoryTemplateProps;

  const { query } = useRouter();
  const [activeItem, setActiveItem] = React.useState<string | null>(
    query.article as string,
  );

  useEffect(() => {
    setActiveItem(query.article as string);
  }, [query.article]);

  return (
    <Box
      sx={(theme) => ({
        background: theme.colors.gray[1],
        display: 'inline-block',
      })}
      w='100%'>
      <HeroTextBanner
        title={resourceCategory.fields.label?.fields.text}
        subTitle={resourceCategory.fields.subLabel?.fields.text}>
        <>
          <Flex
            direction={'column'}
            align={'center'}
            mt={30}
            mb={'-2.25rem'}>
            <a
              href={staticContent.zendeskNewTicketUrl}
              target='_blank'
              rel='noreferrer'>
              <NoozButton
                color={'dark'}
                size='md'>
                {staticContent.zendeskNewTicketLabel}
              </NoozButton>
            </a>
          </Flex>
        </>
      </HeroTextBanner>
      <Container
        mt={30}
        mb={50}
        size='lg'
        className={containerStyles.classes.container}>
        <Breadcrumbs list={breadcrumbs} />
        <Divider my={20} />
        <Grid gutter={30}>
          <Grid.Col
            span={12}
            sm={8}>
            <Accordion
              chevron={<Plus size='1rem' />}
              value={activeItem as string}
              styles={{
                chevron: {
                  '&[data-rotate]': {
                    transform: 'rotate(45deg)',
                  },
                },
              }}>
              {resourceCategoryTopics?.map((topic, key) => {
                // @ts-ignore
                return (
                  <Accordion.Item
                    onClick={() =>
                      setActiveItem((active) =>
                        active === (topic as unknown as { id: string }).id
                          ? null
                          : (topic as unknown as { id: string }).id,
                      )
                    }
                    id={(topic as unknown as { id: string }).id}
                    value={(topic as unknown as { id: string }).id}
                    key={key}>
                    <Accordion.Control
                      chevron={
                        <NoozIcon
                          iconKey='Plus'
                          size={30}
                          strokeWidth={1.5}
                        />
                      }>
                      <NoozText
                        size={20}
                        fw={600}>
                        {topic.fields.questionLabel?.fields?.text}
                      </NoozText>
                    </Accordion.Control>
                    <Accordion.Panel>
                      {topic.fields.answerBody?.fields?.richBody
                        ? documentToReactComponents(
                            topic.fields.answerBody?.fields?.richBody,
                            {
                              renderText: (text) => {
                                return text
                                  .split('\n')
                                  .reduce(
                                    (children: any, textSegment, index) => {
                                      return [
                                        ...children,
                                        index > 0 && <br key={index} />,
                                        textSegment,
                                      ];
                                    },
                                    [],
                                  );
                              },
                            },
                          )
                        : null}
                    </Accordion.Panel>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </Grid.Col>
          <Grid.Col
            span={12}
            sm={4}>
            <NoozText
              fw={700}
              mb={10}
              size={24}>
              {i18n(staticContent, 'faqCustomerServiceLabel')}
            </NoozText>
            <NoozText
              mb={20}
              size={14}>
              {i18n(staticContent, 'faqCustomerServiceTime')}
            </NoozText>
            <Group>
              <ActionIcon
                color='primary'
                size={40}
                variant='outline'
                radius='xl'>
                <NoozIcon
                  color={theme.colors[theme.primaryColor][6]}
                  size={24}
                  strokeWidth={1.5}
                  iconKey='Mail'
                />
              </ActionIcon>

              <TextLink
                type='animated-underline'
                text={i18n(staticContent, 'faqCustomerServiceEmail')}
                url={`mailto:${i18n(staticContent, 'faqCustomerServiceEmail')}`}
              />
            </Group>
          </Grid.Col>
        </Grid>
      </Container>
    </Box>
  );
};

export default FaqCategory;
