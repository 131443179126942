import React from 'react';
import { IInputValue } from '@/@types/generated/contentful';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { formatCfItems } from '@/helpers/formatCfItems';
import usePageContext from '@/utils/context/page';
import { Container, Group } from '@mantine/core';
import { useForm } from '@mantine/form';

const Quiz = () => {
  const { components } = usePageContext();
  const { form } = formatCfItems(components?.[0]);

  const formState = useForm();

  const onSubmit = (_: Record<string, any>) => {
    // FETCH NEXT COMPONENT DATA
  };

  const input = form.fields.inputs[0];
  return (
    <form onSubmit={formState.onSubmit(onSubmit)}>
      <Container>
        <NoozText>{input.fields.label.fields.text}</NoozText>
        <Group>
          {input.fields.values.map((value: IInputValue, index: number) => (
            <React.Fragment key={(value as unknown as { id: string }).id}>
              <NoozText key={index}>
                {value.fields.label?.fields?.text}
              </NoozText>
              <NoozText>{value.fields.subLabel?.fields?.text}</NoozText>
            </React.Fragment>
          ))}
        </Group>
        <NoozButton
          type='submit'
          loaderPosition='center'>
          SUBMIT
        </NoozButton>
      </Container>
    </form>
  );
};

export default Quiz;
