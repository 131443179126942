import React, { useEffect, useMemo, useState } from 'react';
import usePageContext, { IPageContext } from '@/utils/context/page';
import { formatCfItems } from '@/helpers/formatCfItems';
import { IBlock, IImage } from '@/@types/generated/contentful';

import MobileStoreLocatorMap from '@/components/StoreLocatorMap/MobileStoreLocatorMap';
import { IMapCfComponents } from '@/interface.custom';
import DesktopStoreLocatorMap from '@/components/StoreLocatorMap/DesktopStoreLocatorMap';
import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import NoozNextApi from '@/utils/services/NoozNextApi';

// Set initial coordinates and zoom level for the map
// const longitude = 2.3488;
// const latitude = 48.8534;
// const zoom = 10;
// const initialClusteredPointLength = 20;

// Define the main component
const StoreLocatorMap = ({ stores }: { stores: any }) => {
  const [loaded, setLoaded] = useState(false);
  const [categories, setCategories] = useState({});
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(
    theme.fn.largerThan('sm').replace('@media', ''),
  );
  // Get the list of stores and components from the global context
  const { components } = usePageContext() as IPageContext;
  // Extract the relevant data from the components object
  const cfComponents: Omit<IMapCfComponents, 'stores' | 'categories'> =
    useMemo(() => {
      const { marker, list, storeDetails } = formatCfItems(
        formatCfItems(components).stores || {},
      ) as {
        marker: IImage;
        list: IBlock;
        storeDetails: IBlock;
      };

      return {
        marker,
        list: formatCfItems(list),
        storeDetails: formatCfItems(storeDetails),
        data: undefined,
      };
    }, [components]);

  useEffect(() => {
    NoozNextApi.instance.get('/api/odoo/stores').then(({ data }) => {
      setCategories(data.categories);
      setLoaded(true);
    });
  }, []);

  return loaded ? (
    <>
      {isDesktop ? (
        <DesktopStoreLocatorMap
          {...cfComponents}
          stores={stores}
          categories={categories}
        />
      ) : (
        <MobileStoreLocatorMap
          {...cfComponents}
          stores={stores}
          categories={categories}
        />
      )}
    </>
  ) : null;
};

export default StoreLocatorMap;
