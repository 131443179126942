const deleteKeys = (obj: any, keysToDelete: string[]): any => {
  if (typeof obj !== 'object' || !obj) return obj;

  if (Array.isArray(obj)) {
    return obj.map((item) => deleteKeys(item, keysToDelete));
  } else {
    const formatted: Record<string, any> = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] && !keysToDelete.includes(key)) {
        if (typeof obj[key] === 'object') {
          formatted[key] = deleteKeys(obj[key], keysToDelete);
        } else {
          formatted[key] = obj[key];
        }
      }
    });
    return formatted;
  }
};

export default deleteKeys;
