//@ts-nocheck
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Box, createStyles, useMantineTheme, Flex } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { useMediaQuery } from '@mantine/hooks';
import usePageContext from '@/utils/context/page';
import { PRODUCT_LAYOUT_MEDIA_QUERY } from '@/templates/Product';
import { IImage } from '@/@types/generated/contentful';
import useProductContext from '@/utils/context/product';
import { useCarouselIndicatorsStyles } from '@/utils/style/useCarouselIndicatorStyle';
import { CAROUSEL_PROPS } from '@/utils/style/carousel';
import NoozIcon from '../Nooz/NoozIcon/NoozIcon';
import ZoomedProductImage from '@/components/ProductDisplayer/ZoomedProductImage';

const useCarouselStyles = createStyles((theme) => ({
  root: {
    height: '100%',
  },
  body: {
    height: '100%',
    padding: '0px !important',
  },
  controls: {
    top: 0,
    height: '100%',
  },
  control: {
    cursor: 'pointer',
    height: '70%',
    width: '15%',
    border: 'none',
    background: 'none',
    boxShadow: 'none',
  },
  indicator: {
    zIndex: 2,
  },
}));

const useImageStyles = createStyles((theme, props) => ({
  root: {
    height: '100%',
  },
  figure: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    display: 'block',
    height: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },
}));

const useStyles = createStyles((theme) => ({
  picture: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    [theme.fn.smallerThan(PRODUCT_LAYOUT_MEDIA_QUERY)]: {
      top: 0,
    },
  },
}));

const ProductDisplayerImages = ({
  images,
  width,
  height,
}: {
  images: IImage[];
  width: string;
  height?: string;
}) => {
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);
  const className = useImageStyles({
    width,
    height,
  }).classes;
  const click = useRef<{ nb: number; timeout: undefined | NodeJS.Timeout }>({
    nb: 0,
    timeout: undefined,
  });

  const [isZoomed, setIsZoomed] = useState(false);

  return (
    <>
      {images?.map((image, key) => {
        const title = image?.fields?.title;
        const desktopImageWidth = title?.includes('FOCUS') ? '100%' : width;
        return image?.fields?.media ? (
          <ZoomedProductImage
            key={(image as any).id}
            {...{
              className,
              isDesktop,
              desktopImageWidth,
              height,
              image,
            }}
          />
        ) : null;
      })}
    </>
  );
};

const ProductDisplayerPicturesVideo = React.memo(() => {
  const productFields = usePageContext().product?.fields;

  const videoSrc = useMemo(() => {
    return productFields?.video?.fields?.url?.fields?.file.url || '';
  }, [productFields?.video]);

  const videoType = useMemo(() => {
    return productFields?.video?.fields?.url?.fields?.file.contentType || '';
  }, [productFields?.video]);

  if (!videoSrc || !videoType) return null;

  return (
    <Carousel.Slide
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box w='100%'>
        <video
          width='100%'
          height='100%'
          playsInline
          autoPlay
          loop
          muted
          src={videoSrc}>
          <source
            src={videoSrc}
            type={videoType}
          />
        </video>
      </Box>
    </Carousel.Slide>
  );
});

const ProductDisplayerPictures = React.memo(({ slideState, emblaState }) => {
  const modalState = useState({ opened: false });
  const { variant } = useProductContext();
  const { product } = usePageContext();
  const [embla, setEmbla] = emblaState;
  const [, setActiveIndex] = slideState;
  const theme = useMantineTheme();
  const classes = useStyles().classes;
  const isDesktop = useMediaQuery(
    `(min-width:${theme.breakpoints[PRODUCT_LAYOUT_MEDIA_QUERY]})`,
  );

  const handleSlideChange = useCallback((index: number) => {
    setActiveIndex(index);
  }, []);

  useEffect(() => {
    if (embla) {
      embla.on('select', () => {
        setActiveIndex(embla.selectedScrollSnap());
      });
    }
  }, [embla]);

  const hasControls = isDesktop ? true : false;

  const lifestyleImages = useMemo(() => {
    if (variant?.fields?.lifestyleImages?.length > 0) {
      return variant.fields.lifestyleImages;
    } else {
      return product?.fields?.lifestyleImages;
    }
  }, [product?.fields?.lifestyleImages, variant?.fields?.lifestyleImages]);

  return (
    <div className={classes.picture}>
      <Carousel
        key={variant?.fields?.title}
        {...CAROUSEL_PROPS}
        loop={true}
        slideSize='100%'
        classNames={{
          ...useCarouselStyles().classes,
          ...useCarouselIndicatorsStyles({}).classes,
        }}
        onSlideChange={handleSlideChange}
        getEmblaApi={setEmbla}
        controlSize={100}
        withIndicators={false}
        withControls={hasControls}
        nextControlIcon={
          <NoozIcon
            size={36}
            strokeWidth={1}
            iconKey='ChevronRight'
          />
        }
        previousControlIcon={
          <NoozIcon
            size={36}
            strokeWidth={1}
            iconKey='ChevronLeft'
          />
        }
        height={'100%'}>
        {product?.fields.variants?.length > 0 && (
          <>
            <ProductDisplayerImages
              modal={modalState}
              height='80%'
              width='80%'
              images={variant?.fields?.images as IImage[]}
            />
          </>
        )}
        <ProductDisplayerImages
          modal={modalState}
          height='80%'
          width='80%'
          images={product?.fields?.images as IImage[]}
        />
        <ProductDisplayerImages
          modal={modalState}
          width={isDesktop ? '70%' : '100%'}
          images={lifestyleImages as IImage[]}
        />
        <ProductDisplayerPicturesVideo />
      </Carousel>
    </div>
  );
});

export default ProductDisplayerPictures;
