import { IBlogArticle, ILabel } from '@/@types/generated/contentful';
import React from 'react';
import usePageContext from '@/utils/context/page';
import { Box, Container, Divider } from '@mantine/core';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { useContainerStyles } from '@/utils/style/useContainer';
import { ResourceCategoryTemplateProps } from '@/interface.custom';
import BlogGrid from '@/components/pages/Blog/BlogGrid';
import HeroBanner from '@/components/Hero/Banner/HeroBanner';

const BlogCategory = () => {
  const { resourceCategory, resourceCategoryArticles, breadcrumbs } =
    usePageContext() as unknown as ResourceCategoryTemplateProps;
  const containerStyles = useContainerStyles();

  return (
    <Box sx={(theme) => ({ background: theme.colors.dark[9], color: 'white' })}>
      <HeroBanner
        labels={{
          fields: {
            //@ts-ignore
            items: [resourceCategory.fields.label as ILabel],
          },
        }}
        image={resourceCategory.fields.bannerImageMobile}
        desktopImage={resourceCategory.fields.bannerImage}
        subLabel={resourceCategory.fields.subLabel}
      />
      <Container
        className={containerStyles.classes.container}
        mt={30}
        mb={50}>
        <Breadcrumbs
          color='white'
          list={breadcrumbs}
        />
        <Divider my={20} />
      </Container>
      <Container
        className={containerStyles.classes.container}
        mt={30}
        pb={80}>
        <BlogGrid
          articles={resourceCategoryArticles as unknown as IBlogArticle[]}
          label={resourceCategory.fields.label}
          subLabel={resourceCategory.fields.subLabel}
        />
      </Container>
    </Box>
  );
};

export default BlogCategory;
