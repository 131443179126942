import usePageContext from '@/utils/context/page';
import React, { useMemo } from 'react';
import {
  Box,
  Container,
  createStyles,
  Sx,
  useMantineTheme,
} from '@mantine/core';
import useProductContext from '@/utils/context/product';
import ProductCard from '@/components/Collection/ProductCard/ProductCard';
import { usePackContext } from '@/utils/context/PackContext';
import useStaticContent from '@/utils/hook/useStaticContent';
import { IProductCard } from '@/interface.custom';
import { NoozSectionTitle } from '@/components/Nooz/NoozTitle/NoozTitle';
import { Carousel } from '@mantine/carousel';
import { CAROUSEL_PROPS } from '@/utils/style/carousel';
import { useContainerStyles } from '@/utils/style/useContainer';
import { useMediaQuery } from '@mantine/hooks';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';

const useCarouselListIndicatorStyles = createStyles((theme) => ({
  controls: {
    height: '100%',
    display: 'flex',
    top: '0px !important',
    alignItems: 'center',
    padding: '0px 80px',
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },
  control: {
    padding: 6,
    borderRadius: '100%',
    background: theme.colors.gray[9],
  },
}));

export const UniqueProductReco = ({
  className,
  sx,
}: {
  className?: string | undefined;
  sx?: Sx | (Sx | undefined)[] | undefined;
}) => {
  const { variant } = useProductContext();

  return (
    <ProductReco
      className={className}
      sx={sx}
      id={(variant as unknown as { id: string }).id}
    />
  );
};

export const PackProductReco = ({
  className,
  sx,
}: {
  className?: string | undefined;
  sx?: Sx | (Sx | undefined)[] | undefined;
}) => {
  const { pack } = usePackContext();

  return (
    <ProductReco
      className={className}
      sx={sx}
      id={(pack.data as unknown as { id: string }).id}
    />
  );
};

const ProductReco = ({
  id,
  className,
  sx,
}: {
  id: string;
  className?: string | undefined;
  sx?: Sx | (Sx | undefined)[] | undefined;
}) => {
  const { locale, recommendations } = usePageContext() as any;
  const containerStyles = useContainerStyles();

  const theme = useMantineTheme();
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);

  const objectId = useMemo(
    () =>
      `${id}_${(locale as String).split('-').reduce((prev, v, key) => {
        if (key === 0) return v.toLowerCase();
        return prev + `-${v}`;
      }, '')}`,
    [id, locale],
  );
  const staticContent = useStaticContent();

  const classnames = {
    ...useCarouselListIndicatorStyles().classes,
  };

  return recommendations && recommendations.length > 0 ? (
    <Box
      mb={80}
      component='section'
      className={className}
      sx={sx}>
      <Container className={containerStyles.classes.container}>
        <NoozSectionTitle>{staticContent.ourReco}</NoozSectionTitle>
      </Container>
      <Carousel
        {...CAROUSEL_PROPS}
        classNames={classnames}
        // loop={true}
        // dragFree={true}
        slideSize={isDesktop ? '25%' : isTablet ? '33%' : '80%'}
        align={(recommendations || []).length >= 4 ? 'start' : 'center'}
        withControls={true}
        withIndicators={false}
        nextControlIcon={
          <NoozIcon
            size={28}
            color='white'
            iconKey='ChevronRight'
          />
        }
        previousControlIcon={
          <NoozIcon
            size={28}
            color='white'
            iconKey='ChevronLeft'
          />
        }
        height={'100%'}>
        {recommendations?.map((hit: IProductCard, index: number) => (
          <Carousel.Slide key={index}>
            <Box
              key={index}
              className={'reco'}>
              <ProductCard
                position={index}
                item={{ ...hit, hasColorPicker: true }}
              />
            </Box>
          </Carousel.Slide>
        ))}
      </Carousel>
    </Box>
  ) : null;
};

export default ProductReco;
