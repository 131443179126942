import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useRef } from 'react';
import { publish } from '@/helpers/events';
import { useMantineTheme } from '@mantine/core';

const useStickyHeader = (selector: string) => {
  const theme = useMantineTheme();

  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);

  const outOfWindow = useRef(false);

  useEffect(() => {
    let observer: IntersectionObserver | undefined;
    const element = document.querySelector(selector);

    publish('header', {
      outOfWindow: outOfWindow.current,
    });

    if ('IntersectionObserver' in window) {
      observer = new IntersectionObserver(([entry]) => {
        if (!entry.isIntersecting !== outOfWindow.current) {
          outOfWindow.current = !entry.isIntersecting;
          publish('header', {
            outOfWindow: outOfWindow.current,
          });
        }
      });

      if (element) {
        observer.observe(element);
      }
    }
    return () => {
      publish('header');
      if (observer && element) observer.unobserve(element);
    };
  }, [isDesktop]);
};

export default useStickyHeader;
