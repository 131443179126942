import React, { useEffect, useState, createContext, useContext } from 'react';
import NoozNextApi, { NOOZ_NEXT_REGISTRY } from '../services/NoozNextApi';
import usePageContext from './page';

// Define the shape of the ShopifyProductContext
export type IShopifyProductContext = Record<string, ShopifyProductInfo>;

// Create the ShopifyProductContext
export const ShopifyProductContext = createContext<IShopifyProductContext>({});

// Custom hook to access the ShopifyProductContext
const useShopifyProductContext = (): IShopifyProductContext => {
  return useContext(ShopifyProductContext);
};

// Provider component for the ShopifyProductContext
export const ShopifyProductProvider = ({
  product,
  children,
  shouldFetch = true,
}: {
  product: Record<string, ShopifyProductInfo>;
  children: React.ReactNode;
  shouldFetch?: boolean;
}) => {
  const [shopifyStockChecked, setShopifyStockChecked] = useState(false);
  const [shopifyProductState, setShopifyProductState] = useState<any>(null);
  const page = usePageContext();

  // Re-Fetch Shopify data when the component mounts
  useEffect(() => {
    if (shouldFetch) {
      const fetchShopifyData = async () => {
        if (page.product?.fields.shopifyKey) {
          // Prepare the configuration for the API call
          const fetchShopifyProductConfig = {
            ...NOOZ_NEXT_REGISTRY.SHOPIFY.PRODUCTS.GET,
            data: { id: page.product?.fields.shopifyKey, locale: page.locale },
          };

          const GIFT_CARD_PRODUCT_ID = btoa(
            'gid://shopify/Product/5050198818955',
          );

          // Make the API call to fetch Shopify data
          NoozNextApi.instance(fetchShopifyProductConfig)
            .then(({ data }) => {
              if (data) {
                // Process the response and update the state
                data.variants = data.variants.edges.map(
                  ({ node }: Record<string, any>) => ({
                    ...node,
                    ...node.contextualPricing,
                    availableForSale:
                      page.product?.fields.shopifyKey ===
                        GIFT_CARD_PRODUCT_ID ||
                      node.inventoryPolicy === 'CONTINUE'
                        ? true
                        : node.inventoryItem?.inventoryLevel?.available > 0,
                    stock:
                      page.product?.fields.shopifyKey ===
                        GIFT_CARD_PRODUCT_ID ||
                      node.inventoryPolicy === 'CONTINUE'
                        ? 100
                        : node.inventoryItem?.inventoryLevel?.available,
                  }),
                );
                setShopifyProductState(data);
              }
            })
            .catch(console.error)
            .finally(() => setShopifyStockChecked(true));
        }
      };

      // Call the fetchShopifyData function
      fetchShopifyData().catch(console.error);

      return () => {
        // Reset the state when the component unmounts
        setShopifyProductState(null);
      };
    }
  }, [page.product?.fields.shopifyKey]);

  // Render the provider with the value of the Shopify product state or the initial product
  return (
    <ShopifyProductContext.Provider
      value={{
        ...(shopifyProductState || product),
        // @ts-ignore
        stockChecked: shouldFetch ? shopifyStockChecked : true,
      }}>
      {children}
    </ShopifyProductContext.Provider>
  );
};

export default useShopifyProductContext;
