//@ts-nocheck
import React, { memo, useMemo } from 'react';
import ProductCategory from './ProductCategory';
import Other from './Other';
import Product from './Product';
import ProductPack from './ProductPack';
import Cart from './Cart';
import FormPage from './Form';
import ResourceCategory from './ResourceCategory';
import BlogCategory from './Blog/BlogCategory';
import Faq from './Faq/faq';
import FaqCategory from './Faq/faqCategory';
import StoreLocator from './StoreLocator';
import BlogMain from './Blog/BlogMain';
import Legal from './Legal';
import Sitemap from './Sitemap';
import Search from './Search';
import EyeTest from './EyeTest';
import Quiz from './Quiz';
import Blog from './Blog/Blog';

const Template = ({ templateKey }: TemplateProps) => {
  const Component = useMemo<React.FC | null>(() => {
    switch (templateKey) {
      case 'Other':
      case 'Home':
      case 'Shopstory':
        return Other;
      case 'Blog':
        return Blog;
      case 'BlogCategory':
        return BlogCategory;
      case 'BlogMain':
        return BlogMain;
      case 'ProductCollection':
      case 'ProductFacet':
      case 'ProductCategory':
      case 'SeasonalPage':
        return ProductCategory;
      case 'ResourceCategory':
        return ResourceCategory;
      case 'Faq':
        return Faq;
      case 'FaqCategory':
        return FaqCategory;
      case 'Product':
        return Product;
      case 'ProductPack':
        return ProductPack;
      case 'Form':
        return FormPage;
      case 'Cart':
        return Cart;
      case 'Legal':
        return Legal;
      case 'StoreLocator':
        return StoreLocator;
      case 'Sitemap':
        return Sitemap;
      case 'Search':
        return Search;
      case 'Eyetest':
        return EyeTest;
      case 'Quiz':
        return Quiz;
      default:
        return null;
    }
  }, [templateKey]);

  // return null;
  return Component ? (
    <Component
      showFeatured={[
        'ProductCollection',
        'ProductCategory',
        'ProductFacet',
      ].includes(templateKey)}
    />
  ) : null;
};

export default memo(Template);
