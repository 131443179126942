import { Flex, useMantineTheme } from '@mantine/core';
import { useMemo, useRef, useState } from 'react';
import { formatCfItems } from '@/helpers/formatCfItems';
import { BasicNoozBadge } from '@/components/Nooz/NoozBadge/NoozBadge';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { IBlockFields, IIcon, ILabel } from '@/@types/generated/contentful';
import NoozText from '@/components/Nooz/NoozText/NoozText';

const EyeTestForm = ({ source, ...props }: IEyeTestStep) => {
  const theme = useMantineTheme();
  const sentencesRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [question, setQuestion] = useState(0);

  const formElements = useMemo<null | Record<string, any>>(() => {
    if (source.formStep) {
      return formatCfItems(source.formStep);
    } else return null;
  }, [source]);

  if (!formElements) {
    console.error('No contentful startStep');
    return null;
  }
  return (
    <Flex
      direction={'column'}
      align={'center'}
      p={50}
      gap={40}
      sx={(theme) => ({
        gap: 40,
        [theme.fn.smallerThan(theme.breakpoints.xs)]: {
          gap: 80,
        },
      })}>
      <Flex
        align={'center'}
        direction={'column'}
        gap={8}>
        <BasicNoozBadge color={'title'}>
          {formElements.badge.fields.label?.fields.text?.toUpperCase()}
          <Flex ml={4}>
            <NoozIcon
              iconKey={
                formElements.badge.fields.icon
                  ? (formElements.badge.fields.icon as IIcon).fields?.iconKey
                  : 'DeviceDesktopAnalytics'
              }
              size={14}
              color={theme.colors.main[0]}
            />
          </Flex>
        </BasicNoozBadge>
        <NoozText
          color={'title'}
          weight={500}
          size={40}
          lh={'48px'}>
          {formElements.label.fields.text}
        </NoozText>
      </Flex>

      {formElements.sentences.fields.items?.map(
        (s: { id: string; fields: IBlockFields }, nb: number) => {
          const { label, value } = formatCfItems(s);

          return (
            <Flex
              key={nb}
              ref={(ref) => (sentencesRefs.current[nb] = ref)}
              align={'center'}
              justify={'center'}
              sx={(theme) => ({
                backgroundColor: '#F6F8FD',
                position: 'relative',
                border: '1px solid #DCE2F2',
                borderRadius: 20,
                width: '100%',
                padding: '70px 110px',
                maxWidth: 900,
                opacity: nb <= question ? 1 : 0.33,
                [theme.fn.smallerThan(theme.breakpoints.xs)]: {
                  padding: '60px 25px',
                },
              })}>
              <NoozText
                align={'center'}
                size={parseInt(s.fields.width || '10')}
                lh={'36px'}
                weight={500}
                color={'title'}>
                {(label as ILabel).fields.text}
              </NoozText>
              <Flex
                justify={'center'}
                align={'center'}
                sx={(theme) => ({
                  position: 'absolute',
                  backgroundColor: theme.white,
                  top: 'calc(50% - 40px)',
                  left: -40,
                  width: 80,
                  height: 80,
                  border: '1px solid',
                  borderColor: '#DCE2F2',
                  borderRadius: 40,

                  [theme.fn.smallerThan(theme.breakpoints.xs)]: {
                    left: 'calc(50% - 40px)',
                    top: -40,
                  },
                })}>
                <NoozText
                  size={28}
                  lh={'34px'}
                  weight={500}>
                  {(value as ILabel).fields.text}
                </NoozText>
              </Flex>

              {nb < question ? (
                <Flex
                  justify={'center'}
                  align={'center'}
                  sx={(theme) => ({
                    position: 'absolute',
                    top: 'calc(50% - 40px)',
                    right: -40,
                    backgroundColor: theme.colors.green[4],
                    width: 80,
                    height: 80,
                    borderRadius: 40,
                  })}>
                  <NoozIcon
                    size={40}
                    iconKey={'Check'}
                    color={'white'}
                  />
                </Flex>
              ) : null}

              {nb === question ? (
                <Flex
                  gap={40}
                  sx={{
                    position: 'absolute',
                    bottom: -40,
                    left: 'calc(50% - ((40px + 80px + 80px) / 2))',
                  }}>
                  <Flex
                    justify={'center'}
                    align={'center'}
                    onClick={() => {
                      props.goNext({ diopter: (value as ILabel).fields.text });
                    }}
                    sx={(theme) => ({
                      backgroundColor: theme.colors.red[5],
                      width: 80,
                      height: 80,
                      borderRadius: 40,
                      cursor: 'pointer',
                    })}>
                    <NoozIcon
                      size={40}
                      iconKey={'X'}
                      color={'white'}
                    />
                  </Flex>
                  <Flex
                    justify={'center'}
                    align={'center'}
                    onClick={() => {
                      if (
                        question !==
                        formElements.sentences.fields.items.length - 1
                      ) {
                        setQuestion((prev) => prev + 1);
                        sentencesRefs.current[question + 1]?.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        });
                      } else {
                        props.goNext({ diopter: null });
                      }
                    }}
                    sx={(theme) => ({
                      backgroundColor: theme.colors.green[4],
                      width: 80,
                      height: 80,
                      borderRadius: 40,
                      cursor: 'pointer',
                    })}>
                    <NoozIcon
                      size={40}
                      iconKey={'Check'}
                      color={'white'}
                    />
                  </Flex>
                </Flex>
              ) : null}
            </Flex>
          );
        },
      )}
    </Flex>
  );
};

export default EyeTestForm;
