import { ICollectionUspFields } from '@/@types/generated/contentful';
import { Image, useMantineTheme } from '@mantine/core';
import React from 'react';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { createStyles } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

const useStyles = createStyles((theme) => {
  return {
    modelCard: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 280,
      '& > *:not(:first-of-type)': {
        marginTop: 16,
      },
      '& .mantine-Image-figure, & .mantine-Image-imageWrapper, & .mantine-Image-image':
        {
          maxHeight: 500,
        },
    },
    card: {
      [theme.fn.smallerThan(theme.breakpoints.md)]: {
        minWidth: 300,
      },
    },
  };
});

const FrameFocusCard = ({ label, subLabel, image }: ICollectionUspFields) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);

  return (
    <div className={classes.modelCard}>
      {image?.fields.media?.fields?.file.url ? (
        <Image
          src={image?.fields.media?.fields?.file.url + '?fm=webp'}
          width='100%'
          fit={'cover'}
          radius={isDesktop ? 'xl' : 'lg'}
          alt={image?.fields?.altText}
        />
      ) : null}
      <NoozText
        color={'title'}
        size={24}
        fw={700}>
        {label?.fields.text}
      </NoozText>
      <NoozText
        color={'text'}
        size={18}
        fw={500}>
        {subLabel?.fields.text}
      </NoozText>
    </div>
  );
};

export default FrameFocusCard;
