import { Accordion, createStyles, Divider } from '@mantine/core';
import usePageContext from '@/utils/context/page';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import React, { memo, useMemo } from 'react';
import { formatCfItems } from '@/helpers/formatCfItems';
import { IProduct, IProductPack } from '@/@types/generated/contentful';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import {
  marginDesktop,
  marginMobile,
  useProductInfoStyles,
} from './ProductInformation';
import { PRODUCT_LAYOUT_MEDIA_QUERY } from '@/templates/Product';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import useStaticContent from '@/utils/hook/useStaticContent';
import ProductInformationFitter from '@/components/Product/ProductInformation/ProductInformationFitter';
import useProductContext from '@/utils/context/product';
import { ProductColorPickerProps } from '@/interface.custom';

const useStyles = createStyles((theme) => ({
  item: {
    borderBottom: 'none',
  },
  control: {
    paddingLeft: marginDesktop,
    paddingRight: marginDesktop,
    width: '-webkit-fill-available',
    [theme.fn.smallerThan(theme.breakpoints[PRODUCT_LAYOUT_MEDIA_QUERY])]: {
      paddingLeft: marginMobile,
      paddingRight: marginMobile,
    },
  },
  content: {
    paddingLeft: marginDesktop,
    paddingRight: marginDesktop,
    width: '-webkit-fill-available',
    [theme.fn.smallerThan(theme.breakpoints[PRODUCT_LAYOUT_MEDIA_QUERY])]: {
      paddingLeft: marginMobile,
      paddingRight: marginMobile,
    },
  },
  divider: {
    marginLeft: marginDesktop,
    marginRight: marginDesktop,
    [theme.fn.smallerThan(theme.breakpoints[PRODUCT_LAYOUT_MEDIA_QUERY])]: {
      marginLeft: marginMobile,
      marginRight: marginMobile,
    },
  },
}));
const ProductInformationAccordion = () => {
  const staticContent = useStaticContent();
  const styles = useStyles();
  const { product } = usePageContext() as unknown as {
    product: IProduct | IProductPack;
  };
  const info = product.fields.informations;
  const hasTechnicalSpecs = useMemo(() => {
    return info?.find((block) => {
      const { label, key } = formatCfItems(block);
      return label?.fields.text === staticContent.technicalSpecifications;
    });
  }, [info, staticContent]);
  const { classes } = useProductInfoStyles();
  const { variant } = useProductContext() as unknown as ProductColorPickerProps;

  return (
    <Accordion
      defaultValue={(info?.[0] as unknown as { id: string })?.id}
      classNames={styles.classes}
      mb={20}>
      {info?.map((i, key) => {
        const id = (i as unknown as { id: string }).id;
        const { label, body } = formatCfItems(i);

        if (
          label?.fields.text === staticContent.technicalSpecifications &&
          // @ts-ignore
          !product.fields.frames?.length
        ) {
          return null;
        }

        return (
          <React.Fragment key={id}>
            {key !== 0 ? <Divider className={styles.classes.divider} /> : null}
            <Accordion.Item
              value={id}
              key={id}>
              <Accordion.Control
                chevron={
                  <NoozIcon
                    size={'xl'}
                    iconKey='ChevronDown'
                  />
                }>
                <NoozText
                  color={'title'}
                  size={14}
                  fw={900}>
                  {label?.fields.text}
                </NoozText>
              </Accordion.Control>
              <Accordion.Panel
                sx={{ '* > ul': { marginTop: 0, marginBottom: 20 } }}>
                {body?.fields?.richBody
                  ? documentToReactComponents(body?.fields?.richBody, {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => (
                          <NoozText
                            mb={10}
                            lh={1.6}>
                            {children}
                          </NoozText>
                        ),
                      },
                      renderText: (text: string) => {
                        return text.replaceAll(
                          '{{lenses}}',
                          (variant.fields as any)?.lense?.fields
                            .technicalSpecLabel?.fields.text || '',
                        );
                      },
                    })
                  : null}
                {label?.fields.text === staticContent.technicalSpecifications &&
                // @ts-ignore
                product.fields.frames?.length &&
                // @ts-ignore
                product.fields.frames.length > 0 &&
                i.fields.key !== 'noSizeGuide' ? (
                  <ProductInformationFitter classes={classes} />
                ) : null}
              </Accordion.Panel>
            </Accordion.Item>
          </React.Fragment>
        );
      })}
    </Accordion>
  );
};

export default memo(ProductInformationAccordion);
