import { Box } from '@mantine/core';
import React from 'react';

const Geocoder = () => (
  <Box
    id='geocoder'
    className='geocoder'
    sx={(theme) => ({
      '& .mapboxgl-ctrl-geocoder': {
        width: '100%',
        minWidth: '100%',
        boxShadow: 'none',
        border: `1px solid ${theme.colors.gray[4]}`,
        borderRadius: 25,
        height: 44,
        '& > svg': {
          top: 12,
          left: 12,
        },
        '& > input.mapboxgl-ctrl-geocoder--input': {
          height: 44,
          padding: '6px 40px',
          '&:focus': {
            outline: 'none',
          },
        },
      },
    })}
  />
);

export default Geocoder;
