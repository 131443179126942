import { IProduct } from '@/@types/generated/contentful';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import useShopifyProductContext from '../context/shopifyProduct';
import ShopifyFormatter from '@/helpers/ShopifyFormatter';
import Url from '@/helpers/Url';

/**

Custom hook to find the default product variant based on the color query parameter
in the URL and return the associated shopify information.
@param {IProduct} product - The product to find the default variant for
@returns {object} - The default variant and its shopify information
*/
function useDefaultProductVariant(product: IProduct) {
  const router = useRouter();
  const shopifyProduct = useShopifyProductContext();
  const shopifyVariants = shopifyProduct?.variants as unknown as any[];

  // Find the default variant based on the color & size query parameter in the URL
  const variant = useMemo(() => {
    const defaultVariant = product?.fields?.variants?.[0];
    const color = Url.getQueryParam(router, 'color');
    const size = Url.getQueryParam(router, 'size');
    const value = Url.getQueryParam(router, 'value');

    if (!color && !size && !value) {
      return defaultVariant;
    }

    return (
      product?.fields.variants?.find((p) => {
        let match = true;

        if (color)
          match = Boolean(
            match &&
              p.fields.color?.fields.shopifyKey?.toLowerCase() ===
                color?.toLowerCase(),
          );
        if (size)
          match = Boolean(
            match && new RegExp(`${size}$`, 'g').test(p.fields.title),
          );
        if (value) {
          const sku = shopifyVariants.find(
            (sv) => sv.price.amount === value,
          )?.sku;
          match = Boolean(match && p.fields.title === sku);
        }

        return match;
      }) || defaultVariant
    );
  }, [product, router, shopifyVariants]);

  const variantTitle = variant?.fields.title as string;

  const variantShopifyInfo = useMemo(
    () =>
      ShopifyFormatter.getVariantInfo(router, shopifyVariants, variantTitle),
    [shopifyVariants, variantTitle],
  );

  // Filter the shopify variants to only include the default variant
  const filteredShopifyVariants = useMemo(
    () =>
      shopifyVariants?.filter(
        (v) =>
          v &&
          v.selectedOptions.find((o: { name: string }) =>
            ['Color', 'Couleur'].includes(o.name),
          )?.value ===
            variantShopifyInfo?.selectedOptions.find((o: { name: string }) =>
              ['Color', 'Couleur'].includes(o.name),
            )?.value,
      ) || shopifyVariants[0],
    [shopifyVariants, variantShopifyInfo],
  );

  const stockedVariant = useMemo(() => {
    if (typeof window !== 'undefined') {
      const sp = new URLSearchParams(location.search);
      if (sp.get('diopter')) return variantShopifyInfo;
    }

    return filteredShopifyVariants.reduce((selected, pointer) => {
      if (!selected.availableForSale && pointer.availableForSale)
        return pointer;
      return selected;
    }, variantShopifyInfo);
  }, [filteredShopifyVariants, variantShopifyInfo]);
  return {
    ...variant,
    shopifyInfo: stockedVariant,
    shopifyVariants: filteredShopifyVariants,
  };
}

export default useDefaultProductVariant;
