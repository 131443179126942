import { ActionIcon, Box, Button, Flex, Progress } from '@mantine/core';
import React, { useCallback, useMemo, useState } from 'react';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import useDrawerContext, {
  drawerContextInitState,
} from '@/utils/context/drawer';
import { IVariant } from '@/utils/context/product';
import { IPackContext, IVariantName } from '@/utils/context/PackContext';
import useStaticContent from '@/utils/hook/useStaticContent';
import StepProductSelection from '@/components/PackOptionSelection/StepProductSelection';
import StepSelectVariant from '@/components/PackOptionSelection/StepSelectVariant';
import { PackProductOption } from '@/interface.custom';

const PCT_STEP = 33.33;

const PackOptionSelection = ({
  options,
  identifier,
  setPack,
  stocks,
}: {
  stocks: Record<string, number>;
  options: string[];
  identifier: `option1` | `option2` | `option3`;
  setPack: IPackContext['setPack'];
}) => {
  const staticContent = useStaticContent();
  const { setDrawer } = useDrawerContext();
  const closeModal = useCallback(() => {
    setDrawer({
      ...drawerContextInitState.drawer,
    });
  }, []);

  const handelAddToPack = () => {
    setPack((prev) => {
      const update = { ...prev };
      update.options[identifier] = {
        ...(typeof variant === 'object' ? variant : {}),
        name: selectedProduct?.label,
      } as IVariantName;
      return update;
    });
    closeModal();
  };

  const [step, setStep] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState<
    PackProductOption | undefined
  >();

  const [variant, setVariant] = useState<IVariant | string | undefined>();
  const handleSelectProduct = (p: PackProductOption) => () => {
    setSelectedProduct(p);
    setStep(2);
  };
  const isAvailable = useMemo(() => {
    if (
      (variant &&
        typeof variant === 'object' &&
        (variant as any)?.shopifyInfo?.inventoryPolicy !== 'DENY') ||
      ((variant as any)?.shopifyInfo?.inventoryPolicy === 'DENY' &&
        stocks[
          // @ts-ignore
          variant?.shopifyInfo?.id
        ] > 0)
    )
      return true;
    return false;
  }, [variant, stocks]);

  return (
    <Flex
      direction='column'
      justify='space-between'
      h='100%'>
      <Flex
        h={40}
        mb={20}
        justify='center'
        align='center'>
        {step === 2 ? (
          <ActionIcon
            mr={8}
            radius={'xl'}
            variant={'outline'}
            onClick={() => {
              setStep(1);
              setSelectedProduct(undefined);
              setVariant(undefined);
            }}>
            <NoozIcon iconKey={'ChevronLeft'} />
          </ActionIcon>
        ) : null}
        <Progress
          sx={{ flex: 1 }}
          value={PCT_STEP * step}
          color={'title'}
        />
      </Flex>

      <StepProductSelection
        step={step}
        options={options}
        handleSelectProduct={handleSelectProduct}
      />
      <StepSelectVariant
        step={step}
        stocks={stocks}
        product={selectedProduct}
        handleSelectVariant={setVariant}
      />

      {/*// @ts-ignore*/}
      {/*{typeof variant !== 'string' &&*/}
      {/*variant?.shopifyInfo?.availableForSale &&*/}
      {/*stocks[*/}
      {/*  // @ts-ignore*/}
      {/*  variant?.shopifyInfo?.id*/}
      {/*] <= 10 ? (*/}
      {/*  <NoozText*/}
      {/*    size={16}*/}
      {/*    fw={700}*/}
      {/*    m={'0 0 16px'}*/}
      {/*    align={'center'}*/}
      {/*    color={'original.0'}>*/}
      {/*    {i18n(staticContent, 'stockLessThan')}*/}
      {/*  </NoozText>*/}
      {/*) : null}*/}
      {step === 2 ? (
        <Box py={0}>
          <Button
            color={'title'}
            radius={'xl'}
            disabled={!isAvailable}
            fullWidth
            onClick={handelAddToPack}>
            {typeof variant === 'string'
              ? variant
              : staticContent[isAvailable ? 'pickGlasses' : 'soldOut']}
          </Button>
        </Box>
      ) : null}
    </Flex>
  );
};

export default PackOptionSelection;
