import React, { useMemo } from 'react';
import useProductContext, { IVariant } from '@/utils/context/product';
import { Group, Divider, clsx, Box } from '@mantine/core';
import { useRouter } from 'next/router';
import usePageContext from '@/utils/context/page';
import { IPage } from '@/@types/generated/contentful';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { useChipStyles } from '@/components/Product/ProductDiopterPicker.tsx/ProductDiopterPicker';
import {
  ProductDenominationPickerProps,
  UiDenominationPickerProps,
} from '@/interface.custom';
import { useProductInfoStyles } from '../ProductInformation/ProductInformation';
import Currency from '@/helpers/Currency';

const UiPicker = ({
  active,
  variants,
  handleChange,
  styles,
}: UiDenominationPickerProps & {
  active: IVariant | null;
  variants: IVariant[];
  handleChange: (key: string) => void;
}) => {
  const { page } = usePageContext();
  const staticContent = useMemo(
    // @ts-ignore
    () => (page as IPage).fields.pageType.staticItems,
    [page],
  );

  const { classes } = useChipStyles();
  const productInfoStyles = useProductInfoStyles();

  return variants?.length > 0 ? (
    <Box className={productInfoStyles.classes.infoContentWrapper}>
      <Divider className={styles.smallDivider} />
      <div className={styles.itemWrapper}>
        <NoozText
          color={'title'}
          fw={900}
          size={14}
          mb={16}>
          {staticContent.denominationsLabel}
        </NoozText>
        <Group>
          {variants?.map((v) => (
            <div
              className={clsx(classes.chip, {
                [classes.active]: v.shopifyInfo.id === active?.shopifyInfo.id,
                [classes.disabled]: !v.shopifyInfo.availableForSale,
              })}
              onClick={() => {
                handleChange(v.id);
              }}
              key={`product_diopter_${v.id}`}>
              <NoozText size={12}>{`${Currency.toSymbol(
                v.shopifyInfo.price.currencyCode,
              )}${parseFloat(v.shopifyInfo.price.amount).toFixed(
                2,
              )}`}</NoozText>
            </div>
          ))}
        </Group>
      </div>
    </Box>
  ) : null;
};

const ProductDenominationPicker = ({ styles }: UiDenominationPickerProps) => {
  const router = useRouter();
  // @ts-ignore
  const { shopifyProduct } = usePageContext();
  const hasOption = useMemo(
    () =>
      shopifyProduct?.options.find(
        ({ name }: { name: string }) => name === 'Denominations',
      ),
    [shopifyProduct?.options],
  );
  const { variants, variant, setVariant } =
    useProductContext() as unknown as ProductDenominationPickerProps;

  const handleChange = (value: string) => {
    const newVariant = variants.find(
      (v) => (v as unknown as { id: string }).id === value,
    )!;
    if (newVariant) {
      router
        .replace(
          {
            query: {
              ...router.query,
              value: newVariant.shopifyInfo.price.amount,
            },
          },
          undefined,
          { scroll: false, shallow: true },
        )
        .then((res) => {
          console.debug('[PRODUCT DENOMINATION CHANGE] ', res);
        });
    }
  };

  return hasOption ? (
    <UiPicker
      styles={styles}
      active={variant}
      variants={variants}
      handleChange={handleChange}
    />
  ) : null;
};

export default ProductDenominationPicker;
