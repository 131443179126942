import { useForm } from '@mantine/form';
import NoozNextApi, { NOOZ_NEXT_REGISTRY } from '@/utils/services/NoozNextApi';
import customNotification from '@/utils/customNotification';
import { Box, Group, Stack, TextInput } from '@mantine/core';
import React from 'react';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import useStaticContent from '@/utils/hook/useStaticContent';
import { FormTrackOrderProps, TrackOrderForm } from '@/interface.custom';
import i18n from '@/helpers/i18n';

const FormTrackOrder = ({
  loading,
  setOrders,
  setLoading,
}: FormTrackOrderProps) => {
  const staticContent = useStaticContent();
  const form = useForm<TrackOrderForm>({
    initialValues: {
      email: '',
    },
    validate: {
      email: (val, ctx) => {
        if (!ctx.email || ctx.email === '')
          return i18n(staticContent, 'trackOrderAtLeastOneFieldError');

        if (val && val !== '') {
          return /^\S+@\S+$/.test(val)
            ? null
            : i18n(staticContent, 'invalidEmail');
        }

        return null;
      },
    },
  });

  const onSubmit = (formData: Record<string, any>) => {
    const config = { ...NOOZ_NEXT_REGISTRY.SHOPIFY.ORDERS.TRACK };

    config.url += `/${formData.email.trim()}`;
    setLoading(true);
    NoozNextApi.instance(config)
      .then((res) => {
        customNotification.success({
          message: i18n(staticContent, 'trackOrderSuccess'),
        });
        setOrders(res.data);
        form.reset();
      })
      .catch((e) => {
        console.error(e.response.status);

        if (e.response.status === 404) {
          customNotification.error({
            message: i18n(staticContent, 'trackOrderUnknownUser'),
          });
        } else {
          customNotification.error({
            message: i18n(staticContent, 'trackOrderError'),
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      component={'form'}
      onSubmit={form.onSubmit(onSubmit)}>
      <Stack>
        <TextInput
          label={staticContent.emailLabel}
          placeholder={staticContent.emailPlaceholder}
          value={form.values.email}
          onChange={(event) =>
            form.setFieldValue('email', event.currentTarget.value)
          }
          error={form.errors.email && i18n(staticContent, 'invalidEmailFormat')}
        />
        {/*<Divider*/}
        {/*  labelPosition={'center'}*/}
        {/*  label={staticContent.or}*/}
        {/*/>*/}

        {/*<PhoneInput*/}
        {/*  inputProps={{*/}
        {/*    label: staticContent.phoneLabel,*/}
        {/*    placeholder: staticContent.phonePlaceholder,*/}
        {/*    error: form.errors.phone && 'Invalid phone (use national format)',*/}
        {/*  }}*/}
        {/*  value={form.values.phone}*/}
        {/*  onChange={(value) => form.setFieldValue('phone', value)}*/}
        {/*/>*/}
      </Stack>

      <Group
        position='apart'
        mt='xl'>
        <NoozButton
          color={'title'}
          type='submit'
          disabled={!(form.values.phone || form.values.email)}
          loading={loading}
          loaderPosition={'center'}>
          {staticContent.submitLabel}
        </NoozButton>
      </Group>
    </Box>
  );
};

export default FormTrackOrder;
