import { Box, Flex, MediaQuery, createStyles } from '@mantine/core';
import { IBlogArticle, IBlogCategory } from '@/@types/generated/contentful';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import Link from '@/components/Link/Link';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import React from 'react';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import HighlightCard from '@/components/Cards/HighlightCard';
import { Carousel } from '@mantine/carousel';

const useCarouselStyles = createStyles({
  indicators: {
    bottom: -30,
  },
});

const BlogRow = ({
  qtyLimit = -1,
  // @ts-ignore
  fields: { label, subLabel, pageReference, articles, ctaSeeMore },
}: IBlogCategory & { qtyLimit?: number }) => {
  const props = articles.slice(0, qtyLimit)?.map((article: IBlogArticle) => {
    return {
      ...article.fields,
      image: article.fields.posterImage,
      textSize: {
        label: {
          weight: 600,
          size: {
            base: 20,
            xs: 18,
          },
        },
        subLabel: {
          size: {
            base: 32,
            xs: 24,
          },
        },
      },
    };
  });

  return (
    <Box
      my={120}
      sx={{ gap: 50 }}>
      <Flex
        gap={'1rem'}
        mb={40}
        align={{ sm: 'flex-end' }}
        justify={{ sm: 'space-between' }}
        direction={{ base: 'column', sm: 'row' }}>
        <Box maw={600}>
          <NoozText
            size={42}
            lh={'50px'}
            weight={700}>
            {label?.fields.text}
          </NoozText>
          <NoozText
            size={18}
            mt={10}>
            {subLabel?.fields.text}
          </NoozText>
        </Box>
        {qtyLimit > 0 ? (
          <MediaQuery
            styles={{ display: 'none' }}
            smallerThan={'md'}>
            <Link href={pageReference?.fields.slug.fields.url as string}>
              <NoozButton size='md'>
                <Flex
                  gap={4}
                  align={'center'}>
                  <NoozText
                    size={'md'}
                    weight={500}>
                    {ctaSeeMore?.fields.label?.fields.text}
                  </NoozText>
                  <NoozIcon
                    iconKey='ChevronRight'
                    size={18}
                    strokeWidth={1}
                    color={'white'}
                  />
                </Flex>
              </NoozButton>
            </Link>
          </MediaQuery>
        ) : null}
      </Flex>

      <Carousel
        align='start'
        dragFree={true}
        slideSize={300}
        classNames={{ ...useCarouselStyles().classes }}
        loop={true}
        withIndicators={true}
        withControls={false}
        height={'100%'}>
        {props.map((hit: any, index: number) => (
          <Carousel.Slide
            p={5}
            key={index}>
            <Box
              key={index}
              h={400}>
              <HighlightCard
                key={hit.objectID}
                radius='md'
                {...hit}
              />
            </Box>
          </Carousel.Slide>
        ))}
      </Carousel>

      {qtyLimit > 0 ? (
        <MediaQuery
          styles={{ display: 'none' }}
          largerThan={'md'}>
          <Link href={pageReference?.fields.slug.fields.url as string}>
            <NoozButton>
              <Flex gap={4}>
                {ctaSeeMore?.fields.label?.fields.text}
                <NoozIcon
                  iconKey={
                    ctaSeeMore?.fields.icon?.fields.iconKey || 'ChevronRight'
                  }
                  color={'white'}
                />
              </Flex>
            </NoozButton>
          </Link>
        </MediaQuery>
      ) : null}
    </Box>
  );
};

export default BlogRow;
