import React from 'react';
import usePageContext from '@/utils/context/page';
import { Container, Divider } from '@mantine/core';
import { formatCfItems } from '@/helpers/formatCfItems';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { HeroTextBanner } from '@/components/Hero/Banner/HeroTextBanner';
import { IBreadcrumb } from '@/interface.custom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Legal = () => {
  const { components, breadcrumbs } = usePageContext();
  const { body, label } = formatCfItems(components?.[0]);

  return (
    <>
      <HeroTextBanner title={label.fields.text} />
      <Container
        mt={30}
        mb={50}>
        <Breadcrumbs list={(breadcrumbs || []) as unknown as IBreadcrumb[]} />
        <Divider my={20} />
        {body.fields.richBody
          ? documentToReactComponents(body.fields.richBody, {})
          : null}
      </Container>
    </>
  );
};

export default Legal;
