import { Body } from '@/components/Body/body';
import { Box, Container, Divider } from '@mantine/core';
import React from 'react';
import usePageContext from '@/utils/context/page';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { BlogTemplateProps } from '@/interface.custom';
import HeroBanner from '@/components/Hero/Banner/HeroBanner';
import { useContainerStyles } from '@/utils/style/useContainer';
import { ILabel } from '@/@types/generated/contentful';

const Blog = () => {
  const { article, breadcrumbs } =
    usePageContext() as unknown as BlogTemplateProps;
  const { label, subLabel, body, image, posterImage } = article.fields;
  const containerStyles = useContainerStyles();

  return (
    <Box sx={(theme) => ({ background: theme.colors.dark[9], color: 'white' })}>
      <HeroBanner
        subLabel={subLabel}
        labels={{
          fields: {
            items: [
              //@ts-ignore
              {
                ...(label as ILabel),
                fields: {
                  ...label?.fields,
                  size: 42,
                  family: 'Mont',
                  weight: 600,
                },
              } as ILabel,
            ],
          },
        }}
        image={posterImage}
        desktopImage={image}
      />
      <Container
        mt={30}
        mb={50}
        w='100%'
        className={containerStyles.classes.container}>
        <Breadcrumbs
          color='white'
          list={breadcrumbs}
        />
        <Divider my={20} />
      </Container>
      <Box
        pt={30}
        pb={80}>
        <Body richBody={body} />
      </Box>
    </Box>
  );
};

export default Blog;
