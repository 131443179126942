import { Box, Flex } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { formatCfItems } from '@/helpers/formatCfItems';
import React, { memo, SetStateAction, useMemo } from 'react';

const EndStep = ({
  source,
  diopter,
}: {
  source: any;
  toggleStep: (value?: SetStateAction<string> | undefined) => void;
  diopter: number | string | null;
}) => {
  const data = useMemo<null | Record<string, any>>(() => {
    if (source.resultStep) {
      return formatCfItems(source.resultStep);
    } else return null;
  }, [source]);

  return (
    <Flex
      direction={'column'}
      gap={{ base: 16 }}
      p={'0 12px 12px'}>
      <Box>
        <NoozText
          align={'center'}
          weight={700}
          size={32}>{`${data?.label.fields.text}`}</NoozText>
        <NoozText
          align={'center'}
          weight={700}
          size={32}>{`${
          diopter || data?.noDiopterLabel.fields.text
        }`}</NoozText>
      </Box>
      <NoozText
        align={'center'}
        color={'title'}
        size={20}
        lh={'24px'}>
        {data?.subLabel.fields.text}
      </NoozText>
    </Flex>
  );
};

export default memo(EndStep);
