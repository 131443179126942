import { Container, Grid } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import React, { useEffect, useMemo, useState } from 'react';
import { formatCfItems } from '@/helpers/formatCfItems';
import usePageContext from '@/utils/context/page';
import ProductCard from '@/components/Collection/ProductCard/ProductCard';
import { useContainerStyles } from '@/utils/style/useContainer';
import NoozNextApi from '@/utils/services/NoozNextApi';

const EyeTestResult = ({ diopter, source }: IEyeTestStep) => {
  const { locale } = usePageContext();
  const containerStyles = useContainerStyles();
  const [reco, setReco] = useState<Array<any>>([]);
  const h = useHeaderHeight();
  const formElements = useMemo<null | Record<string, any>>(() => {
    if (source.resultStep) {
      return formatCfItems(source.resultStep);
    } else return null;
  }, [source]);

  useEffect(() => {
    NoozNextApi.instance
      .get(
        `/json/shopitems/eye-test-${
          diopter ? 'reading' : 'bluelight'
        }-${locale}.json`,
      )
      .then(({ data }) => {
        setReco(data.items || []);
      });
  }, [diopter]);

  if (!formElements) {
    console.error('No contentful startStep');
    return null;
  }

  return (
    <Container
      className={containerStyles.classes.container}
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        minHeight: `calc(100vh - ${h.wrapper + h.banner}px)`,
        paddingTop: 50,
        gap: '4.5rem',
        justifyContent: 'space-evenly',
        [theme.fn.smallerThan(theme.breakpoints.md)]: {
          padding: 30,
          gap: '2.5rem',
        },
      })}>
      <NoozText
        sx={(theme) => ({
          alignSelf: 'center',

          [theme.fn.smallerThan(theme.breakpoints.md)]: {
            textAlign: 'center',
          },
        })}
        weight={700}
        size={40}
        lh={'48px'}>{`${formElements.label.fields.text} ${
        diopter || formElements.noDiopterLabel.fields.text
      }`}</NoozText>
      <NoozText
        align={'center'}
        color={'title'}
        size={20}
        lh={'24px'}>
        {formElements.subLabel.fields.text}
      </NoozText>
      <Grid mb={30}>
        {reco.map((hit, key) => (
          <Grid.Col
            key={hit.objectID}
            xs={12}
            sm={4}>
            <ProductCard
              item={hit}
              position={key}
              searchParams={diopter ? { diopter } : undefined}
            />
          </Grid.Col>
        ))}
      </Grid>
    </Container>
  );
};

export default EyeTestResult;
