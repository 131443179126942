import { ILabel } from '@/@types/generated/contentful';
import React, { FC, useMemo } from 'react';
import NoozButton from '../Nooz/NoozButton/NoozButton';
import { undoFlyToStore } from './utils';
import NoozText from '../Nooz/NoozText/NoozText';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { Box, Flex, Group } from '@mantine/core';
import { BasicNoozBadge } from '@/components/Nooz/NoozBadge/NoozBadge';
import useStaticContent from '@/utils/hook/useStaticContent';
import usePageContext from '@/utils/context/page';
import LocaleHelper from '@/helpers/Locale';
import CountryCodeToName from '@/helpers/CountryCodeToName.json';
import { capitalizeFirstLetter } from '@/utils';
import CategoriesBadges from '@/components/StoreLocatorMap/CategoriesBadges';

interface Props {
  setActiveStoreDetail: (active: boolean) => void;
  currentStore: any;
  map: any;
  directionLabel: ILabel;
  allVendorsLabel: ILabel;
  isWindowSmallSize: boolean;
  dictionary: any;
}

export const LocationDetail: FC<Props> = ({
  setActiveStoreDetail,
  currentStore,
  map,
  directionLabel,
  allVendorsLabel,
  dictionary,
  ...mapDetails
}) => {
  const staticContent = useStaticContent();
  const handleDirectionsClick = () => {
    if (typeof window !== 'undefined') {
      window.open(
        `https://maps.google.com/?saddr=Current+Location&daddr=${currentStore.geometry.coordinates[1]},${currentStore.geometry.coordinates[0]}`,
        '_blank',
      );
    }
  };

  const handleAllStoresClick = () => {
    setActiveStoreDetail(false);
    undoFlyToStore(map, currentStore);
  };
  const categories = useMemo<number[]>(() => {
    try {
      let c: number[] = currentStore.properties.productCategories || [];
      return c;
    } catch (e) {
      return [];
    }
  }, [currentStore.properties.productCategories]);

  return (
    <>
      {currentStore && (
        <Flex
          direction={'column'}
          gap={12}>
          <Flex
            onClick={handleAllStoresClick}
            align={'center'}
            gap={8}
            p={'12px 0'}
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
            <NoozIcon iconKey={'ChevronLeft'} />
            {allVendorsLabel.fields.text}
          </Flex>
          <NoozText
            weight='bold'
            size='lg'>
            {currentStore.properties.name}
          </NoozText>
          <Box>
            <NoozText>
              {currentStore.properties.address1?.toUpperCase()}
            </NoozText>
            <NoozText>
              {[
                currentStore.properties.postcode,
                currentStore.properties.city?.toUpperCase(),
              ].join(' ')}
            </NoozText>
            <NoozText>
              {[
                currentStore.properties.state && currentStore.properties.state,
                CountryCodeToName[
                  currentStore.properties
                    .country as keyof typeof CountryCodeToName
                ],
              ]
                .filter((v) => !!v)
                .join(', ')}
            </NoozText>
          </Box>

          <Flex justify={'center'}>
            <NoozButton
              variant='filled'
              color={'title'}
              size='lg'
              onClick={handleDirectionsClick}>
              <Flex gap={8}>
                {directionLabel.fields.text}
                <NoozIcon
                  iconKey={'Map2'}
                  color={'white'}
                />
              </Flex>
            </NoozButton>
          </Flex>

          <Box>
            <NoozText
              color={'text'}
              weight={700}
              size={12}
              lh={'15px'}
              mt={16}
              mb={6}>
              {staticContent.theirCollections}
            </NoozText>
            <Group spacing='xs'>
              <CategoriesBadges
                categories={categories}
                dictionary={dictionary}
              />
            </Group>
          </Box>
        </Flex>
      )}
    </>
  );
};
