import { Flex, useMantineTheme } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { IBlock, IButton, IIcon, ILabel } from '@/@types/generated/contentful';
import { formatCfItems } from '@/helpers/formatCfItems';
import { BasicNoozBadge } from '@/components/Nooz/NoozBadge/NoozBadge';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import React, { memo, SetStateAction, useMemo } from 'react';

const StartStep = ({
  source,
  toggleStep,
}: {
  source: any;
  toggleStep: (value?: SetStateAction<string> | undefined) => void;
}) => {
  const data = useMemo<null | Record<string, any>>(() => {
    if (source.startStep) {
      let form = formatCfItems(source.startStep);
      Object.entries(form).forEach(([key, value]) => {
        if ((value as Record<string, any>)?.fields?.items) {
          form[key] = formatCfItems(value);
        }
      });
      return form;
    } else return null;
  }, [source]);
  const theme = useMantineTheme();

  return (
    <Flex
      direction={'column'}
      align={'center'}
      justify={'space-between'}
      gap={'2rem'}
      h={'100% '}>
      <NoozText
        color={'main'}
        align={'center'}
        size={26}
        lh={'31px'}
        weight={900}>
        {(data?.instructions?.label as ILabel).fields.text}
      </NoozText>

      <Flex
        direction={{ base: 'column', sm: 'row' }}
        gap={{ base: 12, sm: 32 }}>
        {(data?.instructions?.steps as IBlock)?.fields.items
          ? (data?.instructions?.steps as IBlock).fields.items?.map(
              (step, idx) => {
                const { icon, label, subLabel } = formatCfItems(step);

                return (
                  <Flex
                    direction={'column'}
                    sx={(theme) => ({
                      flex: 1,
                      alignItems: 'center',
                      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                        width: '75%',
                        minWidth: 200,
                        maxWidth: 350,
                        margin: '0 auto',
                      },
                    })}
                    key={(step as unknown as { id: string }).id}>
                    <BasicNoozBadge
                      size={10}
                      color={'title'}>
                      {`${
                        (data?.instructions?.stepLabel as ILabel).fields.text
                      } ${idx + 1}`.toUpperCase()}
                      <Flex ml={8}>
                        <NoozIcon
                          iconKey={(icon as IIcon).fields.iconKey}
                          size={14}
                          color={theme.colors.main[0]}
                        />
                      </Flex>
                    </BasicNoozBadge>
                    <NoozText
                      mt={10}
                      size={14}
                      sx={(theme) => ({
                        [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                          textAlign: 'center',
                        },
                      })}
                      weight={700}>
                      {(label as ILabel).fields.text}
                      <NoozText
                        component={'span'}
                        size={14}
                        weight={500}
                        ml={4}>
                        {(subLabel as ILabel).fields.text}
                      </NoozText>
                    </NoozText>
                  </Flex>
                );
              },
            )
          : null}
      </Flex>
      <NoozButton
        sx={{
          minWidth: 200,
          width: 'fit-content',
          '& .mantine-Button-label > *': {
            marginLeft: 8,
          },
        }}
        onClick={toggleStep}>
        {(data?.online.button as unknown as IButton).fields.label?.fields?.text}
        <NoozIcon
          iconKey={'ChevronRight'}
          color={'white'}
          size={14}
        />
      </NoozButton>
    </Flex>
  );
};

export default memo(StartStep);
