import usePageContext from '@/utils/context/page';
import Reseller from '@/templates/Form/Reseller';
import Distributor from '@/templates/Form/Distributor';
import TrackOrder from '@/templates/Form/TrackOrder';

const FormPage = () => {
  const { page } = usePageContext();
  const FormComponent = (() => {
    if (/Distributor/g.test(page?.fields?.title || '')) return Distributor;
    if (/Reseller/g.test(page?.fields?.title || '')) return Reseller;
    if (/TrackOrder/g.test(page?.fields?.title || '')) return TrackOrder;

    return null;
  })();

  return FormComponent ? <FormComponent /> : null;
};

export default FormPage;
