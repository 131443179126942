import { Box, Container, clsx, createStyles } from '@mantine/core';
import React, { memo } from 'react';
import usePageContext from '@/utils/context/page';
import { ProductProvider } from '@/utils/context/product';
import {
  FocusMarketingInfo,
  FrameLabelMarketingInfo,
  UspMarketingInfo,
} from '@/components/Product/MarketingInfo/MarketingInfo';
import { UniqueProductReco } from '@/components/Product/ProductReco/ProductReco';
import { SingleProductInformation } from '@/components/Product/ProductInformation/ProductInformation';
import useStickyHeader from '@/utils/hook/useStickyProduct';
import { useContainerStyles } from '@/utils/style/useContainer';
import { ProductTemplateProps } from '@/interface.custom';
import { ProductSchemaOrgJSON } from '@/helpers/SchemaOrgJsonLd';
import ProductDisplayer from '@/components/ProductDisplayer/ProductDisplayer';
import { ShopifyProductProvider } from '@/utils/context/shopifyProduct';
import { ShopStoryEl } from '@/components/Shopstory/Shopstory';
import { APP_INFO } from '@/utils';

export const PRODUCT_LAYOUT_MEDIA_QUERY = 'md';

const useLayoutStyles = createStyles((theme) => {
  return {
    wrapper: {
      minHeight: 'calc(100vh - 90px)',
      backgroundColor: theme.colors.lightGray[0],
      display: 'flex',
      [theme.fn.smallerThan(theme.breakpoints[PRODUCT_LAYOUT_MEDIA_QUERY])]: {
        flexDirection: 'column',
        height: 'fit-content',
        minHeight: 'unset',
      },
    },
    info: {
      backgroundColor: theme.white,
      width: '33.333vw',

      [theme.fn.smallerThan('lg')]: {
        width: '40vw',
        minWidth: 'unset',
      },

      [theme.fn.smallerThan(PRODUCT_LAYOUT_MEDIA_QUERY)]: {
        width: '100vw',
        minWidth: 'unset',
      },
    },
    reco: {
      marginTop: 100,
    },
  };
});

const Product = () => {
  useStickyHeader('#sticky-add-to-cart');
  const { product, shopifyProduct } =
    usePageContext() as unknown as ProductTemplateProps;
  const { classes } = useLayoutStyles();
  const containerStyles = useContainerStyles();

  return (
    <ShopifyProductProvider
      product={shopifyProduct as Record<string, ShopifyProductInfo>}>
      <ProductProvider product={product}>
        <ProductSchemaOrgJSON />
        <Box className={classes.wrapper}>
          <ProductDisplayer />
          <SingleProductInformation className={classes.info} />
        </Box>

        {product.fields?.collection?.fields?.productShopstory ? (
          <Box>
            <ShopStoryEl
              shopstoryBlock={product.fields.collection.fields.productShopstory}
            />
          </Box>
        ) : null}
        <UspMarketingInfo
          hasShopstory={!!product.fields.collection?.fields?.productShopstory}
          mb={0}
        />
        <Container
          className={containerStyles.classes.container}
          mt={48}>
          <FrameLabelMarketingInfo />
        </Container>
        {APP_INFO.type === 'B2C' ? (
          <UniqueProductReco className={clsx(classes.reco)} />
        ) : null}
        <FocusMarketingInfo />
        {/* <StaticContentTrustpilotReviews /> */}
        <Box py={40} />
      </ProductProvider>
    </ShopifyProductProvider>
  );
};

export default memo(Product);
