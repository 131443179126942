import React from 'react';
import distance from '@turf/distance';
// @ts-ignore
import mapboxgl, { Map, Popup } from 'mapbox-gl';
import ReactDOM from 'react-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { StoreLocatorPopup } from './StoreLocatorPopup';

// Set Mapbox access token
mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAPBOX_API_KEY as string;

// Return the number of stores displayed on the map
export const returnPointOnMapLength = (map: Map): number => {
  let pointLength = 0;

  // Count the number of stores that are clustered
  // @ts-ignore
  map.queryRenderedFeatures({ layers: ['cluster-count'] }).forEach((count) => {
    pointLength += (count.properties as any).point_count;
  });

  // Count the number of stores that are not clustered
  pointLength += map.queryRenderedFeatures({
    // @ts-ignore
    layers: ['unclustered-point'],
  }).length;

  // Limit the number of stores displayed to 20
  if (pointLength > 20) {
    pointLength = 20;
  }

  return pointLength;
};

// Remove the popup for a store from the map
export const removeStoresPopup = (): void => {
  const popUps = document.getElementsByClassName('mapboxgl-popup');
  if (popUps[0]) popUps[0].remove();
};

// Create a popup for a store on the map
export const createPopUp = (map: Map, currentFeature: any): void => {
  const placeholder = document.createElement('div');

  // Render the popup component with store data
  ReactDOM.render(
    <StoreLocatorPopup data={currentFeature.properties} />,
    placeholder,
  );

  // Add the popup to the map at the store location
  new Popup({ offset: 18 })
    .setLngLat(currentFeature.geometry.coordinates)
    .setDOMContent(placeholder)
    .addTo(map);
};

// Fly to a store on the map and zoom in
export const flyToStore = (map: Map, currentFeature: any): void => {
  map.flyTo({
    center: currentFeature.geometry.coordinates,
    zoom: 18,
  });
};

// Undo fly to store and zoom out
export const undoFlyToStore = (map: Map, currentFeature: any): void => {
  map.flyTo({
    center: currentFeature.geometry.coordinates,
    zoom: 14,
  });
};

// Initialize the clusters and markers on the map
export const initializeMapLayers = (map: Map): void => {
  const ids = {
    clusters: 'clusters',
    clusterCount: 'cluster-count',
    unClusteredCount: 'unclustered-point',
  };
  if (!map.getLayer(ids.clusters))
    map.addLayer({
      id: ids.clusters,
      type: 'circle',
      source: 'stores-data',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#707070',
          20,
          '#636363',
          30,
          '#474847',
        ],
        'circle-radius': ['step', ['get', 'point_count'], 20, 20, 30, 50, 40],
      },
    });

  if (!map.getLayer(ids.clusterCount))
    map.addLayer({
      id: ids.clusterCount,
      type: 'symbol',
      source: 'stores-data',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-size': 12,
      },
      paint: {
        'text-color': '#ffffff',
      },
    });

  if (!map.getLayer(ids.unClusteredCount))
    map.addLayer({
      id: ids.unClusteredCount,
      type: 'symbol',
      source: 'stores-data',
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': 'nooz-marker',
        'icon-padding': 0,
        'icon-allow-overlap': true,
      },
    });
};

// Return an object of stores sorted by distance from the user's location
export const returnAndSortLocalisedStores = (
  allstores: any,
  lng: number,
  lat: number,
): any => {
  const newStores = { features: [], ...allstores };

  // Calculate the distance from each store to the user's location
  newStores.features?.forEach((store: any) => {
    Object.defineProperty(store.properties, 'distance', {
      value: distance([lng, lat], store.geometry.coordinates),
      writable: true,
      enumerable: true,
      configurable: true,
    });
  });

  // Sort the stores by distance from the user's location
  newStores.features.sort((a: any, b: any) => {
    if (a.properties.distance > b.properties.distance) {
      return 1;
    }
    if (a.properties.distance < b.properties.distance) {
      return -1;
    }
    return 0;
  });

  newStores.features = [...newStores.features];

  return newStores;
};

// The above functions can be used to work with Mapbox GL JS and render store data in a React application.
