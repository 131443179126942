//@ts-nocheck
import deleteKeys from '@/helpers/deleteKeys';
import Currency from '@/helpers/Currency';
import Url from '@/helpers/Url';
import { NextRouter } from 'next/router';

class ShopifyFormatter {
  static fromProducts(products: Record<string, any>) {
    const formatted: Record<string, ShopifyProduct> = {};

    (
      products?.edges?.map(({ node }: Record<string, any>) => ({
        ...node,
        variants: this.fromVariants(node.variants),
      })) as ShopifyProduct[]
    ).forEach((value: ShopifyProduct) => {
      formatted[value.title] = value;
    });

    return deleteKeys(formatted, ['__typename']);
  }
  static fromVariants(variants: Record<string, any>) {
    let formatted: Record<string, ShopifyProductVariant> = {};

    variants?.edges
      ?.map((e: Record<string, any>) => e.node)
      .forEach(
        (variant: ShopifyProductVariant) => (formatted[variant.sku] = variant),
      );

    return deleteKeys(formatted, ['__typename']);
  }
  static getProductVariantPrice(
    pv: ShopifyProductVariant,
    key: 'price' | 'compareAtPrice' = 'price',
  ) {
    return pv?.[key]
      ? `${pv[key].amount} ${Currency.toSymbol(pv[key].currencyCode)}`
      : '';
  }
  static getVariantInfo(
    router: NextRouter,
    shopifyVariants: any[],
    variantTitle: string,
  ) {
    try {
      let diopter = Url.getQueryParam(router, 'diopter');

      if (diopter) {
        diopter = diopter.replace('%2B', '+');
      }

      return (
        shopifyVariants?.find((node) => {
          if (node.sku.includes(variantTitle)) {
            const matchingOption = node.selectedOptions.find(
              ({ name, value }) => {
                return name === 'Diopter' && value === diopter;
              },
            );
            return !diopter || matchingOption;
          }
          return false;
        }) || shopifyVariants[0]
      );
    } catch (e) {
      throw e;
    }
  }
}

export default ShopifyFormatter;
