//@ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { Box, Flex, Grid } from '@mantine/core';
import { IBlock } from '@/@types/generated/contentful';
import {
  createPopUp,
  flyToStore,
  initializeMapLayers,
  removeStoresPopup,
  returnAndSortLocalisedStores,
  returnPointOnMapLength,
} from './utils';
// Import sub-components
import { StoreLocationList } from './StoreLocationList';
import { LocationDetail } from './StoreLocationDetail';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import { IMapCfComponents } from '@/interface.custom';

// Set initial coordinates and zoom level for the map
const longitude = 2.3488;
const latitude = 48.8534;
const zoom = 10;
const initialClusteredPointLength = 20;

// Set the Mapbox access token
mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAPBOX_API_KEY;

// Define the types of the store and props objects
interface IStore {
  [key: string]: any;
}

// Define the main component
const DesktopStoreLocatorMap = ({
  stores,
  marker,
  storeDetails,
  list,
  categories,
}: IMapCfComponents) => {
  // Define various state variables to manage the map and the UI
  const [currentStore, setCurrentStore] = useState<IStore | null>(null);
  const [, setIsLoading] = useState<boolean>(true);
  const [map, setMap] = useState<Map | null>();
  const [geocoder, setGeocoder] = useState<MapboxGeocoder | null>(null);
  const [currentZoom, setCurrentZoom] = useState<number>(zoom);
  const [activeStoreDetail, setActiveStoreDetail] = useState<boolean>(false);
  const [activeStoreIndex, setActiveStoreIndex] = useState<number | null>(null);
  const [lng, setLng] = useState<number>(longitude);
  const [lat, setLat] = useState<number>(latitude);
  const [storeList, setStoreList] = useState<any>(
    returnAndSortLocalisedStores(stores, lng, lat),
  );
  const headerHeight = useHeaderHeight();

  // Set the number of unClustered points to display on the map
  const [unClusteredPointLength, setUnClusteredPointLength] = useState(
    initialClusteredPointLength,
  );

  // Create a ref for the map container
  const mapContainerRef = useRef(null);

  // Function to handle clicking on a store location
  const handleLocationClick = (store, index) => {
    setActiveStoreDetail(true);
    flyToStore(map, store);
    setActiveStoreIndex(index);
    setCurrentStore(store);
  };

  // Initialize the map and the geocoder
  useEffect(() => {
    if (mapContainerRef) {
      setMap(
        new mapboxgl.Map({
          container: mapContainerRef.current,
          style: process.env.NEXT_PUBLIC_MAPBOX_STYLE_URL,
          center: [lng, lat],
          zoom,
          maxZoom: 18,
          minZoom: 3,
        }),
      );
      setGeocoder(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl,
          marker: false,
          zoom,
        }),
      );
    }
  }, []);

  useEffect(() => {
    // When the map is ready
    if (typeof map !== 'undefined' && map !== null) {
      // Inital load of controls (zoom in / out)
      map.addControl(
        new mapboxgl.NavigationControl({ showCompass: false }),
        'top-left',
      );
      // When the map is fully loaded
      map.on('load', () => {
        if (map && map.getSource('stores-data')) {
          map.getSource('stores-data').setData(storeList);
        }

        // Add the geocoder search input to the map
        if (
          document.getElementById('geocoder')?.children &&
          document.getElementById('geocoder')?.children.length > 0
        )
          document
            .getElementById('geocoder')
            .replaceChild(
              geocoder.onAdd(map),
              document.getElementById('geocoder').children[0],
            );
        else
          document.getElementById('geocoder').appendChild(geocoder.onAdd(map));

        // When a search result is selected
        geocoder.on('result', (e: Event) => {
          // Get the coordinate of the search result
          const searchResult = e.result.geometry;
          const resultLng = parseFloat(searchResult.coordinates[0]);
          const resultLat = parseFloat(searchResult.coordinates[1]);
          setLng(resultLng);
          setLat(resultLat);
        });

        // Add the stores data as a GeoJSON source to the map
        map.addSource('stores-data', {
          type: 'geojson',
          data: storeList,
          cluster: true,
          clusterMaxZoom: 12,
        });

        // Load the custom marker image onto the map
        if (marker.fields.media != null) {
          map.loadImage(
            marker.fields.media.fields.file.url,
            (error: Error, image: any) => {
              if (error) throw error;
              map.addImage('nooz-marker', image);
            },
          );
        }

        // Initialize the map layers
        initializeMapLayers(map);

        // When a cluster is clicked
        map.on('click', 'clusters', (e) => {
          // Zoom on the on map when a cluster is cliked
          const features = map.queryRenderedFeatures(e.point, {
            layers: ['clusters'],
          });
          const clusterId = features[0].properties.cluster_id;
          map
            .getSource('stores-data')
            .getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;

              map.easeTo({
                center: features[0].geometry.coordinates,
                zoom,
              });
            });
        });

        // When the mouse enters a cluster
        map.on('mouseenter', 'clusters', () => {
          map.getCanvas().style.cursor = 'pointer';
        });

        // When the mouse leaves a cluster
        map.on('mouseleave', 'clusters', () => {
          map.getCanvas().style.cursor = '';
        });

        // When an unclustered point is clicked
        map.on('click', 'unclustered-point', (e) => {
          handleLocationClick(e.features[0]);
        });

        // When the mouse enters an unclustered point
        map.on('mouseenter', 'unclustered-point', (e) => {
          // trigger location tooltip on desktop only
          if (true) {
            map.getCanvas().style.cursor = 'pointer';
            createPopUp(map, e.features[0]);
          }
        });
        // When the mouse leaves an unclustered point
        map.on('mouseleave', 'unclustered-point', () => {
          map.getCanvas().style.cursor = '';
          removeStoresPopup();
        });

        setIsLoading(false);
      });

      // When the map view is changed
      map.on('moveend', () => {
        const lg = map.getCenter().lng.toFixed(4);
        const lt = map.getCenter().lat.toFixed(4);

        // Set the number of stores visible on the list
        setUnClusteredPointLength(returnPointOnMapLength(map));
        setCurrentZoom(map.getZoom());
        setStoreList(
          returnAndSortLocalisedStores(
            storeList,
            parseFloat(lg),
            parseFloat(lt),
          ),
        );
      });

      // Get user's position
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          setLng(position.coords.longitude);
          setLat(position.coords.latitude);
        });
      }
    }
    return () => {
      if (map) {
        if (!map.current) return;
        map.remove();
      }
    };
  }, [map]);

  return (
    <Grid maw={'100vw'}>
      <Grid.Col span={4}>
        <Box
          h={`calc(100vh - ${headerHeight.wrapper + headerHeight.marginTop}px)`}
          mah={`calc(100vh - ${
            headerHeight.wrapper + headerHeight.marginTop
          }px)`}
          sx={{
            position: 'relative',
            overflow: 'auto',
            padding: '12px 16px 12px 24px',
            '& > *:not(:first-of-type)': { marginTop: 16 },
          }}>
          <Box
            id='geocoder'
            className='geocoder'
            sx={(theme) => ({
              position: 'sticky',
              top: 0,
              zIndex: 10,
              '& .mapboxgl-ctrl-geocoder': {
                width: '100%',
                minWidth: '100%',
                boxShadow: 'none',
                border: `1px solid ${theme.colors.gray[4]}`,
                borderRadius: 25,
                height: 44,
                '& > svg': {
                  top: 12,
                  left: 12,
                },
                '& > input.mapboxgl-ctrl-geocoder--input': {
                  height: 44,
                  padding: '6px 40px',
                  '&:focus': {
                    outline: 'none',
                  },
                },
              },
            })}
          />
          <Flex
            direction={'column'}
            gap={16}
            sx={{ flex: 1, overflow: 'auto' }}>
            {activeStoreDetail ? ( // Display the store location detail component
              <LocationDetail
                map={map}
                setActiveStoreDetail={setActiveStoreDetail}
                currentStore={currentStore}
                dictionary={categories}
                {...storeDetails}
              />
            ) : (
              // Display the store location list component
              <StoreLocationList
                activeStoreIndex={activeStoreIndex}
                createPopUp={createPopUp}
                currentZoom={currentZoom}
                list={storeList.features.slice(0, unClusteredPointLength)}
                map={map}
                onClick={handleLocationClick}
                removePopup={removeStoresPopup}
                setActiveStoreIndex={setActiveStoreIndex}
                storeDetails={{ ...storeDetails }}
                dictionary={categories}
                {...list}
              />
            )}
          </Flex>
        </Box>
      </Grid.Col>
      <Grid.Col span={8}>
        <Box
          h={`calc(100vh - ${headerHeight.wrapper + headerHeight.marginTop}px)`}
          ref={mapContainerRef}
        />
      </Grid.Col>
    </Grid>
  );
};

export default DesktopStoreLocatorMap;
