import { IBlogCategory, IResourceTopic } from '@/@types/generated/contentful';
import React, { memo, useMemo } from 'react';
import usePageContext from '@/utils/context/page';
import {
  ActionIcon,
  Box,
  Card,
  Container,
  Divider,
  Grid,
  Group,
  createStyles,
  useMantineTheme,
  Flex,
} from '@mantine/core';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { HeroTextBanner } from '@/components/Hero/Banner/HeroTextBanner';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import Link from '@/components/Link/Link';
import { ResourceCategoryTemplateProps } from '@/interface.custom';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { SearchInput, useInput } from '@/components/Search/Search';
import TextLink from '@/components/Link/TextLink';
import { useContainerStyles } from '@/utils/style/useContainer';
import SearchNoResults from '@/components/Search/SearchNoResults/SearchNoResults';
import useStaticContent from '@/utils/hook/useStaticContent';
import i18n from '@/helpers/i18n';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';

const useStyles = createStyles((theme) => ({
  navigationItem: {
    transition: 'transform 150ms ease, box-shadow 100ms ease',

    '&:hover': {
      boxShadow: theme.shadows.sm,
      transform: 'scale(1.02)',
    },
  },
}));

export const FaqNavigationItem = ({ item }: { item: IBlogCategory }) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  if (
    !item.fields.pageReference?.fields.slug?.fields.url ||
    !item.fields.pageReference?.fields.slug?.fields
  ) {
    return null;
  }

  return (
    <Link href={item.fields.pageReference?.fields.slug.fields.url as string}>
      <Card
        p={20}
        className={classes.navigationItem}
        radius='lg'
        withBorder>
        <Group noWrap>
          <ActionIcon
            color='primary'
            size={40}
            variant='outline'
            radius='xl'>
            <NoozIcon
              color={theme.colors[theme.primaryColor][6]}
              size={24}
              strokeWidth={1.5}
              iconKey={item.fields.icon?.fields.iconKey}
            />
          </ActionIcon>
          <Box>
            <TextLink
              weight={'bold'}
              text={item.fields.label?.fields.text}
              url={item.fields.pageReference?.fields.slug.fields.url}
              size={24}
            />
            <NoozText size={14}>{item.fields.subLabel?.fields.text}</NoozText>
          </Box>
        </Group>
      </Card>
    </Link>
  );
};

const FaqNavigationGrid = () => {
  const staticContent = useStaticContent();
  const { resourceCategory } =
    usePageContext() as unknown as ResourceCategoryTemplateProps;
  return (
    <>
      <NoozText
        fw={700}
        mb={20}
        size={24}>
        {i18n(staticContent, 'allTopicsLabel')}
      </NoozText>
      <Grid>
        {resourceCategory?.fields.subCategory?.map((item, key) => (
          <Grid.Col
            span={12}
            sm={6}
            key={key}>
            <FaqNavigationItem
              item={item}
              key={JSON.stringify(item)}
            />
          </Grid.Col>
        ))}
      </Grid>
    </>
  );
};

const FaqSearchResults = ({ hits }: { hits: IResourceTopic[] }) => {
  if (hits.length === 0) {
    return <SearchNoResults />;
  }
  return (
    <>
      {hits.map((hit: any) => {
        return (
          <TextLink
            key={hit.id}
            type={'animated-underline'}
            url={hit.fields.slug}
            text={hit.fields?.questionLabel?.fields?.text}
          />
        );
      })}
    </>
  );
};

const FaqTopResults = () => {
  const { resourceCategoryTopics: hits } = usePageContext() as any;
  const topResults = hits.slice(0, 5);

  return topResults.map((hit: any) => {
    return (
      <TextLink
        key={hit.id}
        type={'animated-underline'}
        size={14}
        url={hit.fields.slug}
        text={hit.fields.questionLabel?.fields.text}
      />
    );
  });
};

const FaqBody = memo(({ query }: { query: string }) => {
  const staticContent = useStaticContent();
  const theme = useMantineTheme();
  const { resourceCategoryTopics } =
    usePageContext() as unknown as ResourceCategoryTemplateProps;

  const hits = useMemo(() => {
    return structuredClone(resourceCategoryTopics || []).filter((el) => {
      const title = (
          el.fields?.questionLabel?.fields?.text || ''
        ).toLowerCase(),
        keys = query
          .replace(/\s{2,}/g, ' ')
          .trim()
          .toLowerCase()
          .split(' ');

      return keys.reduce((match, key) => {
        if (!title.includes(key)) match = false;
        return match;
      }, true);
    });
  }, [query, resourceCategoryTopics]);
  if (!query || query.length === 0) {
    return (
      <Grid gutter={30}>
        <Grid.Col
          span={12}
          sm={8}>
          <FaqNavigationGrid />
        </Grid.Col>
        <Grid.Col
          span={12}
          sm={4}>
          <NoozText
            fw={700}
            mb={20}
            size={24}>
            Top asked questions
          </NoozText>
          <FaqTopResults />
          <Divider my={20} />
          <NoozText
            fw={700}
            mb={10}
            size={24}>
            Service client
          </NoozText>
          <NoozText
            mb={20}
            size={14}>
            Lun - Ven 9:00 - 18:00
          </NoozText>
          <Group>
            <ActionIcon
              color='primary'
              size={40}
              variant='outline'
              radius='xl'>
              <NoozIcon
                color={theme.colors[theme.primaryColor][6]}
                size={24}
                strokeWidth={1.5}
                iconKey='Mail'
              />
            </ActionIcon>

            <TextLink
              type='animated-underline'
              text={i18n(staticContent, 'faqCustomerServiceEmail')}
              url={`mailto:${i18n(staticContent, 'faqCustomerServiceEmail')}`}
            />
          </Group>
        </Grid.Col>
      </Grid>
    );
  }

  return (
    <Grid gutter={30}>
      <Grid.Col span={12}>
        <FaqSearchResults hits={hits} />
      </Grid.Col>
    </Grid>
  );
});

const Faq = () => {
  const containerStyles = useContainerStyles();
  const staticContent = useStaticContent();
  const { resourceCategory, breadcrumbs } =
    usePageContext() as unknown as ResourceCategoryTemplateProps;
  const input = useInput();

  return (
    <Box
      mb={80}
      w='100%'
      sx={(theme) => ({
        background: theme.colors.gray[1],
        display: 'inline-block',
      })}>
      <HeroTextBanner
        subTitle={resourceCategory?.fields.subLabel?.fields.text}
        title={resourceCategory?.fields.label?.fields.text}>
        <>
          <SearchInput {...input} />

          <Flex
            direction={'column'}
            align={'center'}
            mt={30}
            mb={'-2.25rem'}>
            <a
              href={staticContent.zendeskNewTicketUrl}
              target='_blank'
              rel='noreferrer'>
              <NoozButton
                color={'dark'}
                size='md'>
                {staticContent.zendeskNewTicketLabel}
              </NoozButton>
            </a>
          </Flex>
        </>
      </HeroTextBanner>
      <Container
        size='lg'
        mt={30}
        mb={50}
        className={containerStyles.classes.container}>
        <Breadcrumbs list={breadcrumbs} />
        <Divider my={20} />
        <FaqBody query={input.query} />
      </Container>
    </Box>
  );
};

export default Faq;
