import {
  Box,
  Center,
  Container,
  clsx,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import usePageContext from '@/utils/context/page';
import React, { useMemo } from 'react';
import FrameFocusCard from '@/components/Cards/FrameFocusCard';
import GridSlider from '@/components/GridSlider/GridSlider';
import { formatCfItems } from '@/helpers/formatCfItems';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { NoozSectionTitle } from '@/components/Nooz/NoozTitle/NoozTitle';
import { IBlock } from '@/@types/generated/contentful';
import useStaticContent from '@/utils/hook/useStaticContent';
import i18n from '@/helpers/i18n';
import { useContainerStyles } from '@/utils/style/useContainer';
import { useGridSliderHighlightCardTryptiqueProps } from '@/utils/hook/useGridSliderHighlightCardProps';

const useStyles = createStyles({
  wrapper: {
    marginTop: 40,
    marginBottom: 40,
  },
});

export const UspMarketingInfo = ({
  hasShopstory,
  mb,
}: {
  hasShopstory?: boolean;
  mb?: number;
}) => {
  const { classes } = useStyles();
  const containerStyles = useContainerStyles();
  const theme = useMantineTheme();
  const { product } = usePageContext();
  const staticContent = useStaticContent();

  const color = useMemo(() => {
    return (
      theme.colors[
        product?.fields.category?.fields.color?.fields.classname as string
      ]?.[0] || theme.colors.main[0]
    );
  }, [product, theme]);

  const usps =
    product?.fields.collection?.fields?.usps?.slice(0, 3)?.map((usp) => ({
      ...usp.fields,
      color: color,
    })) || [];

  const sliderProps = useGridSliderHighlightCardTryptiqueProps();

  return usps && usps.length > 0 ? (
    <Box
      mt={hasShopstory ? 0 : undefined}
      mb={mb}
      className={classes.wrapper}
      component='section'>
      <Container className={containerStyles.classes.container}>
        <NoozSectionTitle>{i18n(staticContent, 'uspLabel')}</NoozSectionTitle>
      </Container>
      <GridSlider
        {...sliderProps}
        items={usps}
      />
    </Box>
  ) : null;
};

const useFrameLabelStyles = createStyles((theme) => ({
  wrapper: {
    margin: '120px 15%',
    [theme.fn.smallerThan(theme.breakpoints.lg)]: {
      margin: '90px 15px',
    },
  },
  label: {
    [theme.fn.smallerThan(theme.breakpoints.lg)]: {
      fontSize: 32,
    },
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      fontSize: 24,
    },
  },
}));

export const FrameLabelMarketingInfo = () => {
  const { classes } = useStyles();
  const frameLabelStyles = useFrameLabelStyles();

  const { product } = usePageContext();
  const [frame] = product?.fields?.frames || [];

  return (
    <>
      {frame?.fields?.label?.fields.text ? (
        <Center
          className={clsx(classes.wrapper, frameLabelStyles.classes.wrapper)}
          component='section'>
          <NoozText
            className={frameLabelStyles.classes.label}
            family='Mont'
            fw={400}
            size={42}
            color='title'
            align='center'>
            {frame.fields.label.fields.text}
          </NoozText>
        </Center>
      ) : null}
    </>
  );
};

export const FocusMarketingInfo = () => {
  const { classes } = useStyles();
  const containerStyles = useContainerStyles();
  const { product } = usePageContext();
  const staticContent = useStaticContent();
  const focusMarketingItems =
    product?.fields.collection?.fields?.focusMarketingItems
      ?.slice(0, 2)
      ?.map((block: IBlock) => formatCfItems(block)) || [];

  const sliderProps = useGridSliderHighlightCardTryptiqueProps();

  return (
    (focusMarketingItems?.length > 0 && (
      <Box
        className={classes.wrapper}
        component='section'>
        <Container className={containerStyles.classes.container}>
          <NoozSectionTitle>
            {i18n(staticContent, 'focusLabel')}
          </NoozSectionTitle>
        </Container>
        <GridSlider
          {...sliderProps}
          colProps={{ span: 6 }}
          items={focusMarketingItems}
          gridProps={{ ...sliderProps.gridProps, h: '100%' }}
          sliderProps={{ ...sliderProps.sliderProps, slideGap: 20 }}
          Component={(props) => <FrameFocusCard {...props} />}
        />
      </Box>
    )) ||
    null
  );
};
