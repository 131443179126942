import { createContext, useContext, useState } from 'react';
import * as React from 'react';
import { IPackData } from '@/interface.custom';
import { IVariant } from '@/utils/context/product';

export interface IVariantName extends IVariant {
  name: string;
}
export interface IPack {
  data: IPackData;
  options: {
    option1?: IVariantName | null;
    option2?: IVariantName | null;
    option3?: IVariantName | null;
  };
}
export interface IPackContext {
  setPack: React.Dispatch<React.SetStateAction<IPack>>;
  pack: IPack;
}
const PackContext = createContext<IPackContext>({} as IPackContext);

export const usePackContext = () => useContext(PackContext);

const PackProvider = ({
  defaultPack,
  children,
}: {
  defaultPack: IPackData;
  children: React.ReactNode;
}) => {
  const [pack, setPack] = useState<IPack>({
    data: defaultPack,
    options: {},
  });
  return (
    <PackContext.Provider value={{ pack, setPack }}>
      {children}
    </PackContext.Provider>
  );
};

export default PackProvider;
