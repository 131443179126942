import { Box, Container } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import React from 'react';
import usePageContext from '@/utils/context/page';
import { formatCfItems } from '@/helpers/formatCfItems';
import { useGridSliderHighlightCardTryptiqueProps } from '@/utils/hook/useGridSliderHighlightCardProps';
import GridSlider from '@/components/GridSlider/GridSlider';
import { IBlogArticle } from '@/@types/generated/contentful';
import { useContainerStyles } from '@/utils/style/useContainer';

const BlogMustRead = () => {
  const containerStyles = useContainerStyles();
  const { components } = usePageContext();
  const { label, subLabel, list } = formatCfItems(
    formatCfItems(components)?.mustRead,
  );

  const sliderProps = useGridSliderHighlightCardTryptiqueProps();
  const props = list?.fields.items?.map((article: IBlogArticle) => {
    return {
      ...article.fields,
      subLabel: null,
      image: article.fields.posterImage,
      textSize: {
        label: {
          weight: 500,
          size: {
            base: 28,
            xs: 24,
          },
        },
        subLabel: {
          weight: 300,
          size: {
            base: 18,
            xs: 14,
          },
        },
      },
    };
  });

  return (
    <>
      <Container
        my={80}
        className={containerStyles.classes.container}>
        <Box maw={600}>
          <NoozText
            size={42}
            lh={'50px'}
            weight={700}>
            {label?.fields.text}
          </NoozText>
          <NoozText
            size={18}
            mt={10}>
            {subLabel?.fields.text}
          </NoozText>
        </Box>
      </Container>

      <GridSlider
        {...sliderProps}
        items={props}
      />
    </>
  );
};

export default BlogMustRead;
