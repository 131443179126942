import React, { PropsWithChildren } from 'react';
import AppHead from '@/components/AppHead/AppHead';
import { HomeSchemaOrgJSON } from '@/helpers/SchemaOrgJsonLd';

export const PageSeo = ({ children }: PropsWithChildren) => (
  <>
    {/* Move to _app */}
    {/*<AppHead />*/}
    {/*<HomeSchemaOrgJSON />*/}
    {children}
  </>
);
