//@ts-nocheck
import { IPage } from '@/@types/generated/contentful';
import usePageContext from '@/utils/context/page';
import React, { useState } from 'react';
import { MediaQuery, createStyles } from '@mantine/core';
import { ProductTemplateProps } from '@/interface.custom';
import { PRODUCT_LAYOUT_MEDIA_QUERY } from '@/templates/Product';
import ProductDisplayerTop from './ProductDisplayerTop';
import ProductDisplayerPictures from './ProductDisplayerPictures';
import ProductDisplayerPicker from './ProductDisplayerPicker';
import { Embla } from '@mantine/carousel';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import useWindowHeight from '@/utils/hook/useWindowInnerHeight';

const useStyles = createStyles((theme) => {
  const headerHeight = useHeaderHeight();
  const windowHeight = useWindowHeight();

  return {
    wrapper: {
      display: 'flex',
      overflow: 'hidden',
      flexDirection: 'column',
      flex: 1,
      height: `calc(100vh - ${headerHeight.wrapper}px)`,
      position: 'sticky',
      top: 90,
      [theme.fn.smallerThan(PRODUCT_LAYOUT_MEDIA_QUERY)]: {
        margin: '0px',
        height: `calc(${windowHeight} - ${headerHeight.wrapper}px - 80px - 120px)`,
        minHeight: 375,
        position: 'relative',
        flex: 'unset',
        top: 0,
      },
    },
  };
});

const ProductDisplayer = () => {
  const slideState = useState<number>(0) as unknown as [
    number,
    React.Dispatch<React.SetStateAction<number>>,
  ];
  const emblaState = useState<Embla | null>(null);
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <ProductDisplayerTop />
      <ProductDisplayerPictures
        emblaState={emblaState}
        slideState={slideState}
      />
      {/*<MediaQuery*/}
      {/*  smallerThan={PRODUCT_LAYOUT_MEDIA_QUERY}*/}
      {/*  styles={{ display: 'none' }}>*/}
      {/*  <span>*/}
      {/*    <ProductDisplayerPicker*/}
      {/*      emblaState={emblaState}*/}
      {/*      slideState={slideState}*/}
      {/*    />*/}
      {/*  </span>*/}
      {/*</MediaQuery>*/}
      {/*<MediaQuery*/}
      {/*  largerThan={PRODUCT_LAYOUT_MEDIA_QUERY}*/}
      {/*  styles={{ display: 'none' }}>*/}
      {/*  <div>*/}
      <ProductDisplayerPicker
        emblaState={emblaState}
        slideState={slideState}
      />
      {/*  </div>*/}
      {/*</MediaQuery>*/}
    </div>
  );
};

export default ProductDisplayer;
