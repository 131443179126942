import { Box, Flex, Grid } from '@mantine/core';
import { IBlogArticle, ILabel } from '@/@types/generated/contentful';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import HighlightCard from '@/components/Cards/HighlightCard';

const QTY_PAGE = 12;

const generatePaginationNumbers = (currentPage: number, totalPages: number) => {
  const pageNumbers: (number | string)[] = [];

  pageNumbers.push(1);

  if (currentPage - 2 > 2) {
    pageNumbers.push('...');
  }

  for (let i = currentPage - 2; i <= currentPage + 2; i++) {
    if (i > 1 && i < totalPages) {
      pageNumbers.push(i);
    }
  }

  if (currentPage + 2 < totalPages - 1) {
    pageNumbers.push('...');
  }

  pageNumbers.push(totalPages);

  return pageNumbers;
};

const PageButton = ({
  page,
  setPage,
  active,
}: {
  page: number;
  setPage: (_p: number | null) => void;
  active: boolean;
}) => (
  <Flex
    onClick={() => setPage(page)}
    h={30}
    w={30}
    align={'center'}
    justify={'center'}
    sx={(theme) => ({
      border: `1px solid ${theme.colors.gray[5]}`,
      color: active ? 'white' : theme.colors.title[0],
      backgroundColor: active ? theme.colors.main[0] : 'white',
      borderRadius: 4,
      cursor: 'pointer',
      size: 14,
      lineHeight: '21.7px',
      weight: 400,
    })}>
    {page}
  </Flex>
);
const Pagination = ({
  active,
  setActivePage,
  nbPages,
}: {
  active: number | null;
  nbPages?: number;
  setActivePage: (_p: number | null) => void;
}) =>
  active && nbPages && nbPages > 1 ? (
    <Flex
      justify={'center'}
      mt={20}
      sx={{ gap: 8 }}>
      {generatePaginationNumbers(active, nbPages).map((value, idx) =>
        typeof value === 'string' ? (
          <NoozText key={`${value}_${idx}`}>{value}</NoozText>
        ) : (
          <PageButton
            key={`PageButton_${idx}_${value}`}
            page={value}
            setPage={setActivePage}
            active={value === active}
          />
        ),
      )}
    </Flex>
  ) : null;

const BlogGrid = ({
  articles,
}: {
  label?: ILabel;
  subLabel?: ILabel;
  articles: IBlogArticle[];
  qtyLimit?: number;
}) => {
  const router = useRouter();
  const [page, setPage] = useState<number | null>(null);

  useEffect(() => {
    if (!router.query.page) {
      setPage(1);
    } else if (
      typeof router.query.page === 'string' &&
      !Number.isNaN(parseInt(router.query.page))
    ) {
      setPage(parseInt(router.query.page));
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [router.query.page]);

  return (
    <Flex direction={'column'}>
      {page ? (
        <Grid gutter={20}>
          {articles
            .slice((page - 1) * QTY_PAGE, page * QTY_PAGE)
            .map((article, index) => {
              const props = {
                ...article.fields,
                image: article.fields.image,
                textSize: {
                  label: {
                    weight: 600,
                    size: {
                      base: 20,
                      xs: 18,
                    },
                  },
                  subLabel: {
                    size: {
                      base: 32,
                      xs: 24,
                    },
                  },
                },
              };
              return (
                <Grid.Col
                  span={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={index}>
                  <Box h={{ lg: 500, sm: 450, base: 400 }}>
                    {/* @ts-ignore */}
                    <HighlightCard {...props} />
                  </Box>
                </Grid.Col>
              );
            })}
        </Grid>
      ) : null}
      <Pagination
        active={page}
        setActivePage={(p) => {
          if (p)
            router.push({ query: { ...router.query, page: p } }, undefined, {
              shallow: true,
            });
        }}
        nbPages={Math.ceil(articles.length / QTY_PAGE)}
      />
    </Flex>
  );
};

export default BlogGrid;
