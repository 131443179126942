import React, { useMemo } from 'react';
import useProductContext from '@/utils/context/product';
import NoozButton from '../Nooz/NoozButton/NoozButton';
import useStaticContent from '@/utils/hook/useStaticContent';
import usePageContext from '@/utils/context/page';
import { AddToCartButtonProps, ICartItem } from '@/interface.custom';
import generateUUID from '@/helpers/uuid';
import useCartActions from '@/utils/hook/cart/useCartActions';
import Currency from '@/helpers/Currency';
import NoozText from '../Nooz/NoozText/NoozText';
import useShopifyProductContext from '@/utils/context/shopifyProduct';
import { Loader } from '@mantine/core';
import { APP_INFO } from '@/utils';
import useAuth from '@/utils/context/authentication';
import { formatCfItems } from '@/helpers/formatCfItems';

const AddToCartButton = ({ hasPrice = false }: { hasPrice?: boolean }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const staticContent = useStaticContent();
  const { addToCart } = useCartActions();
  const auth = useAuth();

  const { layout, product, locale, localizedSlugs } = usePageContext();
  const { variant } = useProductContext() as unknown as AddToCartButtonProps;

  const price = variant.shopifyInfo.price;

  const shopifyProduct = useShopifyProductContext();
  const isPreOrder = variant.fields.preOrder;

  const isAvailable = useMemo(() => {
    if (shopifyProduct.stockChecked) {
      // @ts-ignore
      const v = shopifyProduct.variants.find(
        (node: any) => node?.id === variant.shopifyInfo.id,
      );
      return v?.availableForSale || v?.inventoryPolicy === 'CONTINUE';
    } else {
      // @ts-ignore
      return (
        variant.shopifyInfo.availableForSale ||
        variant.shopifyInfo.inventoryPolicy === 'CONTINUE'
      );
    }
  }, [shopifyProduct, variant]);

  const isDisabled = !isAvailable && !isPreOrder;

  const handleOnClick = async () => {
    if (!shopifyProduct.stockChecked) return;
    if (product) {
      setIsLoading(true);

      const diopter = variant.shopifyInfo.selectedOptions.find(
        (so: Record<string, any>) => so.name === 'Diopter',
      )?.value;

      const cartItems: ICartItem[] = [
        {
          quantity: 1,
          type: 'item',
          dataLayerItem: {
            item_id: parseInt(
              variant.shopifyInfo.id.replace(
                'gid://shopify/ProductVariant/',
                '',
              ),
            ),
            parent_item_id: parseInt(
              (shopifyProduct.id as unknown as string).replace(
                'gid://shopify/Product/',
                '',
              ),
            ),
            item_name: product.fields.label?.fields.text,
            item_variant: variant.shopifyInfo.title,
            price: parseFloat(variant.shopifyInfo.price.amount),
            quantity: 1,
          },
          shopifyVariant: variant.shopifyInfo,
          contentfulItem: {
            title: product.fields.label?.fields.text,
            preOrder: variant?.fields?.preOrder,
            color: variant.fields.color,
            image: variant.fields.images?.[0] || null,
            price: parseFloat(variant.shopifyInfo.price.amount).toFixed(2),
            discountPrice: variant.shopifyInfo.compareAPrice
              ? parseFloat(variant.shopifyInfo.compareAPrice).toFixed(2)
              : undefined,
            currencyCode: variant.shopifyInfo.price.currencyCode,
            categoryColor: product.fields.category?.fields?.color?.fields.hex,
            categoryTitle: product.fields.category?.fields?.label?.fields.text,
            categoryGradientColor:
              product.fields.category?.fields.gradientColor?.fields.hex,
            itemOpt: [
              { key: 'color', value: variant.fields.color?.fields.text },
              {
                key: 'collection',
                value: product.fields.collection?.fields?.label?.fields.text,
              },
              {
                key: 'diopter',
                value: diopter ? (diopter !== '0' ? diopter : '-') : undefined,
              },
              {
                key: 'sizeLabel',
                value: variant.shopifyInfo.selectedOptions.find(
                  (opt: Record<string, string>) => opt.name === 'Size',
                )?.value,
              },
            ].filter((opt) => !!opt.value),
            url: (localizedSlugs as unknown as Record<string, string>).en,
            id: variant.id,
            algoliaId: `${variant.id}_${(locale as String)
              .split('-')
              .reduce((prev, v, key) => {
                if (key === 0) return v.toLowerCase();
                return prev + `-${v}`;
              }, '')}`,
          },
        },
      ];
      await addToCart(
        cartItems,
        [
          { key: '__itemType', value: 'single' },
          { key: '__itemId', value: generateUUID() },
        ],
        // 'drawer',
        'redirect',
      );
      setIsLoading(false);
    }
  };

  if (APP_INFO.type === 'B2B' && auth.isLoading) {
    return (
      <NoozButton
        loading={true}
        radius={100}
        color={'title'}
        disabled={true}
        fullWidth
      />
    );
  } else if (APP_INFO.type === 'B2B' && !auth.isAuthenticated) {
    const { login, loginLinkLabel } = formatCfItems(
      (layout as any).fields.header,
    );
    return (
      <NoozButton
        radius={100}
        color={'title'}
        href={login?.fields.url}
        fullWidth>
        {loginLinkLabel?.fields.text}
      </NoozButton>
    );
  }

  return (
    <NoozButton
      loading={isLoading}
      onClick={handleOnClick}
      radius={100}
      color={'title'}
      disabled={isDisabled}
      fullWidth>
      {shopifyProduct.stockChecked ? (
        <NoozText component='span'>
          {
            staticContent[
              isAvailable && !isPreOrder
                ? 'addToCart'
                : isPreOrder
                ? 'preOrder'
                : 'soldOut'
            ]
          }
        </NoozText>
      ) : (
        <Loader
          size={20}
          color='white'
        />
      )}
      {hasPrice && isAvailable ? (
        <>
          <NoozText
            px={6}
            component='span'
            weight={400}>
            {' - '}
          </NoozText>
          <NoozText
            component='span'
            weight={400}>
            {Currency.toFormattedPrice(price.amount)}
            {Currency.toSymbol(price.currencyCode)}
          </NoozText>
        </>
      ) : null}
    </NoozButton>
  );
};

export default AddToCartButton;
