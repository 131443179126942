import React, { useEffect, useState } from 'react';
import StoreLocatorMap from '@/components/StoreLocatorMap/StoreLocatorMap';
import { Flex, Loader } from '@mantine/core';
import axios from 'axios';
import * as process from 'process';

const StoreLocator: React.FC = () => {
  const [stores, setStores] = useState<undefined | any>();
  useEffect(() => {
    axios
      .get(`${process.env.NEXT_PUBLIC_DOMAIN}/json/stores.json`)
      .then(({ data }) => {
        setStores(data);
      });
  }, []);
  return stores ? (
    <StoreLocatorMap stores={stores} />
  ) : (
    <Flex
      align={'center'}
      justify={'center'}
      h={'calc(100vh - 100px)'}
      w={'100%'}>
      <Loader />
    </Flex>
  );
};

export default StoreLocator;
