import { useToggle } from '@mantine/hooks';
import StartStep from '@/components/ProductEyeTest/WizardSteps/StartStep';
import { useState } from 'react';
import EndStep from '@/components/ProductEyeTest/WizardSteps/EndStep';
import FormStep from '@/components/ProductEyeTest/WizardSteps/FormStep';
import { motion } from 'framer-motion';

const STEPS = ['START', 'FORM', 'END'];
const ProductEyeTestWizard = ({ source }: { source: any }) => {
  const [step, toggleStep] = useToggle(STEPS);
  const [diopter, setDiopter] = useState<number | string | null>(null);
  switch (step) {
    case STEPS[0]:
      return (
        <motion.div
          initial={{ opacity: 0, scale: 0, flex: 1 }}
          animate={{
            scale: 1,
            opacity: 1,
            transition: { ease: 'easeOut', duration: 0.2 },
          }}
          exit={{
            opacity: 0,
            scale: 0,
            transition: { ease: 'easeOut', duration: 0.5 },
          }}>
          <StartStep
            source={source}
            toggleStep={toggleStep}
          />
        </motion.div>
      );
    case STEPS[1]:
      return (
        <motion.div
          initial={{ opacity: 0, scale: 0, flex: 1 }}
          animate={{
            scale: 1,
            opacity: 1,
            transition: { ease: 'easeOut', duration: 0.2 },
          }}
          exit={{
            opacity: 0,
            scale: 0,
            transition: { ease: 'easeOut', duration: 0.5 },
          }}>
          <FormStep
            source={source}
            toggleStep={toggleStep}
            setDiopter={setDiopter}
          />
        </motion.div>
      );
    case STEPS[2]:
      return (
        <motion.div
          initial={{ opacity: 0, scale: 0, flex: 1 }}
          animate={{
            scale: 1,
            opacity: 1,
            transition: { ease: 'easeOut', duration: 0.2 },
          }}
          exit={{
            opacity: 0,
            scale: 0,
            transition: { ease: 'easeOut', duration: 0.5 },
          }}>
          <EndStep
            source={source}
            toggleStep={toggleStep}
            diopter={diopter}
          />
        </motion.div>
      );
  }
  return null;
};

export default ProductEyeTestWizard;
