// @ts-nocheck
import { Box, Divider, Flex, MediaQuery, TextInput } from '@mantine/core';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import useStaticContent from '@/utils/hook/useStaticContent';
import i18n from '@/helpers/i18n';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import Currency from '@/helpers/Currency';
import useCommerceContext, { useDiscounts } from '@/utils/context/commerce';
import ShopifyBuy from 'shopify-buy';
import React, { useState } from 'react';
import CartFreeShipping from '@/components/Cart/CartFreeShipping/CartFreeShipping';
import { notifications } from '@mantine/notifications';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { CART_BREAKPOINT } from '@/templates/Cart';
import { useFreeShipping } from '@/utils/hook/cart/useFreeShipping';
import ProductInformationCarousel from '@/components/Product/ProductInformation/ProductInformationCarousel';
import { fetchShopifyCart } from '@/utils/services/ShopstoryStorefrontService';
import usePageContext from '@/utils/context/page';
import zIndexes from '@/utils/style/zIndex';
import { MOBILE_CONTAINER_PADDING } from '@/utils/style/useContainer';
import LocaleHelper from '@/helpers/Locale';

export const StickyCheckout = ({ loading }) => {
  const staticContent = useStaticContent();
  const { cart } = useCommerceContext() as unknown as {
    client: ShopifyBuy;
    cart: ShopifyBuy.Checkout;
    setCart: React.Dispatch<React.SetStateAction<ShopifyBuy.Checkout | null>>;
  };

  const checkoutUrl = cart?.webUrl;

  return (
    <Box
      p='lg'
      py='md'
      bg='white'
      w='100vw'
      pos={'fixed'}
      left={0}
      bottom={0}
      sx={() => ({ zIndex: zIndexes.header })}>
      <a href={checkoutUrl}>
        <NoozButton
          mt={10}
          loading={loading}
          disabled={loading}
          fullWidth>
          {i18n(staticContent, 'checkout')}
        </NoozButton>
      </a>
    </Box>
  );
};

const Checkout = ({ loading }: { loading: boolean }) => {
  const discounts = useDiscounts();
  const freeShipping = useFreeShipping();
  const { geoMarket, locale } = usePageContext();
  const [promoCode, setPromoCode] = useState<string | undefined>();
  const { client, cart, setCart } = useCommerceContext() as unknown as {
    client: ShopifyBuy;
    cart: ShopifyBuy.Checkout;
    setCart: React.Dispatch<React.SetStateAction<ShopifyBuy.Checkout | null>>;
  };

  const checkoutUrl = cart?.webUrl;

  const staticContent = useStaticContent();

  return (
    <Flex
      direction={'column'}
      sx={(theme) => ({
        padding: '30px',
        backgroundColor: theme.colors.gray[0],
        borderRadius: 20,
        gap: 4,
        [theme.fn.smallerThan(CART_BREAKPOINT)]: {
          padding: `30px ${MOBILE_CONTAINER_PADDING}px`,
          backgroundColor: theme.colors.gray[2],
          borderRadius: 0,
          marginBottom: 15,
        },
      })}>
      <NoozText
        color={'title'}
        size={18}
        weight={900}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        {i18n(staticContent, 'subtotal')}
        <NoozText
          component={'span'}
          color={'text'}
          size={16}
          weight={400}>
          {(
            parseFloat(cart?.lineItemsSubtotalPrice.amount) -
            (cart?.discountApplications || []).reduce((prev, discount) => {
              if (
                discount.__typename === 'ScriptDiscountApplication' &&
                discount.value.amount
              ) {
                prev += parseFloat(discount.value.amount);
              }
              return prev;
            }, 0.0)
          ).toFixed(2)}
          <NoozText
            component={'span'}
            color={'text'}
            size={16}
            weight={400}>
            {` ${Currency.toSymbol(cart?.lineItemsSubtotalPrice.currencyCode)}`}
          </NoozText>
        </NoozText>
      </NoozText>

      <NoozText
        color={'title'}
        size={18}
        weight={900}
        mt={10}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        {i18n(staticContent, 'shippingPriceLabel')}
        <NoozText
          component={'span'}
          color={'text'}
          align='right'
          size={16}
          weight={400}>
          {i18n(
            staticContent,
            freeShipping && freeShipping.percentage >= 100
              ? 'freeShipping'
              : 'shippingInformationLabel',
          )}
        </NoozText>
      </NoozText>

      <MediaQuery
        smallerThan={CART_BREAKPOINT}
        styles={{ display: 'none' }}>
        <Box>
          {freeShipping ? (
            <>
              {freeShipping.missingAmount > 0 ? (
                <>
                  <Divider my={20} />
                  <Box>
                    <NoozText
                      size={16}
                      weight={500}
                      mb={10}>
                      {
                        i18n(staticContent, 'freeShippingLabel').split(
                          '{{amount}}',
                        )[0]
                      }

                      <NoozText
                        component={'span'}
                        size={14}
                        weight={700}>
                        {` ${freeShipping.missingAmount.toFixed(2)} ${
                          freeShipping.currency
                        } `}
                      </NoozText>
                      {
                        i18n(staticContent, 'freeShippingLabel').split(
                          '{{amount}}',
                        )[1]
                      }
                    </NoozText>
                    <CartFreeShipping
                      size={'lg'}
                      showLabel={false}
                    />
                  </Box>
                </>
              ) : null}
            </>
          ) : null}
        </Box>
      </MediaQuery>

      <Divider my={20} />

      <NoozText
        color={'title'}
        size={18}
        weight={900}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        {i18n(staticContent, 'totalLabel')}
        <NoozText
          component={'span'}
          color={'title'}
          size={24}
          weight={700}>
          {parseFloat(String(cart?.totalPrice.amount)).toFixed(2)}
          <NoozText
            component={'span'}
            color={'main'}
            size={20}
            weight={700}>
            {` ${Currency.toSymbol(cart?.totalPrice.currencyCode)}`}
          </NoozText>
        </NoozText>
      </NoozText>
      {loading ? (
        <NoozButton
          mt={10}
          disabled={loading}
          loading={loading}
          fullWidth>
          {i18n(staticContent, 'checkout')}
        </NoozButton>
      ) : (
        <a href={checkoutUrl}>
          <NoozButton
            mt={10}
            disabled={loading}
            loading={loading}
            fullWidth>
            {i18n(staticContent, 'checkout')}
          </NoozButton>
        </a>
      )}
      <Box
        mt={18}
        sx={{ overflow: 'hidden' }}>
        <ProductInformationCarousel />
      </Box>
    </Flex>
  );
};

export default Checkout;
