import usePageContext from '@/utils/context/page';
import { Center, createStyles, Group } from '@mantine/core';
import Autoplay from 'embla-carousel-autoplay';
import React, { useMemo, useRef } from 'react';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { IPage } from '@/@types/generated/contentful';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { Carousel } from '@mantine/carousel';
import { CAROUSEL_PROPS } from '@/utils/style/carousel';
import { formatCfItems } from '@/helpers/formatCfItems';
import { FREE_SHIPPING_ENABLED } from '@/components/Header/HeaderBanner/headerBanner';
import NoozRichBody from '@/components/Nooz/NoozRichBody/NoozRichBody';
import Currency from '@/helpers/Currency';
import useShippingRates from '@/utils/hook/useShippingRates';
import { useClasses } from '@/components/Cart/CartDrawer/CartDrawerReassurance';
import { APP_INFO } from '@/utils';

const useCarouselStyles = createStyles(() => ({
  control: {
    border: 'none',
    backgroundColor: 'none',
    boxShadow: 'none',
  },
}));

const ProductInformationCarousel = ({ delay = 4000 }: { delay?: number }) => {
  const autoplay = useRef(Autoplay({ delay }));
  const { page } = usePageContext();
  const classes = useCarouselStyles().classes;
  const shippingRates = useShippingRates();
  const reassuranceClasses = useClasses();

  const { layout } = usePageContext();
  const { reassurances } = useMemo(
    () => formatCfItems(formatCfItems(layout?.fields.cart).drawer),
    [layout],
  );
  return (reassurances.fields.items || []).length > 0 ? (
    <Carousel
      {...CAROUSEL_PROPS}
      loop={true}
      classNames={classes}
      nextControlIcon={
        <NoozIcon
          iconKey='ChevronRight'
          size={20}
        />
      }
      previousControlIcon={
        <NoozIcon
          iconKey='ChevronLeft'
          size={20}
        />
      }
      h={25}
      slideSize='100%'
      height={200}
      slideGap='md'
      plugins={[autoplay.current]}
      onMouseEnter={autoplay.current.stop}
      onMouseLeave={autoplay.current.reset}>
      {(reassurances.fields.items || []).map((r: any, key: number) => {
        const { body, icon } = formatCfItems(r);

        if (
          (APP_INFO.type === 'B2B' && r.fields.key === 'freeShipping') ||
          (APP_INFO.type === 'B2C' &&
            r.fields.key === 'freeShipping' &&
            (!shippingRates?.free || FREE_SHIPPING_ENABLED))
        ) {
          return null;
        }

        return (
          <Carousel.Slide key={r.id}>
            <Center>
              <Group
                className={reassuranceClasses.rowItem}
                key={`${r.id}-${key}`}
                spacing={8}
                sx={{
                  '& > p': {
                    margin: 0,
                    flex: 1,
                  },
                }}>
                {icon ? (
                  <NoozIcon
                    iconKey={icon.fields.iconKey}
                    color={icon.fields.color?.fields.hex}
                  />
                ) : null}
                <NoozRichBody
                  {...body.fields}
                  replaceStrings={
                    r.fields.key === 'freeShipping'
                      ? {
                          //  on a le pris des livraisons en euros uniquement
                          '{{amount}}': `${Currency.moneyToPrice({
                            ...shippingRates.free.price,
                            amount: shippingRates.free.min_order_subtotal,
                          })}`,
                        }
                      : undefined
                  }
                />
              </Group>
            </Center>
          </Carousel.Slide>
        );
      })}
    </Carousel>
  ) : undefined;
};

export default ProductInformationCarousel;
