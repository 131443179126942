import React, { memo } from 'react';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import Currency from '@/helpers/Currency';

const SIZE = {
  default: {
    unit: '24px',
    decimal: 16,
  },
  sticky: {
    unit: '26px',
    decimal: 18,
  },
};
export const Price = memo(
  ({
    price: { amount, currencyCode },
    // @ts-ignore
    unitPrice = Currency.toFormattedPrice(amount).split('.')[0],
    // @ts-ignore
    decimalPrice = `,${
      Currency.toFormattedPrice(amount).split('.')[1] || '00'
    }`.replace(',00', ''),
    // @ts-ignore
    currencySymbol = Currency.toSymbol(currencyCode),
    discountApply,
    initialPrice,
    style = 'default',
  }: {
    price: { amount: string; currencyCode: string };
    discountApply?: boolean;
    initialPrice?: boolean;
    style?: keyof typeof SIZE;
  }) => (
    <NoozText
      component={'div'}
      sx={(theme) => ({
        display: 'flex',
        textWrap: 'nowrap',
        alignItems: 'flex-end',
        textDecoration: initialPrice
          ? `2px line-through ${
              discountApply ? theme.colors.red[6] : theme.colors.decimal[0]
            }`
          : undefined,
      })}
      lh={SIZE[style].unit}>
      <NoozText
        component={'span'}
        display={'flex'}
        size={SIZE[style].unit}
        lh={SIZE[style].unit}
        ff={'Mont'}
        sx={(theme) => ({
          color: initialPrice
            ? theme.colors.decimal[0]
            : discountApply
            ? theme.colors.red[6]
            : theme.colors.title[0],
        })}
        weight={600}>
        {unitPrice}
      </NoozText>
      <NoozText
        component={'span'}
        lh={1}
        h={SIZE[style].decimal + 2}
        size={SIZE[style].decimal}
        sx={(theme) => ({
          color:
            discountApply && !initialPrice
              ? theme.colors.red[6]
              : theme.colors.decimal[0],
        })}
        ff={'Mont'}>
        {`${decimalPrice} ${currencySymbol}`}
      </NoozText>
    </NoozText>
  ),
);
