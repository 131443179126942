import React, { useMemo } from 'react';
import { Select, SelectItem } from '@mantine/core';
import useCountries from '@/utils/context/country';

interface CountryPickerProps {
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  error?: React.ReactNode;
  keyValue?: 'value' | 'label';
  [key: string]: any;
}

function CountryPicker({
  label,
  placeholder,
  value,
  onChange,
  error,
  keyValue = 'value',
  ...props
}: CountryPickerProps) {
  const countries = useCountries();
  const adaptedCountries = useMemo(
    () =>
      countries
        .map((c) => ({
          label: c.label,
          value: c[keyValue],
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [countries],
  );

  return (
    <Select
      {...{
        label,
        placeholder,
        value,
        onChange,
        error,
        ...props,
      }}
      data={adaptedCountries as SelectItem[]}
      searchable
      maxDropdownHeight={400}
    />
  );
}

export default CountryPicker;
