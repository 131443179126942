import { Box, Collapse, Divider, Flex, Group, Tabs } from '@mantine/core';
import React from 'react';
import { useDisclosure } from '@mantine/hooks';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import { IMapCfComponents } from '@/interface.custom';
import useMapbox from '@/utils/hook/useMapbox';
import Geocoder from '@/components/StoreLocatorMap/Geocoder';
import {
  createPopUp,
  removeStoresPopup,
} from '@/components/StoreLocatorMap/utils';
import { StoreLocationList } from '@/components/StoreLocatorMap/StoreLocationList';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import useStaticContent from '@/utils/hook/useStaticContent';
import CategoriesBadges from '@/components/StoreLocatorMap/CategoriesBadges';

const MobileStoreLocatorMap = ({
  stores,
  marker,
  storeDetails,
  list,
  categories,
}: IMapCfComponents) => {
  const staticContent = useStaticContent();
  const {
    map,
    activeStoreDetail,
    activeStoreIndex,
    setActiveStoreIndex,
    storeList,
    mapContainerRef,
    currentStore,
    handleLocationClick,
  } = useMapbox(stores, marker);
  const headerHeight = useHeaderHeight();
  const [opened, { toggle }] = useDisclosure(false);

  return (
    <Box pos={'relative'}>
      <Tabs
        defaultValue={'map'}
        styles={(theme) => ({
          tab: {
            fontSize: 24,
            fontWeight: 600,
            padding: '16px 0',
            height: 60,
            color: theme.colors.gray[6],
            borderBottomWidth: 3,
            '&[data-active], &[data-active]:active': {
              borderColor: theme.colors.title[0],
              color: theme.colors.title[0],
            },
            '&:focus': {
              outline: 'none',
            },
          },
          panel: {
            height: `calc(100vh - ${
              headerHeight.wrapper + headerHeight.marginTop + 60 + 50
            }px)`,
            position: 'relative',
          },
        })}>
        <Tabs.List
          grow
          position='center'>
          <Tabs.Tab value='map'>{staticContent.mapLabel}</Tabs.Tab>
          <Tabs.Tab value='list'>{staticContent.listLabel}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='map'>
          <Box
            h={'100%'}
            w={'100%'}
            ref={mapContainerRef}
          />
          {currentStore ? (
            <Collapse in={activeStoreDetail}>
              <Box
                pb={32}
                sx={(theme) => ({
                  position: 'absolute',
                  width: '100%',
                  bottom: 0,
                  backgroundColor: theme.white,
                  borderTopLeftRadius: 20,
                  borderTopRightRadius: 20,
                })}>
                <Flex
                  onTouchStart={toggle}
                  sx={{
                    touchAction: 'none',
                    msTouchAction: 'none',
                  }}
                  direction={'column'}
                  p={'0 16px 4px'}>
                  <Flex
                    direction={'column'}
                    sx={(theme) => ({
                      height: 3,
                      width: '15vw',
                      backgroundColor: theme.colors.gray[6],
                      borderRadius: 3,
                      margin: '8px auto 16px',
                    })}
                  />
                  <Flex gap={16}>
                    <Box sx={{ flex: 1 }}>
                      <NoozText
                        color={'title'}
                        fw={700}
                        size={18}
                        lh={1.2}>
                        {currentStore.properties.name}
                      </NoozText>
                      <NoozText
                        color={'text'}
                        size={14}
                        lh={1.2}>
                        {currentStore.properties.address1}
                        <br />
                        {`${currentStore.properties.postcode} ${currentStore.properties.city}`}
                      </NoozText>
                    </Box>
                    <Flex align={'center'}>
                      <NoozText
                        color={'text'}
                        size={14}
                        fw={700}
                        lh={1.2}>{`${currentStore.properties.distance.toFixed(
                        1,
                      )} km`}</NoozText>
                    </Flex>
                  </Flex>
                </Flex>
                <Collapse in={opened}>
                  <Flex
                    direction={'column'}
                    gap={16}
                    mt={12}
                    mb={16}>
                    <Divider />
                    <Flex
                      direction={'column'}
                      p={'0 16px'}
                      gap={8}>
                      <NoozText
                        fw={700}
                        color={'title'}>
                        {staticContent.theirCollections}
                      </NoozText>
                      <Group spacing='xs'>
                        <CategoriesBadges
                          categories={JSON.parse(
                            currentStore.properties.productCategories,
                          )}
                          dictionary={categories}
                        />
                      </Group>
                    </Flex>
                  </Flex>
                </Collapse>
              </Box>
            </Collapse>
          ) : null}
        </Tabs.Panel>
        <Tabs.Panel
          value='list'
          sx={{ overflow: 'auto' }}>
          <Flex
            p={'60px 16px 0'}
            gap={16}
            direction={'column'}>
            <StoreLocationList
              activeStoreIndex={activeStoreIndex}
              // @ts-ignore
              createPopUp={createPopUp}
              list={storeList}
              // @ts-ignore
              map={map}
              onClick={handleLocationClick}
              removePopup={removeStoresPopup}
              setActiveStoreIndex={setActiveStoreIndex}
              storeDetails={storeDetails}
              dictionary={categories}
              {...list}
            />
          </Flex>
        </Tabs.Panel>
      </Tabs>
      <Box
        pos={'absolute'}
        top={70}
        w={'calc(100vw - 80px)'}
        left={40}
        p={'0 24px'}>
        <Geocoder />
      </Box>
    </Box>
  );
};

export default MobileStoreLocatorMap;
