import React from 'react';
import { Center, Flex } from '@mantine/core';
import i18n from '@/helpers/i18n';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import useStaticContent from '@/utils/hook/useStaticContent';
import OrderRow from '@/components/OrderRow/OrderRow';
import { ResultsTrackOrderProps } from '@/interface.custom';

const ResultsTrackOrder = ({ orders, goPrev }: ResultsTrackOrderProps) => {
  const staticContent = useStaticContent();

  return (
    <>
      <NoozButton
        onClick={goPrev}
        color={'title'}>
        {i18n(staticContent, 'backLabel')}
      </NoozButton>
      <Center>
        <NoozText
          weight={700}
          size={28}>
          {i18n(staticContent, 'myOrdersLabel')}
        </NoozText>
      </Center>
      {orders && orders.length > 0 ? (
        <Flex
          direction={'column'}
          gap={16}>
          {orders.map((o) => (
            <OrderRow
              key={o.order_number}
              {...o}
            />
          ))}
        </Flex>
      ) : (
        <NoozText weight={500}>
          {i18n(staticContent, 'emptyOrdersListLabel')}
        </NoozText>
      )}
    </>
  );
};

export default ResultsTrackOrder;
