import { Badge, Group, Paper } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import i18n from '@/helpers/i18n';
import format from 'date-fns/format';
import caseConverter from '@/helpers/caseConverter';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import React from 'react';
import useStaticContent from '@/utils/hook/useStaticContent';
import { OrderRowProps } from '@/interface.custom';

const OrderRow = ({
  financial_status,
  fulfillment_status,
  order_number,
  created_at,
  order_status_url,
}: OrderRowProps) => {
  const staticContent = useStaticContent();
  let fulfillmentLabel = 'notDispatchedLabel';

  if (fulfillment_status === 'fulfilled') fulfillmentLabel = 'dispatchedLabel';
  else if (fulfillment_status === 'restocked')
    fulfillmentLabel = 'restockedLabel';

  return (
    <Paper
      key={order_number}
      shadow='xs'
      p='md'>
      <Group position='apart'>
        <div>
          <NoozText>
            {`${i18n(staticContent, 'orderNumberLabel')}: ${order_number}`}
          </NoozText>
          <NoozText>
            {`${i18n(staticContent, 'dateLabel')}: ${format(
              new Date(created_at),
              'dd/MM/yyyy',
            )}`}
          </NoozText>
        </div>

        <Group>
          <Badge
            size='md'
            variant='outline'
            color={'title'}>
            {i18n(
              (staticContent?.financialDictionary || {}) as Record<
                string,
                string
              >,
              `${caseConverter.toCamel(financial_status || 'voided')}Label`,
            )}
          </Badge>
          <Badge
            size='md'
            color={'title'}
            variant='outline'>
            {i18n(
              (staticContent?.fulfillmentDictionary || {}) as Record<
                string,
                string
              >,
              fulfillmentLabel,
            )}
          </Badge>
          <a
            href={order_status_url}
            target={'_blank'}
            rel='noreferrer'>
            <NoozButton size="md" color={'title'}>
              {i18n(staticContent, 'viewLabel')}
            </NoozButton>
          </a>
        </Group>
      </Group>
    </Paper>
  );
};

export default OrderRow;
