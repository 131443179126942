import { Flex, useMantineTheme } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { IBlockFields, IIcon, ILabel } from '@/@types/generated/contentful';
import { formatCfItems } from '@/helpers/formatCfItems';
import { BasicNoozBadge } from '@/components/Nooz/NoozBadge/NoozBadge';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import React, { Dispatch, memo, SetStateAction, useMemo } from 'react';
import { useToggle } from '@mantine/hooks';
import { motion } from 'framer-motion';

const Question = ({
  item,
  handleResponse,
}: {
  item: { id: string; fields: IBlockFields };
  handleResponse: (value: string, isEnded: boolean) => void;
}) => {
  const { label, value } = formatCfItems(item);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{
        scale: 1,
        opacity: 1,
        transition: { ease: 'easeOut', duration: 0.2 },
      }}
      exit={{
        opacity: 0,
        scale: 0,
        transition: { ease: 'easeOut', duration: 0.5 },
      }}>
      <Flex
        align={'center'}
        justify={'center'}
        m={{ base: 0, sm: '0 32px 16px' }}
        sx={(theme) => ({
          backgroundColor: '#F6F8FD',
          position: 'relative',
          border: '1px solid #DCE2F2',
          borderRadius: 20,
          // width: '100%',
          padding: '70px 110px',
          maxWidth: 900,
          [theme.fn.smallerThan(theme.breakpoints.xs)]: {
            padding: '60px 25px',
          },
        })}>
        <NoozText
          align={'center'}
          size={parseInt(item.fields.width || '10')}
          lh={'36px'}
          weight={500}
          color={'title'}>
          {(label as ILabel).fields.text}
        </NoozText>
        <Flex
          justify={'center'}
          align={'center'}
          sx={(theme) => ({
            position: 'absolute',
            backgroundColor: theme.white,
            top: 'calc(50% - 40px)',
            left: -40,
            width: 80,
            height: 80,
            border: '1px solid',
            borderColor: '#DCE2F2',
            borderRadius: 40,

            [theme.fn.smallerThan(theme.breakpoints.xs)]: {
              left: 'calc(50% - 40px)',
              top: -40,
            },
          })}>
          <NoozText
            size={28}
            lh={'34px'}
            weight={500}>
            {(value as ILabel).fields.text}
          </NoozText>
        </Flex>
        <Flex
          gap={40}
          sx={{
            position: 'absolute',
            bottom: -40,
            left: 'calc(50% - ((40px + 80px + 80px) / 2))',
          }}>
          <Flex
            justify={'center'}
            align={'center'}
            onClick={() => {
              handleResponse((value as ILabel).fields.text as string, true);
            }}
            sx={(theme) => ({
              backgroundColor: theme.colors.red[5],
              width: 80,
              height: 80,
              borderRadius: 40,
              cursor: 'pointer',
            })}>
            <NoozIcon
              size={40}
              iconKey={'X'}
              color={'white'}
            />
          </Flex>
          <Flex
            justify={'center'}
            align={'center'}
            onClick={() => {
              handleResponse((value as ILabel).fields.text as string, false);
            }}
            sx={(theme) => ({
              backgroundColor: theme.colors.green[4],
              width: 80,
              height: 80,
              borderRadius: 40,
              cursor: 'pointer',
            })}>
            <NoozIcon
              size={40}
              iconKey={'Check'}
              color={'white'}
            />
          </Flex>
        </Flex>
      </Flex>
    </motion.div>
  );
};
const FormStep = ({
  source,
  toggleStep,
  setDiopter,
}: {
  source: any;
  toggleStep: (value?: SetStateAction<string> | undefined) => void;
  setDiopter: Dispatch<SetStateAction<string | number | null>>;
}) => {
  const data = useMemo<null | Record<string, any>>(() => {
    if (source.formStep) {
      return formatCfItems(source.formStep);
    } else return null;
  }, [source]);
  const [questionNb, toggleQuestionNumber] = useToggle([1, 2, 3, 4, 5]);
  const theme = useMantineTheme();

  const handleResponse = (value: string, isEnded: boolean) => {
    if (isEnded || questionNb === 5) {
      setDiopter(questionNb === 5 ? 0 : value);
      toggleStep();
    } else {
      toggleQuestionNumber();
    }
  };

  return data ? (
    <Flex
      direction={'column'}
      align={'center'}
      pb={24}
      gap={40}
      mih={{ base: 470, sm: 'auto' }}
      sx={(theme) => ({
        gap: 40,
        [theme.fn.smallerThan(theme.breakpoints.xs)]: {
          gap: 20,
        },
        [theme.fn.largerThan(theme.breakpoints.xs)]: {
          flex: 1,
        },
      })}>
      <Flex
        align={'center'}
        direction={'column'}
        gap={8}>
        <BasicNoozBadge color={'title'}>
          {data.badge.fields.label?.fields.text?.toUpperCase()}
          <Flex ml={4}>
            <NoozIcon
              iconKey={
                data.badge.fields.icon
                  ? (data.badge.fields.icon as IIcon).fields?.iconKey
                  : 'DeviceDesktopAnalytics'
              }
              size={14}
              color={theme.colors.main[0]}
            />
          </Flex>
        </BasicNoozBadge>
        {/*<NoozText*/}
        {/*  color={'title'}*/}
        {/*  weight={500}*/}
        {/*  align={'center'}*/}
        {/*  size={32}*/}
        {/*  lh={'48px'}>*/}
        {/*  {data.label.fields.text}*/}
        {/*</NoozText>*/}
        <NoozText
          color={'text'}
          weight={500}
          align={'center'}
          size={12}>
          {`${questionNb} / ${5}`}
        </NoozText>
      </Flex>
      <Flex sx={{ flex: 1, alignItems: 'center' }}>
        <Question
          key={questionNb}
          item={data.sentences.fields.items[questionNb - 1]}
          handleResponse={handleResponse}
        />
      </Flex>
    </Flex>
  ) : null;
};

export default memo(FormStep);
