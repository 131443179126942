import { usePackContext } from '@/utils/context/PackContext';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import React from 'react';
import useStaticContent from '@/utils/hook/useStaticContent';
import { usePackPackOptSelector } from '@/components/Product/ProductInformation/ProductInformation';
import { IImage, IProduct, IProductPack } from '@/@types/generated/contentful';
import usePageContext from '@/utils/context/page';
import { STATIC_SIZE_KEY } from '@/components/Product/ProductSizePicker.tsx/ProductSizePicker';
import useCartActions from '@/utils/hook/cart/useCartActions';
import { ICartItem } from '@/interface.custom';
import generateUUID from '@/helpers/uuid';
import { APP_INFO } from '@/utils';

const AddToCartPackButton = ({ hasPrice }: { hasPrice?: boolean }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { addToCart } = useCartActions();
  const { shopifyProduct, locale, localizedSlugs } =
    usePageContext() as unknown as {
      shopifyProduct: Record<string, any>;
      product: IProductPack;
      locale: string;
      localizedSlugs: Record<string, string>;
    };
  const staticContent = useStaticContent();

  const { pack } = usePackContext();

  const { openPackOptionSelection, hasLoaded } = usePackPackOptSelector();

  const handleOnClick = async () => {
    if (pack.data.fields.options.length === Object.keys(pack.options).length) {
      setIsLoading(true);
      const cartItems: ICartItem[] = [
        {
          quantity: 1,
          type: 'item',
          dataLayerItem: {
            item_id: parseInt(
              shopifyProduct.variants[0].id?.replace(
                'gid://shopify/ProductVariant/',
                '',
              ),
            ),
            parent_item_id: parseInt(
              (shopifyProduct.id as unknown as string).replace(
                'gid://shopify/Product/',
                '',
              ),
            ),
            item_name: pack.data.fields.title,
            item_variant: shopifyProduct.title,
            price: parseFloat(shopifyProduct.variants[0].price.amount),
            quantity: 1,
            index: 0,
            item_list_name: pack.data.fields.title,
            item_list_id: `${(pack.data as unknown as { id: string })?.id}_${(
              locale as String
            )
              .split('-')
              .reduce((prev, v, key) => {
                if (key === 0) return v.toLowerCase();
                return prev + `-${v.toUpperCase()}`;
              }, '')}`,
          },
          shopifyVariant: {
            ...shopifyProduct.variants[0],
            title: shopifyProduct.variants[0].sku,
          },
          // @ts-ignore
          contentfulItem: {
            items: Object.values(pack.options).map((i) =>
              [
                // @ts-ignore
                i?.product.label,
                // @ts-ignore
                i?.selectedOptions.Size
                  ? // @ts-ignore
                    staticContent[STATIC_SIZE_KEY[i?.selectedOptions.Size]]
                  : undefined,
                // @ts-ignore
                i?.selectedOptions.Diopter,
                i?.fields.color?.fields.text,
              ].filter((f) => !!f),
            ),
            title: pack.data.fields?.label?.fields.text,
            image: (pack.data.fields.images?.[0] as IImage) || null,
            price: parseFloat(shopifyProduct.variants[0].price.amount).toFixed(
              2,
            ),
            discountPrice: shopifyProduct.variants?.[0]?.compareAtPrice
              ? parseFloat(
                  shopifyProduct.variants[0].compareAtPrice.amount,
                ).toFixed(2)
              : undefined,
            currencyCode: shopifyProduct.variants[0].price.currencyCode,
            url: localizedSlugs.en,
            id: (pack.data as any).id,
            algoliaId: `${(pack.data as any).id}_${(locale as String)
              .split('-')
              .reduce((prev, v, key) => {
                if (key === 0) return v.toLowerCase();
                return prev + `-${v}`;
              }, '')}`,
          },
        },
      ];

      await addToCart(
        cartItems,
        [
          { key: '__itemId', value: generateUUID() },
          { key: '__itemType', value: 'pack' },
          {
            key: '__components',
            value: JSON.stringify(
              (Object.values(pack.options) as any[]).map((variant) => {
                return {
                  id: parseInt(
                    variant.shopifyInfo.id.replace(
                      'gid://shopify/ProductVariant/',
                      '',
                    ),
                  ),

                  // @ts-ignore
                  name: [variant?.product.label, variant.product.category]
                    .filter((f) => !!f)
                    .join(' - '),
                  sku: variant.shopifyInfo.sku,
                  posterImage:
                    variant.fields.posterImage?.fields.media?.fields.file.url,
                  options: [
                    // @ts-ignore
                    variant?.selectedOptions.Size,
                    // @ts-ignore
                    ('+' + variant?.selectedOptions.Diopter).replace('++', '+'),
                    variant?.fields.color?.fields.text,
                  ]
                    .filter((f) => !!f)
                    .join(' / '),
                };
              }) as any[],
            ),
          },
        ],
        // 'drawer',
        'redirect',
      );
      setIsLoading(false);
    } else {
      const optKeys = pack.data.fields.options.map((o) => o.key);
      for (const key of optKeys) {
        if (!(key in pack.options)) {
          openPackOptionSelection(
            key,
            pack.data.fields.options.find((o) => o.key === key)
              ?.products as unknown as IProduct[],
          )();
          break;
        }
      }
    }
  };

  return APP_INFO.type === 'B2B' && !hasPrice ? (
    <NoozButton
      loading={true}
      radius={100}
      color={'title'}
      disabled={true}
      fullWidth></NoozButton>
  ) : (
    <NoozButton
      loading={isLoading && !hasLoaded}
      disabled={!hasLoaded}
      onClick={handleOnClick}
      radius={100}
      color={'title'}
      fullWidth>
      {
        staticContent[
          pack.data.fields.options.length === Object.keys(pack.options).length
            ? 'addToCart'
            : 'configurePack'
        ]
      }
      <NoozIcon
        color={'white'}
        iconKey={'ChevronRight'}
      />
    </NoozButton>
  );
};

export default AddToCartPackButton;
