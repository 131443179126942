import { useToggle } from '@mantine/hooks';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { Box, Modal } from '@mantine/core';
import React, { useMemo } from 'react';
import useStaticContent from '@/utils/hook/useStaticContent';
import { APP_INFO } from '@/utils';
import { formatCfItems } from '@/helpers/formatCfItems';
import ProductEyeTestWizard from '@/components/ProductEyeTest/ProductEyeTestWizard';
import usePageContext from '@/utils/context/page';
import i18n from '@/helpers/i18n';

const ProductEyeTest = () => {
  const [isModalOpened, toggleModal] = useToggle();
  const staticContent = useStaticContent();
  const eyeTestSource = useMemo(() => {
    const src = formatCfItems(staticContent.EyeTest);
    return Object.keys(src).length > 0 ? src : null;
  }, [staticContent]);

  // @ts-ignore
  return eyeTestSource ? (
    <>
      <Box
        sx={{ cursor: 'pointer' }}
        // @ts-ignore
        onClick={toggleModal}
        mt={12}>
        <NoozText
          transform={'uppercase'}
          variant='gradient'
          size={10}
          weight={500}
          color={'text'}>
          {`${i18n(staticContent, 'diopterNotSure')} `}
          <NoozText
            color={'title'}
            size={10}
            weight={900}
            underline
            component={'span'}>
            {i18n(staticContent, 'diopterTryYourView')}
          </NoozText>
        </NoozText>
      </Box>
      <Modal
        opened={isModalOpened}
        centered
        size={'90vw'}
        closeButtonProps={{
          size: 'md',
          radius: 'xl',
          color: 'black',
          variant: 'filled',
        }}
        styles={(theme) => ({
          body: {
            marginTop: -46,
            display: 'flex',
            [theme.fn.largerThan(theme.breakpoints.sm)]: {
              minHeight: '50vh',
            },
          },
          header: { background: 'transparent' },
        })}
        onClose={toggleModal}>
        <ProductEyeTestWizard source={eyeTestSource} />
      </Modal>
    </>
  ) : null;
};

export default ProductEyeTest;
