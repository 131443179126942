import EyeTestWizardHandler from '@/components/pages/EyeTest/EyeTestWizardHandler';
import usePageContext from '@/utils/context/page';
import { formatCfItems } from '@/helpers/formatCfItems';
import { useMemo } from 'react';

function EyeTest() {
  const { components } = usePageContext();
  const eyeTestSource = useMemo(
    () => formatCfItems(formatCfItems(components).EyeTest),
    [components],
  );
  return eyeTestSource ? <EyeTestWizardHandler source={eyeTestSource} /> : null;
}

export default EyeTest;
