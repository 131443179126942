import React from 'react';
import { capitalizeFirstLetter } from '@/utils';
import NoozText from '../Nooz/NoozText/NoozText';
import CountryCodeToName from '@/helpers/CountryCodeToName.json';

interface StoreLocatorPopupProps {
  data: {
    name: string;
    address1: string;
    postcode: string;
    city: string;
    state: string;
    country: string;
  };
}

export const StoreLocatorPopup: React.FC<StoreLocatorPopupProps> = ({
  data: { name, address1, postcode, city, country },
}) => {
  return (
    <div style={{ alignItems: 'flex-start', padding: 12 }}>
      <NoozText weight={700}>{name}</NoozText>
      <NoozText>{address1}</NoozText>
      <NoozText>
        {[postcode, capitalizeFirstLetter(city)].filter((f) => !!f).join(' ')}
      </NoozText>
      <NoozText>{}</NoozText>
      <NoozText>
        {capitalizeFirstLetter(
          CountryCodeToName[country as keyof typeof CountryCodeToName],
        )}
      </NoozText>
    </div>
  );
};
