import { createContext, useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { IPackData } from '@/interface.custom';
import NoozNextApi, { NOOZ_NEXT_REGISTRY } from '@/utils/services/NoozNextApi';

const PackProductStockContext = createContext<{
  stocks: Record<string, number>;
  hasLoaded: boolean;
}>({ stocks: {}, hasLoaded: false });

export const usePackProductStock = () => useContext(PackProductStockContext);

const PackProductStockProvider = ({
  product,
  children,
  locale,
}: {
  product: IPackData;
  children: React.ReactNode;
  locale: string;
}) => {
  const [stocks, setStock] = useState<Record<string, number>>({});
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  useEffect(() => {
    const fetchShopifyData = () => {
      const uniqueProducts = product.fields.options.reduce(
        (prev: string[], opt) => {
          prev = prev.concat(opt.products as unknown as string[]);
          return Array.from(new Set(prev));
        },
        [],
      );

      // Prepare the configuration for the API call
      const fetchShopifyProductConfig = {
        ...NOOZ_NEXT_REGISTRY.SHOPIFY.PRODUCTS.GET_STOCK,
        data: { ids: uniqueProducts, locale },
      };

      // Make the API call to fetch Shopify data
      NoozNextApi.instance(fetchShopifyProductConfig)
        .then(({ data }) => {
          setStock(data);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setHasLoaded(true);
        });
    };

    // Call the fetchShopifyData function
    fetchShopifyData();
  }, []);

  return (
    <PackProductStockContext.Provider value={{ stocks, hasLoaded }}>
      {children}
    </PackProductStockContext.Provider>
  );
};

export default PackProductStockProvider;
