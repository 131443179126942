import { useEffect, useMemo, useRef, useState } from 'react';
// eslint-disable-line import/no-webpack-loader-syntax
// @ts-ignore
import mapboxgl, { MapMouseEvent } from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import {
  createPopUp,
  flyToStore,
  initializeMapLayers,
  removeStoresPopup,
  returnAndSortLocalisedStores,
  returnPointOnMapLength,
} from '@/components/StoreLocatorMap/utils';

// Set the Mapbox access token
mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAPBOX_API_KEY as string;

// Set initial coordinates and zoom level for the map
const longitude = 2.3488;
const latitude = 48.8534;
const zoom = 10;
const initialClusteredPointLength = 20;

type GeoCoordinates = [lng: number, lat: number];
const useMapbox = (stores: any, marker: any) => {
  const map = useRef<mapboxgl.Map | undefined>();
  const [coordinates, setCoordinates] = useState<GeoCoordinates>([
    longitude,
    latitude,
  ]);
  const geocoder = useRef<MapboxGeocoder>();
  const [activeStoreDetail, setActiveStoreDetail] = useState<boolean>(false);
  const [activeStoreIndex, setActiveStoreIndex] = useState<number | null>(null);
  const storeList = useMemo<any>(() => {
    return returnAndSortLocalisedStores(stores, coordinates[0], coordinates[1]);
  }, [stores, coordinates]);

  const [unClusteredPointLength, setUnClusteredPointLength] = useState(
    initialClusteredPointLength,
  );
  const [currentStore, setCurrentStore] = useState<IMapStore | null>(null);

  const mapContainerRef = useRef<HTMLDivElement>(null);

  // Function to handle clicking on a store location
  const handleLocationClick = (store: IMapStore, index: number) => {
    if (map.current) {
      setActiveStoreDetail(true);
      flyToStore(map.current, store);
      setActiveStoreIndex(index);
      setCurrentStore(store);
    }
  };

  useEffect(() => {
    let effectedMap: null | mapboxgl.Map = null;
    if (!geocoder.current) {
      geocoder.current = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        marker: false,
        zoom,
      });
    }

    if (!map.current) {
      const initializeMapbox = (c: [number, number]) => {
        map.current = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: process.env.NEXT_PUBLIC_MAPBOX_STYLE_URL as string,
          center: c,
          zoom: zoom,
          maxZoom: 18,
          minZoom: 3,
        });
        effectedMap = map.current;

        // Initial load of controls (zoom in / out)
        map.current.addControl(
          new mapboxgl.NavigationControl({ showCompass: false }),
          'top-left',
        );

        map.current.on('load', () => {
          // Add the geocoder search input to the map
          if (geocoder.current) {
            const geoCoderTag = document.getElementById('geocoder');

            if (!geoCoderTag?.children || geoCoderTag?.children?.length === 0) {
              // @ts-ignore
              geoCoderTag.appendChild(geocoder.current.onAdd(map.current));
              // When a search result is selected
              geocoder.current.on('result', (/*e: any*/) => {
                // Get the coordinate of the search result
                // const searchResult = e.result.geometry;
                // setCoordinates(searchResult.coordinates);
              });
            }
          } else {
            console.debug('[ERROR] No GeoCoder Search Input');
          }

          // Load the custom marker image onto the map
          const markerId = 'nooz-marker';
          if (marker.fields.media != null) {
            map.current.loadImage(
              marker.fields.media.fields.file.url,
              // @ts-ignore
              (error: Error, image: any) => {
                if (error) throw error;
                if (!map.current?.hasImage(markerId))
                  map.current.addImage(markerId, image);
              },
            );
          }

          // Add the stores data as a GeoJSON source to the map
          const storesSourceId = 'stores-data';
          if (!map.current?.getSource(storesSourceId)) {
            map.current.addSource(storesSourceId, {
              type: 'geojson',
              data: storeList,
              cluster: true,
              clusterMaxZoom: 12,
            });
          }

          // Initialize the map layers
          initializeMapLayers(map.current);

          // handle click events
          {
            // When a cluster is clicked
            map.current.on('click', 'clusters', (e: MapMouseEvent) => {
              // Zoom on the on map when a cluster is cliked
              const features = map.current.queryRenderedFeatures(e.point, {
                layers: ['clusters'],
              });

              const clusterId = features[0].properties.cluster_id;
              map.current
                .getSource('stores-data')
                .getClusterExpansionZoom(clusterId, (err: any) => {
                  if (err) return;

                  map.current.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom: map.current.getZoom() + 2,
                  });
                });
            });

            // When an unclustered point is clicked
            map.current.on('click', 'unclustered-point', (e: MapMouseEvent) => {
              if (e.features) {
                // @ts-ignore
                handleLocationClick(e.features[0]);
              }
            });
          }

          // handle mouse events
          {
            // When the mouse enters a cluster
            map.current.on('mouseenter', 'clusters', () => {
              map.current.getCanvas().style.cursor = 'pointer';
            });

            // When the mouse leaves a cluster
            map.current.on('mouseleave', 'clusters', () => {
              map.current.getCanvas().style.cursor = '';
            });

            // When the mouse enters an unClustered point
            map.current.on(
              'mouseenter',
              'unclustered-point',
              (e: MapMouseEvent) => {
                // trigger location tooltip on desktop only
                if (e.features) {
                  map.current.getCanvas().style.cursor = 'pointer';
                  createPopUp(map.current, e.features[0]);
                }
              },
            );
            // When the mouse leaves an unClustered point
            map.current.on('mouseleave', 'unclustered-point', () => {
              map.current.getCanvas().style.cursor = '';
              removeStoresPopup();
            });
          }
        });
      };
      let mapCenter = coordinates;
      // Get user's position
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            mapCenter = [position.coords.longitude, position.coords.latitude];
            setCoordinates(mapCenter);
            initializeMapbox(mapCenter);
          },
          () => {
            initializeMapbox(mapCenter);
          },
        );
      } else {
        initializeMapbox(mapCenter);
      }
    }
    // Clean up on unmount
    return () => {
      if (effectedMap) effectedMap.remove();
    };
  }, []);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    // When the map view is changed
    map.current.on('moveend', () => {
      const newCenter = [
        map.current.getCenter().lng,
        map.current.getCenter().lat,
      ] as GeoCoordinates;
      setCoordinates(newCenter);
      setUnClusteredPointLength(returnPointOnMapLength(map.current));
    });
  });

  return {
    map: map.current,
    geocoder,
    activeStoreDetail,
    setActiveStoreDetail,
    activeStoreIndex,
    setActiveStoreIndex,
    storeList: storeList.features.slice(0, unClusteredPointLength),
    unClusteredPointLength,
    mapContainerRef,
    currentStore,
    handleLocationClick,
  };
};

export default useMapbox;
